import * as React from 'react';
import { ARMGrid } from '../../common/UICLWrappers/ARMGrid';
import { GridConfig, IHeaderProps, IRowProps } from './GridConfig';
import { ISortIndicator, SortDataType } from '../../../scripts/util/SortHelper';
import { ISampleTreeviewData } from './ISampleTreeviewData';
import styled from 'styled-components';
import { ARMHeaderCell } from '../../common/UICLWrappers/ARMHeaderCell';
import plusSymbol from './icons/plus.gif';
import minusSymbol from './icons/minus.gif';
import checkMark from './icons/cvs_checkout.gif';
import checkFileIn from './icons/cvs_checkin.gif';
import undoCheckFileOut from './icons/cvs_undo.gif';
import register from './icons/cvs_register.gif';
import downloadImage from './icons/cvs_download.gif';
import i from './icons/i.gif';
import l_middle from './icons/l-middle.gif';
import l_end from './icons/l-end.gif';
import _ from 'lodash';
import { FileDownloader } from "./FileDownloader";
import { Background } from 'victory';
import { URLs } from '@commonDevResources/constants';
import { getRawToken } from "@scripts/session/SecurityToken";



const numberOfSortableHeaders = 3
let sortableHeaderIds: any[] = []
for (let i = 0; i < numberOfSortableHeaders; i++) {
    sortableHeaderIds = [...sortableHeaderIds, ""+_.uniqueId()]
}


export const GridWrapper = styled.div`

    /* STANDARD OUTER GRID STYLE CONFIGURATION */

    div{
        display:inline;
    }
    background-color:white;
    width: 900px;
    height: 400px;
    border: black 1px solid;
    overflow-y: scroll;

    #sample-treeview-grid{

        /* STANDARD INNER GRID STYLE CONFIGURATION */

        thead{position:sticky; z-index:20; top:0px}

        background-color:white;
        .empty-row{
            background-color:white !important;
        }
        overflow-y:scroll;
        overflow-x:scroll;

        table tbody tr > td{
            height:32px !important;
        }

        /* ROW HOVER CONFIGURATION */

        tr:hover{
            background-color:#edf9ff;
            cursor:pointer;
        }

        /* OTHER CONFIGURATION */

        .child-header {
            background-color: #0F0F59;
            pointer-events: none;
            th > div {
                white-space: nowrap;
                color: #FFF
                float: left;
                img {
                    float: left;
                }
            }
        }

        .child-row {
            cursor: default;
            img {
                float: left;
            }
        }

        .cell-style {
            height: 32px;
            display: flex;
            align-items: center;
            white-space: nowrap;
        }

        /* primary grid row headers */

        .FileName {
            width: 355px;
        }

        .Modified{
            width: 245px;
        }

        .Size {
            width: 125px;
        }

        .Owner {
            width: 125px;
        }

        .primary-header-count {
            width: 100px;
        }

        .primary-header-checkedOut {
            width: 100px;
        }

        .child-row-amount {
  
        }

        .CheckedOut {
            width: 114px;
        }

        /* primary grid rows */

        .overflow-filename-style {
            overflow: hidden;
            inline-size: 288px;
            margin-right: -10px;
        }

        .primary-row-file-name {
            width: 155px;
        }
        
        .overflow-import-date-style {
            overflow: hidden;
            inline-size: 130px;
            margin-right: -10px;
        }

        .primary-row-import-date {
            width: 345px;
        }
        
        .overflow-payment-source-style {
            overflow: hidden;
   
            margin-right: -10px;
        }

        .primary-row-payment-source {
            width: 88px;
        }
        
        .overflow-count-style {
            overflow: hidden;
            inline-size: 122px;
            margin-right: -10px;
            margin-left: 50px;
        }

        .primary-row-count {
            width: 100px;
           inline-size: 90px;
        }
        
        .overflow-checkedOut-style {
            overflow: hidden;
            inline-size: 90px;
            margin-right: -10px;
        }

        .primary-row-checkedOut {
            width: 100px;
            inline-size:62px;
        }

        .primary-row-remove {
            width: 114px;
        }

        /* child grid row headers */

        .child-header-payment-date {
            white-space: nowrap;
            width: 170px;
        }

        .child-header-payment-no {
            width: 152px;
        }

        .child-header-provider-no {
            width: 450px;
        }

        .child-header-tax-id {
            width: 90px;
        }

        .child-header-secondary {
            width: 115px;
        }

        .child-header-matched {
            width: 100px;
        }

        .child-header-unmatched {
            width: 115px;
        }

        .child-header-checkedOut {
            width: 100px;
        }

        .child-header-remove {
            width: 50px;
            white-space: nowrap;
        }

        /* child grid rows */
        .child-row-payment-date{
            white-space: nowrap;
            width: 170px;
        }
        
        .overflow-payment-no-style {
            overflow: hidden;
            inline-size: 138px;
            margin-right: -10px;
        }

        .child-row-payment-no {
            width: 120px;
        }
        
        .overflow-provider-no-style {
            overflow: hidden;
           /* inline-size: 100px;*/
            margin-right: -10px;
        }

        .child-row-provider-no {
            width: 464px;
        }
        
        .overflow-tax-id-style {
            overflow: hidden;
            inline-size: 70px;
            margin-right: -10px;
        }

        .child-row-tax-id {
            width: 90px;
        }
        
        .overflow-secondary-style {
            overflow: hidden;
            inline-size: 100px;
            margin-right: -10px;
        }

        .child-row-secondary {
            width: 115px;
        }
        
        .overflow-matched-style {
            overflow: hidden;
            inline-size: 90px;
            margin-right: -10px;
        }

        .child-row-matched {
            width: 100px;
        }
        
        .overflow-unmatched-style {
            overflow: hidden;
            inline-size: 100px;
            margin-right: -10px;
        }

        .child-row-unmatched {
            width: 115px;
        }
        
        .overflow-child-checkedOut-style {
            overflow: hidden;
            inline-size: 90px;
            margin-right: -10px;
        }

        .child-row-checkedOut {
            width: 100px;
        }

        .child-row-remove{
            width: 50px;
            white-space: nowrap;
        }
    }
`;

interface ISampleHierarchyGridProps {
    treeviewData: ISampleTreeviewData;
    setResultsGridState: any;
    resultsGridState: IComponentState;
    onGetBridgeFiles: any;
    setBusyLoader: any;
    onGetFilename: any;
    onSort: any;
    onHandleScroll : any
    onClearScrollTo : any
}


interface searchResultsObj {
    "tOwner": string;
    "parentData": any[];
};

interface IComponentProps {
};

interface ResultsGridState {
    treeviewData: ISampleTreeviewData | undefined;
    expandClicked: boolean;
    expandedParentIds: string[];
    inputValue: string;
    sortIndicator: ISortIndicator;
    topOwner: string;
}

interface paginatedSearchResult {
    childData: any[];
    parentData: any[];
}


interface IChildRecord {
    RevisionNumber: string,
    Note: string,
    RevisionDate: string,
}

interface IComponentState {
    selectedIndex: number;
    cancelLeave: boolean;
    isBusy: boolean,
    topOwner:string,
    reasonInputError: boolean;
    reasonInputText: string;
    isHoveringOverBack: boolean;
    isHoveringOverFoward: boolean;
    searchResults: searchResultsObj;
    paginatedSearchResults: paginatedSearchResult[];
    pageIndex: number;
    dateUUID: string;
    resultsUUID: string;
    treeviewData: ISampleTreeviewData | undefined;
    expandClicked: boolean;
    expandedParentIds: string[];
    inputValue: string;
    sortIndicator: ISortIndicator;
    validationModalMessage: string;
    isValidationModalOpen: boolean;
    fileToCheckin: string;
    recordToScrollTo: string;
};


export const DEFAULT_RESULTS_GRID_STATE: ResultsGridState = {
    treeviewData: undefined,
    expandClicked: false,
    topOwner: "",
    expandedParentIds: [],
    inputValue: '',
    sortIndicator: {
        sortColumn: 'Modified',
        sortDirection: 'up',
        sortDataType: SortDataType.Date
    },
}

export const DEFAULT_STATE: IComponentState = {
    selectedIndex: 0,
    cancelLeave: false,
    isBusy: false,
    topOwner: "",
    reasonInputError: false,
    reasonInputText: "",
    isHoveringOverBack: false,
    isHoveringOverFoward: false,
    validationModalMessage: "",
    isValidationModalOpen: false,
    searchResults: {
        "tOwner": "",
        "parentData": []
    },
    paginatedSearchResults: [{
        childData: [],
        parentData: [],
    }],
    pageIndex: 0,
    dateUUID: _.uniqueId(),
    resultsUUID: "" + Date.now(),
    treeviewData: undefined,
    expandClicked: false,
    expandedParentIds: [],
    inputValue: '',
    sortIndicator: {
        sortColumn: 'Modified',
        sortDirection: 'up',
        sortDataType: SortDataType.Date
    },
    fileToCheckin: "",
    recordToScrollTo: ""
};

export class UseTabGrid extends React.Component<ISampleHierarchyGridProps, IComponentState> {
    cheackboxRefs: { [index: string]: any };
    sortableHeaderRefs: { [index: string]: any };
    rowRefs: { [index: string]: any };
    scrollPosition: any;

    constructor(props: ISampleHierarchyGridProps) {
        super(props);
        this.state = {
            ...DEFAULT_STATE,
            treeviewData: props.treeviewData
        };
        this.handleSorting = this.handleSorting.bind(this);
        this.onRowClick = this.onRowClick.bind(this);
        this.onParentRemoveClicked = this.onParentRemoveClicked.bind(this);
        this.cheackboxRefs = [];
        this.sortableHeaderRefs = [];
        this.rowRefs = [];
     
    }

    handleSorting(sortIndicator: ISortIndicator, refKey: string = "") {
        const {
            treeviewData
        } = this.props.resultsGridState;

        if (!treeviewData) return;

        let sortedTreeviewData = this.props.onSort(sortIndicator);

        this.props.setResultsGridState({
            treeviewData: sortedTreeviewData[this.props.resultsGridState.pageIndex],
            paginatedSearchResults: sortedTreeviewData,
            sortIndicator,
            expandedParentIds: [],
        }, () =>refKey !== "" && this.sortableHeaderRefs[refKey].focus());
    }

    onRowClick(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) {
        const {
            expandedParentIds
        } = this.props.resultsGridState;

        let newExpandedParentIds = expandedParentIds;
        const clickedId = event.currentTarget.id;

        if (newExpandedParentIds.includes(clickedId)) {
            newExpandedParentIds = newExpandedParentIds.filter(x => x != clickedId)
        } else {
            newExpandedParentIds.push(clickedId);
        }

        this.props.setResultsGridState({ expandedParentIds: newExpandedParentIds });
    }

    getCustomHeader() {
        const {
            sortIndicator,
        } = this.props.resultsGridState;

        const header = () => <thead><tr style={{ display: 'block' }}>
            {
                <>
                    <ARMHeaderCell
                        id={sortableHeaderIds[0]}
                        key={sortableHeaderIds[0]}
                        setRef={(input: any) => this.sortableHeaderRefs[sortableHeaderIds[0]] = input}
                        refKey={sortableHeaderIds[0]}
                        style={{ whiteSpace: "nowrap", width: "400px" }}
                        dataName="FileName"
                        text="Filename"
                        cellType="text"
                        sortable={true}
                        sortDataType={SortDataType.String}
                        sortHandler={this.handleSorting}
                        sortIndicator={sortIndicator}
                        hasCorrectedTabIndex={true}
                    />
                    <ARMHeaderCell
                        id={sortableHeaderIds[1]}
                        key={sortableHeaderIds[1]}
                        setRef={(input: any) => this.sortableHeaderRefs[sortableHeaderIds[1]] = input}
                        refKey={sortableHeaderIds[1]}
                        dataName="Modified"
                        text="Modified"
                        cellType="text"
                        sortable={true}
                        sortDataType={SortDataType.Date}
                        sortHandler={this.handleSorting}
                        sortIndicator={sortIndicator}
                        hasCorrectedTabIndex={true}
                    />
                    <ARMHeaderCell
                        id={sortableHeaderIds[2]}
                        key={sortableHeaderIds[2]}
                        setRef={(input: any) => this.sortableHeaderRefs[sortableHeaderIds[2]] = input}
                        refKey={sortableHeaderIds[2]}
                        style={{ whiteSpace: "nowrap", width: "400px" }}
                        dataName="Size"
                        text="Size"
                        cellType="text"
                        sortable={true}
                        sortDataType={SortDataType.Number}
                        sortHandler={this.handleSorting}
                        sortIndicator={sortIndicator}
                        hasCorrectedTabIndex={true}
                    />
                    <ARMHeaderCell
                        id={sortableHeaderIds[3]}
                        key={sortableHeaderIds[3]}
                        setRef={(input: any) => this.sortableHeaderRefs[sortableHeaderIds[3]] = input}
                        refKey={sortableHeaderIds[3]}
                        style={{ whiteSpace: "nowrap", width: "400px" }}
                        dataName="Owner"
                        text="Owner"
                        cellType="text"
                        sortable={true}
                        sortDataType={SortDataType.String}
                        sortHandler={this.handleSorting}
                        sortIndicator={sortIndicator}
                        hasCorrectedTabIndex={true}
                    />
                    <ARMHeaderCell
                        id={sortableHeaderIds[4]}
                        key={sortableHeaderIds[4]}
                        setRef={(input: any) => this.sortableHeaderRefs[sortableHeaderIds[4]] = input}
                        refKey={sortableHeaderIds[4]}
                        style={{ whiteSpace: "nowrap", width: "400px" }}
                        cellType="text"
                        sortDataType={SortDataType.String}
                        sortHandler={this.handleSorting}
                        sortIndicator={sortIndicator}
                        hasCorrectedTabIndex={true}
                        dataName="CheckedOut"
                        text="Action"
                        sortable={false}
                    />
                </>
            }
        </tr></thead>
        return header;
    }

    onParentRemoveClicked(e: React.MouseEvent<HTMLInputElement, MouseEvent>) {
        e.preventDefault();
    }




    public doApiCall = async (action: any, filename: any) => {

        let API_CALL = '';
        const CHECKOUT_FILES_API_CALL = 'api/data/BridgeFileCheckout';
        const UNDO_CHECKOUT_FILES_API_CALL = 'api/data/BridgeFileAction';
        const REGISTER_API_CALL = 'api/data/BridgeFileAction';
        const UNDO_CHECKOUT_COMMAND = 'UndoCheckOut';
        const REGISTER_COMMAND = 'Register';
        let body:any;

        switch (action) {
            case 'CHECKOUT':

                API_CALL = CHECKOUT_FILES_API_CALL;

                body = JSON.stringify(filename);

                break;
            case 'UNDOCHECKOUT':

                API_CALL = UNDO_CHECKOUT_FILES_API_CALL;

                body = JSON.stringify({ BridgeFileName: filename, Command: UNDO_CHECKOUT_COMMAND });

                break;
            case 'REGISTER':

                API_CALL = REGISTER_API_CALL;

                body = JSON.stringify({ BridgeFileName: filename, Command: REGISTER_COMMAND });

                break;
            default:
                console.log(`Sorry, no action found for ${action}.`);
        }

        this.props.setBusyLoader(true);
      

        await fetch(URLs.api + '/' + API_CALL,
            {
                method: 'POST',
                      headers: {
                          'Accept': 'application/json',
                          'Content-Type': 'application/json',
                          'Authorization': `${getRawToken()}`
                      },
                body: body

            }).then(response => {
                if (response.status == 500) {
                    this.setState({
                        isValidationModalOpen: true,
                        validationModalMessage: "API has server error.",
                    })

                    this.props.setBusyLoader(false);

                } else
                    return response.json();
            }).then((data) => {

                if (data == "") {

                    //if successful refetch data;

                    this.setState({ isBusy: false });

                    this.props.onGetBridgeFiles();

                } else if (data != "") {

                    this.setState({
                        isValidationModalOpen: true,
                        validationModalMessage: data+"",
                    });

                    this.props.setBusyLoader(false);

                }
                else {
                    this.setState({
                        isValidationModalOpen: true,
                        validationModalMessage: "Something went wrong.",
                    });
                    this.props.setBusyLoader(false);
                };

            }).catch(error => {
                console.log('error:', error + '');
            });
    }






    getCustomRow() {
        const row = ({ record }: IRowProps) => {
            const {
                expandedParentIds,
                treeviewData,
            } = this.props.resultsGridState;



            const {
                CheckedOut,
                Owner,
                FileName,
                Modified,
                Size,
                Revisions
            } = record
            const uID = Size + "" + Owner + "" + FileName + "" + Modified + ""

            //const tOwner = treeviewData?.
 

            let recordId = uID;

           // console.log('TONE', this.props.resultsGridState.topOwner)

            let userName: any = sessionStorage?.getItem("Username");
            if (userName?.toUpperCase().startsWith('NDC\\')) {
                userName = userName.substring(4, userName.length);
            }
            return (
             
                <>
                    <tr id={record.FileSequence.toString()} ref={el => this.rowRefs[record.FileSequence.toString()] = el} key={record.FileSequence} role="button" onClick={(x) => { this.onRowClick(x); this.props.onClearScrollTo() }} style={{ display: 'block' }}>
                        {
                            
                            <>
                                <td className="primary-row-file-name">
                                    <div className="cell-component-wrapper cell-style">
                                        <div className="no-margin cell-style overflow-filename-style">
                                          {/*  the way to check for unregistered files is to see if there are any child records per ASP code---records.Revisions.length*/}

                                            {expandedParentIds.includes(record.FileSequence.toString()) && (record.Revisions.length > 0) &&
                                                <img src={minusSymbol} />
                                            }
                                            {!expandedParentIds.includes(record.FileSequence.toString()) && (record.Revisions.length > 0) &&
                                                <img src={plusSymbol} />
                                            }
                                            <a style={{ cursor: "pointer",  textDecoration:'underline'  }}
                                                href={void (0)}
                                                onClick={async (x) => {
                                                     x.stopPropagation();
                                                    await new FileDownloader().downloadFile(`BRIDGE_ROUTINES/${record.FileName}`);

                                                }} > ${record.FileName} </a>
                                    
                                        </div>
                                    </div>
                                </td>
                                <td className="primary-row-import-date">
                                    <div className="cell-component-wrapper cell-style">
                                        <div className="no-margin cell-style overflow-import-date-style">
                                            {record.Modified}
                                        </div>
                                    </div>
                                </td>
                                <td className="primary-row-payment-source">
                                    <div className="cell-component-wrapper cell-style">
                                        <div className="no-margin cell-style overflow-payment-source-style">
                                            {record.Size}&nbsp;KB
                                        </div>
                                    </div>
                                </td>
                                <td className="primary-row-count">
                                    <div className="cell-component-wrapper cell-style">
                                        <div className="no-margin cell-style overflow-count-style">
                                            {record.Owner}
                                        </div>
                                    </div>
                                </td>
                                <td className="primary-row-checkedOut">
                                    <div className="cell-component-wrapper cell-style">
                                        <div className="no-margin cell-style overflow-checkedOut-style">
                                            {/*  {/this.props.resultsGridState.topOwner !== userName? <img src={checkMark} alt="Check Out" title="Check Out" /> : "" }*/}

                                            {(record.Owner?.toUpperCase() === userName?.toUpperCase()) &&
                                                <>
                                                < img alt="Check In" title="Check In"
                                                     onClick={(x) => {
                                                        // prevent row click event
                                                         x.stopPropagation();
                                                         this.props.onClearScrollTo();
                                                         this.props.onGetFilename(record.FileName);
                                                }}
                                                src={checkFileIn} /> &nbsp;
                                                < img alt="Undo Check Out" title="Undo Check Out"
                                                     onClick={(x) => {
                                                        // prevent row click event
                                                         x.stopPropagation();
                                                         this.doApiCall("UNDOCHECKOUT", record.FileName);
                                                }}
                                                    src={undoCheckFileOut} />
                                            </>
                                            }
                                            {(record.Owner == "" && this.props.resultsGridState.topOwner == "" && record.Revisions.length > 0) &&
                                                <> < img role="button"
                                                src={checkMark}
                                                onClick={async(x) => {
                                                
                                                    x.stopPropagation();
                                                    await new FileDownloader().downloadFile(`BRIDGE_ROUTINES/${record.FileName}`);
                                                    this.props.onHandleScroll(record.FileSequence.toString())
                                                    this.doApiCall("CHECKOUT", record.FileName);

                                                }}
                                                    alt="Check Out"
                                                    title="Check Out" /> </>
                                            }
                                            {(record.Owner == "" && this.props.resultsGridState.topOwner?.toUpperCase() == userName?.toUpperCase() && record.Revisions.length > 0) &&
                                                <>
                                                < img alt="Check Out" title="Check Out"
                                                    onClick={async(x) => {
                                               
                                                        x.stopPropagation();
                                                        await new FileDownloader().downloadFile(`BRIDGE_ROUTINES/${record.FileName}`);
                                                        this.props.onHandleScroll(record.FileSequence.toString())
                                                        this.doApiCall("CHECKOUT", record.FileName);

                                                    }}
                                                        src={checkMark} /> </>
                                            }
                                            {(record.Revisions.length == 0) &&
                                                <>
                                                    < img alt="Register" title="Register"
                                                        onClick={(x) => {
                                                            // prevent row click event
                                                            x.stopPropagation();
                                                            this.props.onHandleScroll(record.FileSequence.toString())
                                                            this.doApiCall("REGISTER", record.FileName);
                                                        }}
                                                        src={register} /> </>
                                            }


                                        
                                        </div>
                                    </div>
                                </td>
                            </>
                        }
                    </tr>
                    {expandedParentIds.includes(record.FileSequence.toString()) &&
                        <>
                            <tr className="child-header" style={{ display: 'block'}}>
                                <th className="child-header-payment-date">
                                    <div>
                                        <img src={i} />
                                    <button tabIndex={-1}>
                                        <td style={{ color: '#FFF' }}>
                                                Version
                                            </td>
                                        </button>
                                    </div>
                            </th>
                            <th className="child-header-payment-no" >
                                <div>
                                    <button tabIndex={-1}>
                                        <td style={{ color: '#FFF' }}>
                                           Checked In
                                            </td>
                                    </button>
                                </div>
                            </th>
                            <th className="child-header-provider-no"  >
                                <div>
                                    <button tabIndex={-1}>
                                        <td style={{ color: '#FFF' }}>
                                           Note 
                                            </td>
                                    </button>
                                </div>
                            </th>
                            <th className="child-header-checkedOut" >
                                <div>
                                    <button tabIndex={-1}>
                                        <td style={{ color: '#FFF' }}>
                                            Action
                                            </td>
                                    </button>
                                </div>
                            </th>
                        </tr>
                        {record.Revisions && record.Revisions.map((childRecord: IChildRecord, i) => {
                            return (
                                <tr className="child-row" style={{ display: 'block' }}>
                                    <td className="child-row-payment-date">
                                        <div className="cell-component-wrapper cell-style">
                                            <div className="no-margin cell-style overflow-child-amount-style">
                                                {i < (record.Revisions.length-1) &&
                                                    < img src={l_end} />
                                                }
                                                {i == (record.Revisions.length - 1) &&
                                                    < img src={l_middle} />
                                                }
                                 
                                                        {childRecord.RevisionNumber}
                                            
                                         
                                            </div>
                                        </div>
                                    </td>
                                    <td className="child-row-payment-no">
                                        <div className="cell-component-wrapper cell-style">
                                            <div className="no-margin cell-style overflow-payment-no-style">
                                                {childRecord.RevisionDate}
                                            </div>
                                        </div>
                                    </td>
                                    <td className="child-row-provider-no">
                                        <div className="cell-component-wrapper cell-style">
                                            <div className="no-margin cell-style overflow-provider-no-style">
                                                {childRecord.Note}
                                            </div>
                                        </div>
                                    </td>
                                    <td className="child-row-amount">
                                        <div className="cell-component-wrapper cell-style">
                                            <div className="no-margin cell-style overflow-child-amount-style">
                                                {(record.Status == "Up-to-date") &&
                                                    <> < img role="button"
                                                    src={downloadImage}
                                                    onClick={async () => {

                                                        await new FileDownloader().downloadFile(`BRIDGE_ROUTINES/${childRecord.RevisionNumber}=${record.FileName}`);

                                                    }}
                                                    alt="Download"
                                                    title="Download" /> </>
                                                }
                                            </div>
                                        </div>
                                    </td>
                                </tr>

                            );
                   
                                })
                                }
                        </>
                      
                    }
                        </>
            );
        }
                    return row;
    }

    render() {
        const {
            treeviewData
        } = this.props.resultsGridState;

        const data = treeviewData?.parentData
        const columns = GridConfig.getColumnsConfig();
        const row = this.getCustomRow();
        const header = this.getCustomHeader();

        return (
            <GridWrapper>
                {treeviewData?.parentData &&
                    <ARMGrid
                        domID='sample-treeview-grid'
                        maxHeight={'350px'}
                        isFixedHeader={true}
                        columns={columns}
                        headerComponent={header}
                        rowComponent={row}
                        records={data}
                        emptyGridMessage=' '
                        selectionKey={'FileName'} />
                }
            </GridWrapper>
        )
    }
}
