export interface IHeaderProps {
    columns: {
    }
}

export interface IRowProps {
    record: {
        FileName: string;
        Modified: string;
        Size: string;
        Owner: string;
        CheckedOut: string;
        Revisions: [],
        tOwner: string,
        Status: string,
        FileSequence:number
    }
}

interface IUiclColumn {
    dataName: string,
    text: string,
    sortable?: boolean,
    cellType?: string,
    isSorted?: number,
}

export class GridConfig {
    public static getColumnsConfig(): Set<IUiclColumn> {
        const columns: IUiclColumn[] = [
            {
                text: 'File Name',
                dataName: 'FileName',
                sortable: true,
                cellType: 'text',
                // isSorted: 0,
            },
        ];
        return new Set(columns);
    }
}
