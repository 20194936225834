import { Reducer, Dispatch, AnyAction  } from 'redux';
import {
    createDataAction,
    ActionTypes,
    createApiAction,
    createApiBodyAction
} from '@scripts/util/ActionHelpers';
import {URLs} from "@commonResources/constants";
import { ComplexApiAction, IThunkAction, IThunkApiAction, IThunkResult } from "@scripts/util/ThunkHelpers";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IUserEmailConfirmUIState {
    emailAddress: string;
    originalEmailAddress: string;
    code: string;
    successMessage: string;
    statusMessage: string;
    emailErrorMessage: string;
    codeErrorMessage: string;
    enforceCiamLogin: boolean;
    confirmedEmail: boolean;
    allowConfirmation: boolean;
    flowComplete: boolean;
    modalOpen: boolean;
    isBusy: boolean;
    buttonBusy: boolean;
    changed: boolean;
}

export interface IUserEmailConfirmUIData {
    index: string;
    value: boolean;
};

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
//export interface IAN_LoadUserEmailConfirm extends IApiActionData<APICF_ListPopulationContainer, IClaimFilterUIFieldData> { }
export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: IUserEmailConfirmUIState = {
    emailAddress: '',
    originalEmailAddress: '',
    code: '',
    successMessage: '',
    statusMessage: '',
    emailErrorMessage: '',
    codeErrorMessage: '',
    enforceCiamLogin: false,
    confirmedEmail: false,
    allowConfirmation: false,
    flowComplete: false,
    modalOpen: false,
    isBusy: false,
    buttonBusy: false,
    changed: false,
};

export const actionCreators = {
    getEmailInfo: () => createApiAction('UEC_GET_EMAIL_INFO',`${URLs.api}/api/data/ciam/getEmailStatus`),
    updateEmailValue: (emailValue : string) => createDataAction('UEC_UPDATE_EMAIL', emailValue),
    updateCodeValue: (codeValue : string) => createDataAction('UEC_UPDATE_CODE', codeValue),
    sendConfirmationEmail: (email : string) => createApiAction('UEC_SEND_EMAIL',`${URLs.api}/api/data/ciam/sendConfirmationEmail/${email}/`),
    emailConfirmLink: (email : string) => createApiBodyAction('UEC_EMAIL_LINK',`${URLs.api}/api/data/ciam/emailConfirmLink/`, undefined, "POST", email),
    setInvalidEmail: (emailErrorMsg: string) => createDataAction('UEC_INVALID_EMAIL', emailErrorMsg),
    setInvalidCode: (codeErrorMsg: string) => createDataAction('UEC_INVALID_CODE', codeErrorMsg),
    setStatusMessage: (statusMsg: string) => createDataAction('UEC_STATUS_MSG', statusMsg),
    sendConfirmationCode: (code : string) => createApiAction('UEC_SEND_CONFIRM_CODE',`${URLs.api}/api/data/ciam/verifyConfirmationCode/${code}`),
    sendCodeAndLink: (code: string, email: string) => {
        return ComplexApiAction
            .fromAction(actionCreators.sendConfirmationCode(code))
            .addThunk((
                    dispatch: Dispatch<AnyAction>,
                    apiResult: IThunkApiAction<string, any, any>): IThunkResult<void> => {
                    if (apiResult.status.status === 'SUCCESS' && apiResult.responseData) {
                        if(apiResult.responseData.validCode) {
                            dispatch(actionCreators.emailConfirmLink(email));
                        }
                        return {success: true}
                    }
                    return {success: false}
                }
            )
            .finish();
    },
    setModalOpen: (isOpen: boolean) => createDataAction('UEC_MODAL_OPEN', isOpen),
    setBusy: (isBusy : boolean) => createDataAction('UEC_SET_BUSY', isBusy),
    setButtonBusy: (btnBusy : boolean) => createDataAction('UEC_SET_BUTTON_BUSY', btnBusy)
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<IUserEmailConfirmUIState, KnownActions> = (state: IUserEmailConfirmUIState | undefined, action: KnownActions) => {
    if (state != undefined) {
        switch (action.type) {

            case 'UEC_GET_EMAIL_INFO':
                switch (action.status.status) {
                    case "REQUEST":
                        break;

                    case "SUCCESS":
                    {
                        let emailInfoResponseData = action?.responseData;
                        if (emailInfoResponseData) {
                            if(emailInfoResponseData.confirmed)
                                return{
                                    ...state,
                                    isBusy: false,
                                    enforceCiamLogin: emailInfoResponseData.enforceCiamLogin,
                                    emailAddress: emailInfoResponseData.email,
                                    originalEmailAddress: emailInfoResponseData.email,
                                    confirmedEmail: emailInfoResponseData.confirmed,
                                    statusMessage: '',
                                    successMessage : 'Your email address has already been validated on this client!<br/><br/>You are now being redirected.'
                                }
                            return {
                                ...state,
                                allowConfirmation: !(emailInfoResponseData.notSent || emailInfoResponseData.timeLimitExceeded),
                                emailAddress: emailInfoResponseData.email,
                                originalEmailAddress: emailInfoResponseData.email,
                                enforceCiamLogin: emailInfoResponseData.enforceCiamLogin,
                                confirmedEmail: emailInfoResponseData.confirmed,
                                statusMessage: '',
                                isBusy: false
                            }
                        } else {
                            return{
                                ...state,
                                allowConfirmation: false,
                                emailErrorMessage: 'There was an issue getting email information. Please try again.',
                                statusMessage: '',
                                isBusy: false
                            }
                        }
                    }
                    case "FAIL":
                    {
                        return{
                            ...state,
                            emailErrorMessage: 'There was an issue obtaining email address info.',
                            statusMessage: '',
                            isBusy: false
                        }
                    }
                }
                break;
            case 'UEC_SEND_EMAIL':
                switch (action.status.status) {
                    case "REQUEST":
                        break;

                    case "SUCCESS":
                    {
                        let sendEmailResponseData = action?.responseData;
                        if (sendEmailResponseData) {
                            return {
                                ...state,
                                allowConfirmation: true,
                                isBusy: false,
                                statusMessage: '',
                                buttonBusy: false
                            }
                        } else {
                            console.log('No data received.');
                        }
                        break;
                    }
                    case "FAIL":
                    {
                        let emailMessage = 'An error occurred while sending the email. Please try again later.'
                        if(action?.responseData?.message){
                            if(action.responseData.message.indexOf('already confirmed') > 0)
                                emailMessage = 'Duplicate email address.'
                        }
                        return{
                            ...state,
                            emailErrorMessage: emailMessage,
                            allowConfirmation: false,
                            statusMessage: '',
                            isBusy: false,
                            buttonBusy: false
                        }
                    }
                }
                break;
            case 'UEC_EMAIL_LINK':
                switch (action.status.status) {
                    case "REQUEST":
                        break;

                    case "SUCCESS":
                    {
                        // 200 back but may have error detail
                        let linkResponseData = action?.responseData;
                        let errorMessage = '';
                        let successMessage = '';
                        let didLink = false;

                        if (linkResponseData.users[0].ciamErrorMessages || linkResponseData.users[0].message.indexOf('An error occurred') > -1) {
                            errorMessage = "Error Linking Email: "
                            errorMessage += linkResponseData.users[0].message;
                            //errorMessage += ". If the issue persists, please contact Support.";
                        }
                        else{
                            successMessage = "Your email was successfully linked to CIAM.<br/><br/> Please login using the CIAM Link.";
                            didLink = true;
                        }
                        if (linkResponseData) {
                            return {
                                ...state,
                                emailErrorMessage: errorMessage,
                                successMessage: successMessage,
                                statusMessage: '',
                                flowComplete: didLink,
                                isBusy: false,
                                buttonBusy: false,
                            }
                        } else {
                            console.log('No data received.');
                        }
                        break;
                    }
                    case "FAIL":
                    {
                        return{
                            ...state,
                            emailErrorMessage: 'Error with link request. Please try again.',
                            allowConfirmation: false,
                            statusMessage: '',
                            isBusy: false,
                            buttonBusy: false
                        }
                    }
                }
                break;                
            case 'UEC_UPDATE_EMAIL':
                return {
                    ...state,
                    emailAddress: action.data,
                    emailErrorMessage: ''
                }
            case 'UEC_UPDATE_CODE':
                return {
                    ...state,
                    code: action.data,
                    codeErrorMessage: ''
                }                
            case 'UEC_INVALID_EMAIL':
                return {
                    ...state,
                    emailErrorMessage: action.data,
                }
            case 'UEC_INVALID_CODE':
                return {
                    ...state,
                    codeErrorMessage: action.data,
                }
            case 'UEC_STATUS_MSG':
                return {
                    ...state,
                    statusMessage: action.data,
                }
            case 'UEC_SEND_CONFIRM_CODE':
                switch (action.status.status) {
                    case "REQUEST":
                        break;

                    case "SUCCESS":
                    {
                        let responseData = action?.responseData;
                        if (responseData.validCode) {
                            return {
                                ...state,
                                successMessage: 'Thank you for validating your email address! <br/><br/> You are now being redirected to back to login.',
                                buttonBusy: false
                            }
                        } else {
                            return {
                                ...state,
                                codeErrorMessage: 'Invalid code.',
                                statusMessage: '',
                                buttonBusy: false
                            }
                        }
                    }
                    case "FAIL":
                    {
                        return{
                            ...state,
                            codeErrorMessage: 'Invalid code.',
                            statusMessage: '',
                            buttonBusy: false
                        }
                    }
                }
                break;
            case 'UEC_MODAL_OPEN':
                return {
                    ...state,
                    modalOpen: action.data,
                }                
            case 'UEC_SET_BUSY':
                return {
                    ...state,
                    isBusy: action.data,
                }
            case 'UEC_SET_BUTTON_BUSY':
                return {
                    ...state,
                    buttonBusy: action.data,
                }
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                //const exhaustiveCheck: never = action;
                return state;
        }
    }    
    return state || defaultState;
}

