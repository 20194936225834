import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { TabContainer, Typography, LoadingIndicator } from 'ui-core';
import styled from 'styled-components';
import { DialogWrapper, DialogButtonOK } from '@common/DialogWrapper';
import { createApiMapDispatchToProps, createApiMapStateToProps, IMergeApiComponentProps, mergeApiComponentProps, resetApiComponentState } from '../../../scripts/util/ApiDataHelpers';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { actionCreators, ISiteFileActionProps, ISiteFileState } from '@store/SiteFile';
import { UseTabGrid } from './UseTabGrid';
import { SortHelper, ISortIndicator, SortDataType } from '@scripts/util/SortHelper';
import { ISampleTreeviewData } from './ISampleTreeviewData';
import { getRawToken } from "@scripts/session/SecurityToken";
import { UploadSiteFile } from './UploadSiteFile';
import { URLs } from '@commonResources/constants';
import Colors from '@commonResources/colorVariables';
import {isArray, isEmpty, uniqueId} from 'lodash';
import { ModalConfirmation } from '@common/ModalConfirmation';
import refreshArrow from './icons/RefreshArrow.png';
import register from './icons/cvs_register.gif';
import minusSymbol from './icons/minus.gif';
import l_middle from './icons/l-middle.gif';

export const ContentWrapper = styled.div`
    display: flex;
    flex:1 auto;
    flex-direction: row;
    width:950px;

    /*Override Tab Styles*/
    #site-file-use-tab,
    #site-file-upload-tab,
    #site-file-navigate-tab,

    #site-file-tools-tab, > :first-child button {
        padding-top:1px;
        padding-bottom:1px;
        font-size:11px;
    }     
    
    .siteFilesLabel {
        display: inline-block;
        margin-top: 1px;
        cursor: pointer;
    }
    
    .siteFilesLabel2 {
        margin-top: -33px;
        position: absolute;
        margin-left: 37px;
        cursor: pointer;
    }
    
    .siteFilesLabel3 {
        margin-top: -33px;
        position: absolute;
        margin-left: 23px;
        cursor: pointer;
    }
    
    .nav-select {
        display: inline-block;
        text-decoration: underline;
    }
    
    .nav-select:hover {
        background-color: #B6BDD2;
    
    }
    .highlight{
        display: inline-block
        background-color: gold; 
        border-color: #000000; 
        padding: 1px;
    }
    
    .noHighlight{
        display: inline-block;
    }

    .link {
        ${ Typography.defaultLineHeight};
        ${Typography.medium};
        color: ${Colors.brightBlue130};
        padding: 0px;
        text-decoration: underline;
        &: hover {
            text-decoration-color: ${Colors.babyBlue};
            {cursor: pointer;}
        }
    }
    
    .navImg {
        display: inline;
        top: 4px;
        position: relative;
    }
    
`;

const ResultsTabChinContentDiv = styled.div`
    display: flex;
    justify-content: space-between; 
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    color: ${Colors.grey100};
    ${Typography.small};
    ${Typography.defaultLineHeight};
    ${Typography.bold};
    ${Typography.LetterSpacing};
    ${Typography.uppercase};
`;

const ResultsNavContentDiv = styled.div`
    display: flex;
    justify-content: space-between; 
    align-items: center;
    color: ${Colors.grey100};
    ${Typography.small};
    ${Typography.defaultLineHeight};
    ${Typography.bold};
    ${Typography.LetterSpacing};
    ${Typography.uppercase};
`;

const ReasonContainerDiv = styled.div`
    display: flex;
    align-items: center;
`;

const ReasonInputDiv = styled.div`
    width: 100px;
    height: 32px;
    padding-left: 5px;
    position: relative;
`;

export const pageSize = 50;

interface ITabSelectInfo {
    selectedTabIndex: Number;
}

interface ITabChangeInfo {
    tabIndex: Number;
}

interface searchResultsObj {
    "topOwner": string;
    "parentData": any[];
};


interface IComponentProps {
    title: string,
};


interface paginatedSearchResult {
    childData: any[];
    parentData: any[];
};

interface ICvsRecord {
    FileName: string;
    Modified: string;
    Size: string;
    Owner: string;
    CheckedOut: string;
    Revisions: [],
    tOwner: string,
    Status: string,
    FileSequence: number,
    DeletedBy: string,
}

interface IComponentState {
    selectedIndex: number,
    activeTab: number,
    cancelLeave: boolean;
    isBusy: boolean;
    readOnly: boolean;
    haveUnregisteredFile: boolean;
    topOwner: string;
    validationModalMessage: string;
    isValidationModalOpen: boolean;
    reasonInputError: boolean;
    reasonInputText: string;
    isHoveringOverBack: boolean;
    isHoveringOverFoward: boolean;
    searchResults: searchResultsObj;
    paginatedSearchResults: paginatedSearchResult[];
    pageIndex: number;
    dateUUID: string;
    resultsUUID: string;
    treeviewData: ISampleTreeviewData | undefined;
    expandClicked: boolean;
    expandedParentIds: string[];
    inputValue: string;
    sortIndicator: ISortIndicator;
    filesData: any[];
    fileToCheckin: string
    recordToScrollTo: string
    unRegisteredFiles: any[];
    registerFilesMessage: string;
    currentDirectory: string;
    currentHeaderDir: string;
    showRegisterAllFilesAlert: boolean;
    currentDirective: string;
};

export const DEFAULT_STATE: IComponentState = {
    selectedIndex: 0,
    activeTab: 0,
    cancelLeave: false,
    isBusy: true,
    readOnly: false,
    haveUnregisteredFile: false,
    topOwner: "",
    validationModalMessage: "",
    isValidationModalOpen: false,
    reasonInputError: false,
    reasonInputText: "",
    isHoveringOverBack: false,
    isHoveringOverFoward: false,
    searchResults: {
        "topOwner": "",
        "parentData": []
    },
    paginatedSearchResults: [{
        childData: [],
        parentData: [],
    }],
    pageIndex: 0,
    dateUUID: uniqueId(),
    resultsUUID: "" + Date.now(),
    treeviewData: undefined,
    expandClicked: false,
    expandedParentIds: [],
    inputValue: '',
    sortIndicator: {
        sortColumn: 'FileName',
        sortDirection: 'up',
        sortDataType: SortDataType.Date
    },
    filesData: [],
    fileToCheckin: "",
    recordToScrollTo: "",
    unRegisteredFiles: [],
    registerFilesMessage: "REGISTER ALL FILES",
    currentDirectory: "Site Files",
    currentHeaderDir: "Site Files",
    showRegisterAllFilesAlert: false,
    currentDirective: "SITEFILES"
};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;
type ISiteFileProps = IMergeApiComponentProps<ISiteFileState, ISiteFileActionProps, IOwnProps>;

export class SiteFile extends React.Component<ISiteFileProps, IComponentState> {
   
    static defaultProps: IComponentProps = {
        title: 'Site File Maintenance'
    };

    constructor(props: ISiteFileProps) {
        super(props);

        this.state = DEFAULT_STATE;

        this.getSiteFiles();

    }

    handleScroll = (e: string) => {
        this.setState({ recordToScrollTo: e })
    }


    componentDidUpdate(prevProps: any, prevState: any) {

        if (this.state.recordToScrollTo !== "") {
            document.getElementById(this.state.recordToScrollTo)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }


    public async componentWillUnmount() {
        this.setState({
            selectedIndex: 0,
            activeTab: 0,
            cancelLeave: false,
            reasonInputError: false,
            reasonInputText: this.state.topOwner,
            isHoveringOverBack: false,
            isHoveringOverFoward: false,
            recordToScrollTo: "",
            searchResults: {
                "topOwner": "",
                "parentData": []
            },
            paginatedSearchResults: [{
                childData: [],
                parentData: [],
            }],
            dateUUID: uniqueId(),
            resultsUUID: "" + Date.now(),
            treeviewData: undefined,
            expandClicked: false,
            expandedParentIds: [],
            inputValue: '',
            sortIndicator: {
                sortColumn: 'FileName',
                sortDirection: 'up',
                sortDataType: SortDataType.Date
            },
        });

        resetApiComponentState(this.props.action, this.props.dataStore);
    };

    public getPageSelectionButtons = () => {
        const {
            isHoveringOverBack,
            isHoveringOverFoward,
            paginatedSearchResults,
            pageIndex
        } = this.state;

        const nPages = paginatedSearchResults.length

        const isBackArrowDisabled = (nPages < 2) || (pageIndex < 1)
        const isFowardArrowDisabled = (nPages < 2) || (pageIndex === nPages - 1)

        const isBackArrowGray = isBackArrowDisabled || isHoveringOverBack
        const isFowardArrowGray = isFowardArrowDisabled || isHoveringOverFoward

        return (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <div
                    onMouseEnter={() => { this.setState({ isHoveringOverBack: true }); this.clearScrollTo(); }}

                    onMouseLeave={() => { this.setState({ isHoveringOverBack: false }); this.clearScrollTo(); }}

                    onClick={() => {
                        isBackArrowDisabled || this.setState({
                            pageIndex: pageIndex - 1,
                            resultsUUID: "" + Date.now(),
                            expandedParentIds: [],
                        })
                        this.clearScrollTo();
                    }} >
                    {
                        isBackArrowGray ?
                            <img src={'/Shared/Images/Buttons/But_ArrowLeft_creamOff.png'} /> :
                            <img src={'/Shared/Images/Buttons/But_ArrowLeft_creamUp.png'} />
                    }
                </div>
                <div style={{ paddingBottom: "5px" }}>{pageIndex + 1 + "/" + nPages}</div>
                <div
                    onMouseEnter={() => { this.setState({ isHoveringOverFoward: true }); this.clearScrollTo(); }}

                    onMouseLeave={() => { this.setState({ isHoveringOverFoward: false }); this.clearScrollTo(); }}

                    onClick={() => {
                        isFowardArrowDisabled || this.setState({
                            pageIndex: pageIndex + 1,
                            resultsUUID: "" + Date.now(),
                            expandedParentIds: [],
                        })
                        this.clearScrollTo();
                    }} >
                    {
                        isFowardArrowGray ?
                            <img src={'/Shared/Images/Buttons/But_ArrowRight_creamOff.png'} /> :
                            <img src={'/Shared/Images/Buttons/But_ArrowRight_creamUp.png'} />
                    }
                </div>
            </div>
        );
    };

    public setResultsGridState = (obj: object, funct: () => any = () => { }) => {
        this.setState({ ...this.state, ...obj }, funct);
    };

    public getSiteFiles = async () => {
        const {
            currentDirective
        } = this.state;
        
        this.setState({ isBusy: true });

        await fetch(URLs.api + `/api/data/allSiteFiles/${currentDirective}`,
            {
                method: 'GET',             

            }).then(response => {
                if (response.status == 500) {
                    this.setState({
                        isValidationModalOpen: true,
                        validationModalMessage: "There was an issue retrieving Site Files data.",
                        isBusy: false
                    })

                } else
                    return response.json();
            }).then((data) => {

                if (data) {
                    this.setState({ filesData: data, topOwner: data.Owner });

                    /*Iterate data and check for unregistered files
                     * Which are records that do not have any revisions
                    */

                    let countOfUnRegisteredFiles = 0;

                    let arrUnRegisteredFiles: any[] = [];

                    Object.entries(data).forEach((entry) => {
                        const [key, value] = entry;
                        if (isArray(value)) {
                            value.map((e, i, a) => {
                                let record: ICvsRecord = e;
                                if (record.Revisions.length == 0) {
                                    arrUnRegisteredFiles.push(record.FileName);
                                    countOfUnRegisteredFiles++;
                                }
                            })
                        }
                    });


                    if (arrUnRegisteredFiles.length > 0) {

                        this.setState({ unRegisteredFiles: arrUnRegisteredFiles });
                    }

                    this.setState({
                        // selectedIndex: 0,
                        pageIndex: this.state.pageIndex > 0 ? this.state.pageIndex : 0,
                        haveUnregisteredFile: countOfUnRegisteredFiles > 0 ? true : false,
                        isBusy: false,
                        searchResults: {
                            topOwner: data.Owner,
                            parentData: data.Files
                        },
                        topOwner: data.Owner,
                        paginatedSearchResults: this.paginateResults(data.Files, {
                            sortColumn: 'FileName',
                            sortDirection: 'up',
                            sortDataType: SortDataType.Date
                        }),
                        resultsUUID: "" + Date.now(),
                        expandedParentIds: [],
                    });
                } else {
                    this.setState({
                        pageIndex: 0,
                        searchResults: {
                            "topOwner": "",
                            "parentData": []
                        },
                        paginatedSearchResults: [{
                            childData: [],
                            parentData: [],
                        }],
                        resultsUUID: "" + Date.now(),
                    });
                };

            }).catch(error => {
                console.log('error:', error);
            });
    }

    public formatResults = (data: any) => {
        const formattedResults = {
            parentData: data.map((files: any) => {
                const {
                    CheckedOut,
                    Size,
                    FileName,
                    Modified,
                    Owner,
                    Revisions,
                    topOwner,
                    Status,
                    FileSequence,
                    DeletedBy
                } = files
                return ({
                    CheckedOut,
                    Size,
                    FileName,
                    Modified,
                    Owner,
                    Revisions,
                    topOwner,
                    Status,
                    FileSequence,
                    DeletedBy,
                })
            })

        };


        return formattedResults;
    };

    public paginateResults = (data: any, sortIndicator: any) => {
        if(data) {
            let sortedData: any;
            if(data.parentData) {
                sortedData = SortHelper.Sort(
                    data.parentData,
                    sortIndicator
                );
            }
            else {
                sortedData = SortHelper.Sort(
                    data,
                    sortIndicator
                )
            }
            const nPages = sortedData.length === 0 ? 1 : Math.ceil(sortedData.length / pageSize);
            let pages: any[] = [];
            let dataSlice: any[] = [];
            let formattedDataSlice;
            for (let i = 0; i < nPages; i++) {
                dataSlice = sortedData.slice((i * pageSize), ((i + 1) * pageSize));
                formattedDataSlice = this.formatResults(dataSlice);
                pages = [
                    ...pages,
                    formattedDataSlice
                ];
            }
            return pages;
        }
    }
    
    public user = () => {
        let userName: any = sessionStorage?.getItem("Username")?.toUpperCase();
        if (userName?.startsWith('NDC\\')) {
            return userName.substring(4, userName.length);
        }
        return "";
    }

    public createLockorUnlockButton = () => {


        let userName: string = this.user();

        if(!this.state.isBusy) {
            
            if (this.state.topOwner != "") {
                if (this.state.topOwner.toUpperCase() === userName?.toUpperCase()) {
                    return <><img role="button"
                                  style={{cursor: "pointer"}}
                                  onClick={() => this.doApiCall("UNLOCK", "", true)}
                                  src={refreshArrow}/>&nbsp;
                        <a style={{cursor: "pointer"}} href={void (0)} onClick={() => {
                            this.clearScrollTo();
                            this.doApiCall("UNLOCK", "", true);
                        }}> UNLOCK </a>
                    </>;
                } else {
                    return "Locked by " + this.state.topOwner;
                }
            } else {
                return <><img role="button"
                              style={{cursor: "pointer"}}
                              onClick={() => this.doApiCall("LOCK", "", true)}
                              src={refreshArrow}/>&nbsp;
                    <a style={{cursor: "pointer"}} href={void (0)} onClick={() => {
                        this.clearScrollTo();
                        this.doApiCall("LOCK", "", true)
                    }}> LOCK </a></>;
            }
        }
    }

    public doApiCall = async (action: string, filename: string, resetFiles: boolean) => {

        let API_CALL = "";
        const LOCK_FILES_API_CALL = "api/data/LockSiteFiles";
        const UNLOCK_FILES_API_CALL = "api/data/UnlockSiteFiles";
        const DELETE_FILE_API_CALL = "api/data/deleteSiteFile";
        const UNDOCHECKOUT_API_CALL = "api/data/undoCheckOut";
        const UNDELETE_FILE_API_CALL = "api/data/undeleteSiteFile"
        const REGISTER_API_CALL = 'api/data/BridgeFileAction';
        const REGISTER_COMMAND = 'Register';
        let body: any;

        switch (action) {
            case 'LOCK':
                API_CALL = LOCK_FILES_API_CALL;
                break;
            case 'UNLOCK':
                API_CALL = UNLOCK_FILES_API_CALL;
                break;
            case 'REGISTER':
                API_CALL = REGISTER_API_CALL;
                body = JSON.stringify({ BridgeFileName: filename, Command: REGISTER_COMMAND });
                break;
            case 'DELETE':
                API_CALL = DELETE_FILE_API_CALL;
                body = JSON.stringify({ BridgeFileName: filename, Command: DELETE_FILE_API_CALL });
                break;
            case 'UNDOCHECKOUT':
                API_CALL = UNDOCHECKOUT_API_CALL;
                body = JSON.stringify({ BridgeFileName: filename, Command: UNDOCHECKOUT_API_CALL });
                break;
            case 'UNDELETE':
                API_CALL = UNDELETE_FILE_API_CALL;
                body = JSON.stringify({ BridgeFileName: filename, Command: UNDELETE_FILE_API_CALL });
                break;
            default:
                console.log(`Sorry, no action found for ${action}.`);
        }


        //this.setState({ isBusy: true });
        this.setState({
            isBusy: true,
            activeTab: 0,
            pageIndex: 0,
            searchResults: {
                "topOwner": "",
                "parentData": []
            },
            paginatedSearchResults: [{
                childData: [],
                parentData: [],
            }],
            resultsUUID: "" + Date.now(),
        });

        await fetch(URLs.api + '/' + API_CALL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`
                },
                body: body

            }).then(response => {
                if (response.status == 500) {
                    this.setState({
                        isValidationModalOpen: true,
                        validationModalMessage: "API has server error.",
                        isBusy: false
                    })

                } else
                    return response.json();
            }).then((data) => {

                if (data == "") {
                    //if successful refetch data;
                    this.setState({ isBusy: false });
                    if (resetFiles == true) {
                        console.log('reset');
                        this.getSiteFiles();
                    }
                } else if (data != "") {
                    this.setState({
                        isValidationModalOpen: true,
                        validationModalMessage: data + " ",
                        isBusy: false
                    })
                }
                else {
                    this.setState({
                        isValidationModalOpen: true,
                        validationModalMessage: "Something went wrong.",
                        isBusy: false
                    })
                };
            }).catch(error => {
                console.log('error:', error + "");
            });
    }

    public doRegisterFiles = (filename: string) => {

        this.setState({
            fileToCheckin: filename
        })

        document.getElementById('site-file-upload-tab')?.click();

    };

    public doCheckIn = (filename: string) => {
        this.setState({
            fileToCheckin: filename
        })

        document.getElementById('site-file-upload-tab')?.click();
    };

    public doRegisterAllFiles = async () => {

        if (this.state.unRegisteredFiles.length > 0) {

            this.setState({ registerFilesMessage: `Registering All Files...` });

            let isAllFilesRegistered: boolean = false;
            this.state.unRegisteredFiles.forEach(async (fileName, i) => {

                //update message
                this.doApiCall('REGISTER', fileName, false);
                console.log(`Registering ${fileName}`);
                if (i === this.state.unRegisteredFiles.length - 1) {
                    isAllFilesRegistered = true;
                    this.setState({ registerFilesMessage: `ALL FILES REGISTERED`, showRegisterAllFilesAlert: false });
                }
                if (isAllFilesRegistered) {
                    this.getSiteFiles();
                }
            });
        }

    };

    public clearFileValue = () => {

        this.setState({
            fileToCheckin: ""
        })

    };

    public clearScrollTo = () => {

        this.setState({
            recordToScrollTo: ""
        })

    };

    public onOKViewResponse(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.history.push("/LandingPage");
    }


    onToggleConfirmation(e: React.MouseEvent<HTMLElement>) {
        this.props.action.confirm.closeConfirm();
    }

    public onClickConfirm(e: React.MouseEvent<HTMLButtonElement>) {
        this.props.dataStore.confirm.confirmCallback();
    }

    public onFileUploadSave = () => {
        this.getSiteFiles();
    }
    
    public getFiles(filesType: string ) {
        // SITEFILES | CLIENT_DIRECTENTRY | SYSTEM_DIRECTENTRY
        let headerDir: string = "Site Files";
        let currentDir: string = "Site Files";
        
        switch (filesType) {

            case 'CLIENT_DIRECTENTRY':
                headerDir = 'Direct Entry Scripts';
                currentDir = 'DE Scripts';
                break;
            case 'SYSTEM_DIRECTENTRY':
                headerDir = 'Global Direct Entry Scripts';
                currentDir = 'Global DE Scripts';
                break;
            default :
                break;
        }

        this.setState({
            activeTab: 0,
            currentHeaderDir: headerDir,
            currentDirectory: currentDir,
            currentDirective: filesType,
            pageIndex: 0,
            searchResults: {
                "topOwner": "",
                "parentData": []
            },
            paginatedSearchResults: [{
                childData: [],
                parentData: [],
            }],
            resultsUUID: "" + Date.now(),
            sortIndicator: {
                sortColumn: "",
                sortDirection: "",
                sortDataType: undefined
            },
        }, () => this.getSiteFiles());        
    }

    getUseTab() {
        const {
            searchResults,
            paginatedSearchResults,
            pageIndex,
            resultsUUID,
            currentDirectory,
            isBusy,
            haveUnregisteredFile,
            currentDirective,
            topOwner,
        } = this.state
        //TODO tie into UI STORE
        let footerMsg = `Current Directory: ${currentDirectory}`;
        
        return (
            <div id="1">
                <UseTabGrid {...this.props}
                    key={resultsUUID}
                    treeviewData={paginatedSearchResults[pageIndex]}
                    setResultsGridState={this.setResultsGridState}
                    onSort={(sortIndicator: any) => { return this.paginateResults(searchResults, sortIndicator) }}
                    resultsGridState={{ ...this.state, treeviewData: paginatedSearchResults[pageIndex] }}
                    onGetBridgeFiles={this.getSiteFiles}
                    setBusyLoader={this.setBusyLoader}
                    onGetFilename={this.doCheckIn}
                    onClearScrollTo={this.clearScrollTo}
                    onHandleScroll={this.handleScroll}
                    directive={currentDirective}
                    lockBy={topOwner}
                />
                <ResultsTabChinContentDiv>
                    <ReasonContainerDiv>
                        <ReasonInputDiv>
                            <div style={{ paddingTop: "10px", width: "150px", display: "flex" }} > {this.createLockorUnlockButton()}</div>
                        </ReasonInputDiv>
                    </ReasonContainerDiv>
                    <>
                        {
                            isBusy ? (
                                <React.Fragment>
                                    <div style={{ paddingTop: "2px", width: "315px", paddingLeft:"5px", display: "flex", position: "absolute", bottom: "300px", left: "330px" }} ><LoadingIndicator length="17px"/>&nbsp;&nbsp;Retrieving {currentDirectory}, please wait.. </div>
                                    <div style={{ paddingTop: "2px", width: "250px", paddingLeft:"5px", display: "flex" }} >&nbsp;</div>
                                </React.Fragment>
                            ) :
                            (
                                    <React.Fragment>
                                    <div style={{ paddingTop: "2px", width: "250px", paddingLeft:"5px", display: "flex" }} > {footerMsg}</div>
                                    </React.Fragment>
                            )
                        }
                    </>
                    {
                        (haveUnregisteredFile && (topOwner?.toUpperCase() == this.user()?.toUpperCase() || isEmpty(topOwner?.toUpperCase())) && !isBusy )  && (
                            <React.Fragment>
                                <div style={{ paddingTop: "2px", width: "150px", paddingLeft: "5px", display: "flex" }} >
                                    <span id="registerAllFiles" className="link" onClick={() => this.handleRegisterAllFilesAlert(true)}>
                                        <img src={register} style={{ verticalAlign: "bottom", marginRight: "5px" }} />
                                        Register All Files</span>
                                </div>
                            </React.Fragment>
                        )
                    }
                    {this.getPageSelectionButtons()}
                </ResultsTabChinContentDiv>
            </div>
        );
    }

    getUploadTab() {
        return (
                <React.Fragment>
                <div id="2">
                    <UploadSiteFile {...this.props}
                        filesData={this.state.filesData}
                        onFileUploadSave={this.onFileUploadSave}
                        setBusyLoader={this.setBusyLoader}
                        fileToCheckin={this.state.fileToCheckin}
                        clearScrollTo={this.clearScrollTo}
                        clearFileCheckin={this.clearFileValue}/>
                </div>
            </React.Fragment>
        );
    }

    getNavigateTab() {
        
        // SITEFILES | CLIENT_DIRECTENTRY | SYSTEM_DIRECTENTRY
        const {
            currentDirectory,
            isBusy
        } = this.state;

        return (
            isBusy ? (
                <React.Fragment>
                    <div id="3">
                        <ResultsTabChinContentDiv>
                            <div style={{
                                paddingTop: "2px",
                                width: "250px",
                                paddingLeft: "5px",
                                display: "flex"
                            }}> Loading, Please wait..
                            </div>
                        </ResultsTabChinContentDiv>
                    </div>
                </React.Fragment>
            ) : (
                <div id="3">
                    <ResultsNavContentDiv>
                        <dl>
                            <dt>
                                    <React.Fragment>
                                    <div className="navImg"><img src={minusSymbol}/></div>
                                    <div
                                            className={currentDirectory === 'Site Files' ? 'highlight' : 'noHighlight'}> {sessionStorage.getItem("ClientAlias")}
                                    </div>
                                    </React.Fragment>
                            </dt>
                            <dd>
                                <div style={{marginBottom: -10, marginLeft: -10}}>
                                    <div id="desc-label" className="siteFilesLabel">
                                        <div className="navImg"><img src={minusSymbol}/></div>
                                        <div
                                            className={currentDirectory === 'DE Scripts' ? 'nav-select highlight' : 'nav-select'}
                                            onClick={(x) => {
                                                if (currentDirectory != 'Site Files')
                                                    this.getFiles('SITEFILES')
                                            }}>Site Files
                                        </div>
                                    </div>
                                </div>
                            </dd>
                            <dd>
                                <div style={{marginLeft: 10}}>
                                    <img style={{marginTop: 15, marginLeft: 15}} src={l_middle}/>
                                    <div id="desc-label" className="siteFilesLabel2">
                                        <div className="nav-select" onClick={(x) => {
                                            if (currentDirectory != 'DE Scripts')
                                                this.getFiles('CLIENT_DIRECTENTRY')
                                        }}>Direct Entry Scripts
                                        </div>
                                    </div>
                                </div>
                            </dd>
                            <dt>
                                <div className="navImg"><img src={minusSymbol}/></div>
                                <div
                                    className={currentDirectory === 'Global DE Scripts' ? 'highlight' : 'noHighlight'}> All
                                    Clients
                                </div>
                            </dt>
                            <dd>
                                <div><img src={l_middle}/>
                                    <div id="desc-label" className="siteFilesLabel3">
                                        <div className="nav-select" onClick={(x) => {
                                            if (currentDirectory != 'Global DE Scripts')
                                                this.getFiles('SYSTEM_DIRECTENTRY')
                                        }}>Global Direct Entry Scripts
                                        </div>
                                    </div>
                                </div>
                            </dd>
                        </dl>
                    </ResultsNavContentDiv>
                </div>
            )
        );
    }

    getTabs() {
        var tabJson = [
            {
                domID: 'site-file-use-tab',
                label: 'Use',
                tabContent: this.getUseTab()
            },
            {
                domID: 'site-file-upload-tab',
                label: 'Upload',
                tabContent: this.getUploadTab()
            },
            {
                domID: 'site-file-navigate-tab',
                label: 'Navigate',
                tabContent: this.getNavigateTab()
            }
        ];
        return tabJson;
    }

    public onSelectTab(e: React.MouseEvent<HTMLButtonElement>, selObj: any) {
        this.setState({activeTab : selObj.tabIndex });
    }

    public setBusyLoader = (isbusy: boolean) => {
        
        this.setState({ isBusy: isbusy }, () => {
            if(isbusy){
                this.setState( {
                    pageIndex: 0,
                    searchResults: {
                        "topOwner": "",
                        "parentData": []
                    },
                    paginatedSearchResults: [{
                        childData: [],
                        parentData: [],
                    }],
                    resultsUUID: "" + Date.now()
                })
            }
        });
        
    }

    public onOK(e: React.ChangeEvent<HTMLButtonElement>) {

        this.props.history.push("/LandingPage");
    }

    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.history.push('/LandingPage');
    }

    handleRegisterAllFilesAlert(show: boolean) {
        this.setState({ showRegisterAllFilesAlert: show });
    }

    handleRegisterAllFilesConfirmation(confirm: boolean) {
        this.setState({ showRegisterAllFilesAlert: confirm });
        if (confirm) {
            this.doRegisterAllFiles();
        }        
    }

    public render() {

        var buttons = <DialogButtonOK onClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOK(e)} />

        const {
            isValidationModalOpen,
            validationModalMessage,
            activeTab,
            currentHeaderDir,
            showRegisterAllFilesAlert,
        } = this.state;
        //TODO directory in state
        return (
            <DialogWrapper title={`${this.props.title} >> ${currentHeaderDir}`} helpUrl='/Support/Help/SUP_SiteFileMaint.htm' buttons={buttons}>
                <ContentWrapper>
                    <div style={{ width: 980 }}>

                        <TabContainer
                            key={"Tabs" + activeTab}
                            domID="TabContainerResponseUseFile"
                            initialTab={activeTab}
                            className='tabs'
                            selectedTabIndex={activeTab}
                            onTabChange={(e: React.MouseEvent<HTMLButtonElement>, selObj: any) => this.onSelectTab(e, selObj)}
                            tabs={this.getTabs()}
                            width='100%'
                            height='600px'
                        />
                    </div>
                    <ModalConfirmation
                        title={""}
                        isOpen={isValidationModalOpen}
                        alertMode={true}
                        onModalToggle={() => this.setState({ isValidationModalOpen: false, validationModalMessage: "" })}
                        formattedMessage={validationModalMessage}
                    />
                    <ModalConfirmation
                        isOpen={showRegisterAllFilesAlert}
                        formattedMessage={(
                            <div>
                                <p>Register All Unregistered Files?</p>
                            </div>)
                        }
                        onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.handleRegisterAllFilesAlert(!showRegisterAllFilesAlert)}
                        onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.handleRegisterAllFilesConfirmation(true)}
                        onDeny={(e: React.MouseEvent<HTMLButtonElement>) => this.handleRegisterAllFilesConfirmation(false)}
                    />
                </ContentWrapper>

            </DialogWrapper>
        );
    }
};

var connectedHoc = connect<ISiteFileState, ISiteFileActionProps, IOwnProps, ISiteFileProps, ApplicationState>(
    createApiMapStateToProps('siteFile'),
    createApiMapDispatchToProps(actionCreators),
    mergeApiComponentProps
)(SiteFile);

export default withRouter(connectedHoc);
