import * as React from 'react';
import { ARMGrid } from '../../common/UICLWrappers/ARMGrid';
import { ISortIndicator, SortDataType, SortHelper } from '../../../scripts/util/SortHelper';
import styled from 'styled-components';
import { ARMHeaderCell } from '../../common/UICLWrappers/ARMHeaderCell';
import _ from 'lodash';
import { GridConfig, IRowProps } from './GridConfig';



const numberOfSortableHeaders = 2
let sortableHeaderIds: any[] = []
for (let i = 0; i < numberOfSortableHeaders; i++) {
    sortableHeaderIds = [...sortableHeaderIds, "" + _.uniqueId()]
}


export const GridWrapper = styled.div`

    /* STANDARD OUTER GRID STYLE CONFIGURATION */

    div{
        display:inline;
    }
    background-color:white;
    width: 100%;
    height: 100%;
    min-height: 400px;
    border: black 1px solid;
    overflow-y: scroll;

    #sample-treeview-grid{

        /* STANDARD INNER GRID STYLE CONFIGURATION */

        thead{position:sticky; z-index:20; top:0px}

        background-color:white;
        .empty-row{
            background-color:white !important;
        }
        overflow-y:scroll;
        overflow-x:scroll;

        table tbody tr > td{
            height:32px !important;
        }

        /* ROW HOVER CONFIGURATION */

        tr:hover{
            background-color:#edf9ff;
            cursor:pointer;
        }

        /* OTHER CONFIGURATION */

        .child-header {
            background-color: #0F0F59;
            pointer-events: none;
            th > div {
                white-space: nowrap;
                color: #FFF
                float: left;
                img {
                    float: left;
                }
            }
        }

        .child-row {
            cursor: default;
            img {
                float: left;
            }
        }

        .cell-style {
            height: 32px;
            display: flex;
            align-items: center;
            white-space1: nowrap;
        }

        /* primary grid row headers */

        .FileName {
            width: 355px;
        }

        .Modified{
            width: 245px;
        }

        .Size {
            width: 125px;
        }

        .Owner {
            width: 125px;
        }

        .primary-header-count {
            width: 100px;
        }

        .primary-header-checkedOut {
            width: 100px;
        }

        .child-row-amount {
  
        }

        .CheckedOut {
            width: 114px;
        }

        /* primary grid rows */

        .overflow-filename-style {
            overflow: hidden;
            inline-size: 288px;
            margin-right: -10px;
        }

        .primary-row-file-name {
            width: 155px;
        }
        
        .overflow-import-date-style {
            overflow: hidden;
            inline-size: 95%;
            margin-right: -10px;
        }

        .primary-row-import-date {
            width: 345px;
        }
        
        .overflow-payment-source-style {
            overflow: hidden;
   
            margin-right: -10px;
        }

        .primary-row-payment-source {
            width: 88px;
        }
        
        .overflow-count-style {
            overflow: hidden;
            inline-size: 122px;
            margin-right: -10px;
            margin-left: 50px;
        }

        .primary-row-count {
            width: 100px;
           inline-size: 90px;
        }
        
        .overflow-checkedOut-style {
            overflow: hidden;
            inline-size: 90px;
            margin-right: -10px;
        }

        .primary-row-checkedOut {
            width: 100px;
            inline-size:62px;
        }

        .primary-row-remove {
            width: 114px;
        }

        /* child grid row headers */

        .child-header-payment-date {
            white-space: nowrap;
            width: 170px;
        }

        .child-header-payment-no {
            width: 152px;
        }

        .child-header-provider-no {
            width: 450px;
        }

        .child-header-tax-id {
            width: 90px;
        }

        .child-header-secondary {
            width: 115px;
        }

        .child-header-matched {
            width: 100px;
        }

        .child-header-unmatched {
            width: 115px;
        }

        .child-header-checkedOut {
            width: 100px;
        }

        .child-header-remove {
            width: 50px;
            white-space: nowrap;
        }

        /* child grid rows */
        .child-row-payment-date{
            white-space: nowrap;
            width: 170px;
        }
        
        .overflow-payment-no-style {
            overflow: hidden;
            inline-size: 138px;
            margin-right: -10px;
        }

        .child-row-payment-no {
            width: 120px;
        }
        
        .overflow-provider-no-style {
            overflow: hidden;
           /* inline-size: 100px;*/
            margin-right: -10px;
        }

        .child-row-provider-no {
            width: 464px;
        }
        
        .overflow-tax-id-style {
            overflow: hidden;
            inline-size: 70px;
            margin-right: -10px;
        }

        .child-row-tax-id {
            width: 90px;
        }
        
        .overflow-secondary-style {
            overflow: hidden;
            inline-size: 100px;
            margin-right: -10px;
        }

        .child-row-secondary {
            width: 115px;
        }
        
        .overflow-matched-style {
            overflow: hidden;
            inline-size: 90px;
            margin-right: -10px;
        }

        .child-row-matched {
            width: 100px;
        }
        
        .overflow-unmatched-style {
            overflow: hidden;
            inline-size: 100px;
            margin-right: -10px;
        }

        .child-row-unmatched {
            width: 115px;
        }
        
        .overflow-child-checkedOut-style {
            overflow: hidden;
            inline-size: 90px;
            margin-right: -10px;
        }

        .child-row-checkedOut {
            width: 100px;
        }

        .child-row-remove{
            width: 50px;
            white-space: nowrap;
        }
    }
`;

interface IPayerDetailsGridProps {
    rows: any[];
    onSort?: any;
    onHandleScroll?: any
    onClearScrollTo?: any
}


interface searchResultsObj {
    "tOwner": string;
    "parentData": any[];
};

interface IComponentProps {
};

interface ResultsGridState {
    rows: any[];
    expandClicked: boolean;
    expandedParentIds: string[];
    inputValue: string;
    sortIndicator: ISortIndicator;
    topOwner: string;
}

interface paginatedSearchResult {
    childData: any[];
    parentData: any[];
}


interface IChildRecord {
    RevisionNumber: string,
    Note: string,
    RevisionDate: string,
}

interface IComponentState {
    selectedIndex: number;
    cancelLeave: boolean;
    isBusy: boolean,
    topOwner: string,
    reasonInputError: boolean;
    reasonInputText: string;
    isHoveringOverBack: boolean;
    isHoveringOverFoward: boolean;
    searchResults: searchResultsObj;
    paginatedSearchResults: paginatedSearchResult[];
    pageIndex: number;
    dateUUID: string;
    resultsUUID: string;
    rows: any[];
    expandClicked: boolean;
    expandedParentIds: string[];
    inputValue: string;
    sortIndicator: ISortIndicator;
    validationModalMessage: string;
    isValidationModalOpen: boolean;
    fileToCheckin: string;
    recordToScrollTo: string;
};


export const DEFAULT_RESULTS_GRID_STATE: ResultsGridState = {
    rows: [],
    expandClicked: false,
    topOwner: "",
    expandedParentIds: [],
    inputValue: '',
    sortIndicator: {
        sortColumn: 'cpId',
        sortDirection: 'up',
        sortDataType: SortDataType.Number
    },
}

export const DEFAULT_STATE: IComponentState = {
    selectedIndex: 0,
    cancelLeave: false,
    isBusy: false,
    topOwner: "",
    reasonInputError: false,
    reasonInputText: "",
    isHoveringOverBack: false,
    isHoveringOverFoward: false,
    validationModalMessage: "",
    isValidationModalOpen: false,
    searchResults: {
        "tOwner": "",
        "parentData": []
    },
    paginatedSearchResults: [{
        childData: [],
        parentData: [],
    }],
    pageIndex: 0,
    dateUUID: _.uniqueId(),
    resultsUUID: "" + Date.now(),
    rows: [],
    expandClicked: false,
    expandedParentIds: [],
    inputValue: '',
    sortIndicator: {
        sortColumn: 'cpId',
        sortDirection: 'up',
        sortDataType: SortDataType.Number
    },
    fileToCheckin: "",
    recordToScrollTo: ""
};

export class PayerDetailsGrid extends React.Component<IPayerDetailsGridProps, IComponentState> {
    cheackboxRefs: { [index: string]: any };
    sortableHeaderRefs: { [index: string]: any };
    rowRefs: { [index: string]: any };
    scrollPosition: any;

    constructor(props: IPayerDetailsGridProps) {
        super(props);
        this.state = {
            ...DEFAULT_STATE,
            rows: props.rows
        };
        this.handleSorting = this.handleSorting.bind(this);
        this.cheackboxRefs = [];
        this.sortableHeaderRefs = [];
        this.rowRefs = [];

    }

    handleSorting(sortIndicator: ISortIndicator, refKey: string = "") {
        if (sortIndicator.sortColumn == this.state.sortIndicator.sortColumn) {
            this.setState({ sortIndicator: { ...this.state.sortIndicator, sortDirection: this.state.sortIndicator.sortDirection=='uo' ? 'down': 'up' } })
        }
        this.setState({ sortIndicator: sortIndicator })

        const sortedData = SortHelper.Sort(
            this.props.rows,
            sortIndicator
        );
        this.setState({ rows: sortedData });
       
    }



    getCustomRow() {
        const row = ({ record }: IRowProps) => {
            return (

                <>
                    <tr id={record.cpId.toString()} ref={el => this.rowRefs[record.cpId.toString()] = el} key={record.cpId} role="button" >
                        {

                            <>
                                <td className="primary-row-file-name">
                                    <div className="cell-component-wrapper cell-style">
                                        <div className="no-margin cell-style overflow-import-date-style">
                                            {record.cpId}
                                        </div>
                                    </div>
                                </td>
                                <td className="primary-row-import-date">
                                    <div className="cell-component-wrapper cell-style">
                                        <div className="no-margin cell-style overflow-import-date-style">
                                            {record.payerName}
                                        </div>
                                    </div>
                                </td>

                            </>
                        }
                    </tr>
                </>
            );
        }
        return row;
    }


    getCustomHeader() {
        const { sortIndicator } = this.state;

        const header = () => <thead><tr>
            {
                <>
                    <ARMHeaderCell
                        id={sortableHeaderIds[0]}
                        key={sortableHeaderIds[0]}
                        setRef={(input: any) => this.sortableHeaderRefs[sortableHeaderIds[0]] = input}
                        refKey={sortableHeaderIds[0]}
                        style={{ whiteSpace: "nowrap", width: "400px" }}
                        dataName="cpId"
                        text='CPID'
                        cellType="text"
                        sortable={true}
                        sortDataType={SortDataType.Number}
                        sortHandler={this.handleSorting}
                        sortIndicator={sortIndicator}
                        hasCorrectedTabIndex={true}
                    />
                    <ARMHeaderCell
                        id={sortableHeaderIds[1]}
                        key={sortableHeaderIds[1]}
                        setRef={(input: any) => this.sortableHeaderRefs[sortableHeaderIds[1]] = input}
                        refKey={sortableHeaderIds[1]}
                        dataName="payerName"
                        text="Payer Name"
                        cellType="text"
                        sortable={true}
                        sortDataType={SortDataType.String}
                        sortHandler={this.handleSorting}
                        sortIndicator={sortIndicator}
                        hasCorrectedTabIndex={true}
                    />
                </>
            }
        </tr></thead>
        return header;
    }


    render() {

        const columns = GridConfig.getColumnsConfig();
        const row = this.getCustomRow();
        const header = this.getCustomHeader();      


        return (
            <GridWrapper>
                    <ARMGrid
                        domID='sample-treeview-grid'
                        maxHeight={'350px'}
                        isFixedHeader={true}
                        columns={columns}
                        headerComponent={header}
                        rowComponent={row}
                        records={this.props.rows}
                        emptyGridMessage=' '
                        selectionKey={'cpId'} />
            </GridWrapper>
        )
    }
}
