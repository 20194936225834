import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { getRawToken } from "../../../scripts/session/SecurityToken";

import { IMergeCrudComponentProps } from '../../../scripts/util/CrudComponentHelpers';

import { URLs } from "../../../commonResources/constants";
import { ApplicationState } from '../../../store/index';
import { IMergeComponentProps } from '../../../scripts/util/ReduxHelpers';
import ApiClient from '@commonResources/ApiClient';

/////////////////////////////////////////////////////////////////////////////////////////////

enum actionRequestName { // 0 = lookupdcn, 1 = rollup adj, 2 = delete adj
    'Look Up DCN' = 0,
    'Roll Up SL Adjustments' = 1,
    'Delete Adjustment Data' = 2
}
interface ILookUpDcnProps {
    canView: boolean,
    isCloud: boolean,
    cloudPage: number       /* 0=initiate, 1=AdjRollup, 2=AdjDelete */
}

interface ILookupDcnState {
}

export const DEFAULT_STATE: ILookupDcnState = {

};

type IOwnProps = ILookUpDcnProps & RouteComponentProps<{}>;
type IILaunchSendReceiveMailProps = IMergeComponentProps<ILookupDcnState, ILookUpDcnProps, IOwnProps>;

/////////////////////////////////////////////////////////////////////////////////////////////

export class LookUpDcn extends React.Component<IILaunchSendReceiveMailProps, ILookupDcnState> {

    static defaultProps: ILookUpDcnProps = {
        canView: false,
        isCloud: false,
        cloudPage: 0
    };

    constructor(props: IILaunchSendReceiveMailProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    public componentDidMount() {
        if (!this.props.canView) {
            this.props.history.push('/LandingPage');
        }

        document.body.style.cursor = "wait";
        if (this.props.isCloud == true) {
            this.InitiateDigitalBiller();
        } else {
            this.SendStartDigitalBillerRequest();
        }
    }

    public componentWillUnmount() {
        document.body.style.cursor = "default";
    }

    public SendStartDigitalBillerRequest() {
        console.log('URLs.api: [' + URLs.api + ']');
        fetch(URLs.api + '/api/data/digitalBiller/start',
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`
                },
            }
        ).then(response => {
            var homeMessage = '';
            var messageType = 'SystemWarningNotification';
            if (response.status === 200) {
                homeMessage = 'The Look Up DCN request has been started!';
                console.log(homeMessage);
            } else {
                const errorDetail = response.status === 401 ? 'This client or user is not authorized for this silo.' : 'An unexpected error has occurred.';
                homeMessage = `The Look Up DCN request failed! ${errorDetail}. If this continues, please contact support.`;
                messageType = 'SystemErrorNotification';
                console.log(homeMessage, response.json);
            }
            sessionStorage.setItem(messageType, homeMessage);
            this.props.history.push('/ui/LandingPage');
        }).catch(error => {
            sessionStorage.setItem('SystemErrorNotification', 'The Look Up DCN request failed! If this continues, please contact support.');
        }).finally(() => {
            this.props.history.push('/ui/LandingPage');
        });
    }

    public InitiateDigitalBiller() {
        const endpoint = `${ApiClient.apiBaseURL}/data/digitalBiller/startAws`;
        const payload = JSON.stringify({ 'type': this.props.cloudPage });
        let successMsg = 'The ' + actionRequestName[this.props.cloudPage] + ' request has been started.';
        let loadConfigFailureMsg = 'Error while initiating ' + actionRequestName[this.props.cloudPage] + ' for Digital Biller due to a missing parameter configuration.';
        let basicFailureMsg = 'Error while initiating ' + actionRequestName[this.props.cloudPage] + ' for Digital Biller.';
        //console.log('successMsg = ' + successMsg);
        //console.log('loadConfigFailureMsg = ' + loadConfigFailureMsg);
        //console.log('basicFailureMsg = ' + basicFailureMsg);

        let requestResponse: any;
        return ApiClient.jsonPostAsync(endpoint, payload)
            .then(async (response: any) => {
                let responseMessage = '';
                let messageType = 'SystemWarningNotification';
                if (response.status === 200 || response.status === 202) {
                    responseMessage = successMsg;
                } else if (response.status === 400) {
                    responseMessage = loadConfigFailureMsg;
                    messageType = 'SystemErrorNotification';
                } else {
                    responseMessage = basicFailureMsg;
                    messageType = 'SystemErrorNotification';
                }
                //alert(responseMessage);
                console.log(responseMessage);
                //console.log(homeMessage, response.json);
                sessionStorage.setItem(messageType, responseMessage);
            })
            .catch(error => {
                sessionStorage.setItem('SystemErrorNotification', basicFailureMsg = ' :  If this continues, please contact support.');
            })
            .finally(() => {
                this.props.history.push('/ui/LandingPage');
            });
    }

    public render() {
        return null;
    }
};

var connectedHoc = connect<ILookupDcnState, IOwnProps, IILaunchSendReceiveMailProps, ApplicationState>(
    null
)(LookUpDcn);


export default withRouter(connectedHoc);