import { Reducer } from 'redux';
import { createDataAction, ActionTypes, createApiAction, createApiBodyAction } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers"
import { IsFieldFilled, IsAlphaNumeric, IsUsernameValid, IsPasswordValid, ValidatePassword } from '@commonResources/validations';
import { URLs } from '@commonDevResources/constants';
import { dataStore } from '../../__tests_setup__/apiData/Crud/ClaimFilterData';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IPcgPayerMaintenanceUIState {
    isBusy: boolean;
    curd: any,
    ui: any,
    dataStore:any

}
export const actionCreators : any = {

}
export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;

const defaultState: IPcgPayerMaintenanceUIState = {
    isBusy: false,
    curd: {},
    ui: {},
    dataStore: {
        confirm: {
        }
    }
};

export const reducer: Reducer<IPcgPayerMaintenanceUIState, KnownActions> = (state: IPcgPayerMaintenanceUIState | undefined, action: KnownActions) => {

return defaultState;
   
}
