import * as WeatherForecasts from "./WeatherForecasts";
import * as Counter from "./Counter";
//import * as Modal from './Modal';
import * as AutoList from "./AutoList";
import * as ADRCoverLetter from "./ADRCoverLetter";
import * as BridgeMaintenance from "./BridgeMaintenance";
import * as ClaimNoteType from "./ClaimNoteType";
import * as ClaimTrackingType from "./ClaimTrackingType";
import * as DashboardContacts from "./DashboardContacts";
import * as DashboardMaintenance from "./DashboardMaintenance";
import * as DirectEntryAccount from "./DirectEntryAccount";
import * as UserMaintenance from "./UserMaintenance";
import * as PayerAddressType from "./PayerAddress";
import * as FileType from "./FileType";
import * as FieldLevelSecurity from "./FieldLevelSecurity";
import * as HoldCode from "./HoldCode";
import * as FormsUsed from "./FormsUsed";
import * as GroupMaster from "./GroupMaster";
import * as EligibilityProfile from "./EligibilityProfile";
import * as FacilityMaster from "./FacilityMaster";
import * as ClaimFilter from "./ClaimFilter";
import * as WorkingGroupDisplay from "./WorkingGroupDisplay";
import * as SampleApiDataGrid from "./SampleApiDataGrid";
import * as SearchClaim from "./SearchClaim";
import * as FavoriteFilter from "./FavoriteFilter";
import * as UserPreferences from "./UserPreferences";
import * as AllowedIpMaintenance from "./AllowedIpMaintenance";
import * as ChangePassword from "./ChangePassword";
import * as ViewResponses from "./ViewResponses";
import * as AcceptRemits from "./AcceptRemits";
import * as DeleteFilter from "./DeleteFilter";
import * as ManualRun from "./ManualRun";
import * as SecondaryBilling from "./SecondaryBilling";
import * as PayerAddress from "./PayerAddress";
import * as AEPhysicianMaintenance from "./AEPhysicianMaintenance";
import * as AutoSubmitMaintenance from "./AutoSubmitMaintenance";
import * as ReportList from "./ReportList";
import * as ReportOptions from "@store/ReportOptions";
import * as RemitImportOptions from "./RemitImportOptions";
import * as PhysicianMaintenance from "./PhysicianMaintenance";
import * as PhysicianMnLetter from "@store/PhysicianMnLetter";
import * as AutoModule from "./AutoModule";
import * as AddNote from "./AddNote";
import * as PaperClaimList from "./PaperClaimList";
import * as JobQueue from "./JobQueue";
import * as ReportCreate from "./ReportCreate";
import * as PayerAlias from "./PayerAlias";
import * as SelectReasonCode from "./SelectReasonCode";
import * as RemitFilter from "./RemitFilter";
import * as SweepTime from "./SweepTime";
import * as UseFile from "./UseFile";
import * as RemitWorkingGroupDisplay from "./RemitWorkingGroupDisplay";
import * as UserPasswordHints from "./UserPasswordHints";
import * as PaperClaimsPrintingMaintenance from "./PaperClaimsPrintingMaintenance";
import * as SupplementalEditsMaintenance from "./SupplementalEditMaintenance";
import * as ManageDeparment from "./ManageDepartmentAssignments";
import * as ManageClaimAssignmentsPayerStatusRules from "./ManageClaimAssignmentsPayerStatusRules";
import * as BatchClaimsPrintingOptions from "@store/BatchClaimsPrintingOptions";
import * as ManageHelpNotesForEdits from "./ManageHelpNotesForEdits";
import * as ManualEligibilityCheck from "./ManualEligibilityCheck";
import * as ViewManualEligibility from "./ViewManualEligibility";
import * as DashboardDefault from "./OperationalDashboard/DashboardDefault";
import * as DrillDownView from "./OperationalDashboard/DrillDownView";
import * as DashboardAssignedClaims from "./OperationalDashboard/DashboardAssignedClaims";
import * as DashboardClaim from "./OperationalDashboard/DashboardClaim";
import * as DashboardEligibility from "./OperationalDashboard/DashboardEligibility";
import * as PreBillFilter from "./PreBillFilter";
import * as ReportFilter from "./ReportFilter";
import * as SecurityAccountPolicy from "./SecurityAccountPolicy";
import * as RestrictAccess from "./RestrictAccess";
import * as HoldCodeProcessing from "./HoldCodeProcessing";
import * as SpinOff from "./SpinOff";
import * as ClaimOptions from "./ClaimOptions";
import * as BulkImportExport from "./BulkImportExport";
import * as RemoveRemits from "./RemoveRemits";
import * as ViewProductivity from "./ViewProductivity";
import * as DeleteRemitFilter from "./DeleteRemitFilter";
import * as ClaimEventNotification from "./ClaimEventNotification";
import * as SubmitClaimUpdateFile from "./SubmitClaimUpdateFile";
import * as SubmitReasonCodes from "./SubmitReasonCodes";
import * as SubmitParallonFacilities from "./SubmitParallonFacilities";
import * as ManageCrossovers from "./ManageCrossoversStore";
import * as FileUpload from "./FileUpload";
import * as SubmitDESFiles from "./SubmitDESFiles";
import * as SiteTemplateTransfer from "./SiteTemplateTransfer";
import * as DenialManagement from "./DenialManagement";
import * as ManageClaimAssignmentsSimpleErrorRules from "./ManageClaimAssignmentsSimpleErrorRules";
import * as Authentication from "./Authentication";
import * as ClaimTotals from "./ClaimTotals";
import * as SubmitRemits from "./SubmitRemits";
import * as RSRTFilter from "./RSRTFilter";
import * as ManageClaimAssignmentsErrorRules from "./ManageClaimAssignmentsErrorRules";
import * as ViewBridgeLog from "./ViewBridgeLog";
import * as BackfeedConfig from "./BackfeedConfig";
import * as SelectClient from "./SelectClient";
import * as ClaimInquiryForm from "./ClaimInquiryForm";
import * as InternalUser from "./InternalUser";
import * as ReapplySiteTemplate from "./ReapplySiteTemplate";
import * as FilterableListDemo from "./FilterableListDemo";
import * as SubmitClaims from "./SubmitClaims";
import * as SiteFile from "./SiteFile";
import * as RunBridgeRoutines from "./RunBridgeRoutines";
import * as PaperCommands from "./PaperCommands";
import * as UserEmailConfirm from "./UserEmailConfirm";
import PcgPayerMaintenance from "../components/SupportTools/PCGPayerMaintenance/PcgPayerMaintenance";

export const actionCreators = {
    dashboardDefault: DashboardDefault.actionCreators,
    drillDownView: DrillDownView.actionCreators,
    dashboardClaim: DashboardClaim.actionCreators,
    dashboardEligibility: DashboardEligibility.actionCreators,
    dashboardAssignedClaims: DashboardAssignedClaims.actionCreators,
//    authentication: Authentication.actionCreators,
    counter: Counter.actionCreators,
    weatherForecasts: WeatherForecasts.actionCreators,
    //modal: Modal.actionCreators,
    autoList: AutoList.actionCreators,
    adrCoverLetter: ADRCoverLetter.actionCreators,
    bridgeMaintenance: BridgeMaintenance.actionCreators,
    manualRun: ManualRun.actionCreators,
    claimNoteType: ClaimNoteType.actionCreators,
    claimTrackingType: ClaimTrackingType.actionCreators,
    dashboardContacts: DashboardContacts.actionCreators,
    dashboardMaintenance: DashboardMaintenance.actionCreators,
    directEntryAccount: DirectEntryAccount.actionCreators,
    holdCode: HoldCode.actionCreators,
    formsUsed: FormsUsed.actionCreators,
    groupMaster: GroupMaster.actionCreators,
    eligibilityProfile: EligibilityProfile.actionCreators,
    facilityMaster: FacilityMaster.actionCreators,
    userMaintenance: UserMaintenance.actionCreators,
    payerAddress: PayerAddressType.actionCreators,
    fileType: FileType.actionCreators,
    fieldLevelSecurity: FieldLevelSecurity.actionCreators,
    sampleApiDataGrid: SampleApiDataGrid.actionCreators,
    searchClaim: SearchClaim.actionCreators,
    favoriteFilter: FavoriteFilter.actionCreators,
    userPreferences: UserPreferences.actionCreators,
    allowedIpMaintenance: AllowedIpMaintenance.actionCreators,
    changePassword: ChangePassword.actionCreators,
    viewResponses: ViewResponses.actionCreators,
    PaperClaimsPrintingMaintenance: PaperClaimsPrintingMaintenance.actionCreators,
    ViewProductivity: ViewProductivity.actionCreators,

    claimFilter: ClaimFilter.actionCreators,
    workingGroupDisplay: WorkingGroupDisplay.actionCreators,
    addNote: AddNote.actionCreators,
    submitClaims: SubmitClaims.actionCreators,
    selectReasonCode: SelectReasonCode.actionCreators,

    useFile: UseFile.actionCreators,
    remitFilter: RemitFilter.actionCreators,
    remitWorkingGroupDisplay: RemitWorkingGroupDisplay.actionCreators,
    reportFilter: ReportFilter.actionCreators,
    acceptRemits: AcceptRemits.actionCreators,
    deleteFilter: DeleteFilter.actionCreators,
    secondaryBilling: SecondaryBilling.actionCreators,
    reportList: ReportList.actionCreators,
    aePhysicianMaintenance: AEPhysicianMaintenance.actionCreators,
    autoSubmitMaintenance: AutoSubmitMaintenance.actionCreators,
    remitImportOptions: RemitImportOptions.actionCreators,
    physicianMaintenance: PhysicianMaintenance.actionCreators,
    autoModule: AutoModule.actionCreators,
    paperClaimList: PaperClaimList.actionCreators,
    jobQueue: JobQueue.actionCreators,
    reportCreate: ReportCreate.actionCreators,
    payerAlias: PayerAlias.actionCreators,
    manageHelpNotesForEdits: ManageHelpNotesForEdits.actionCreators,
    sweepTime: SweepTime.actionCreators,
    reportOptions: ReportOptions.actionCreators,
    physicianMnLetter: PhysicianMnLetter.actionCreators,
    supplementalEditMaintenance: SupplementalEditsMaintenance.actionCreators,
    manageDepartment: ManageDeparment.actionCreators,
  manageClaimAssignmentsPayerStatusRules:
    ManageClaimAssignmentsPayerStatusRules.actionCreators,
    securityAccountPolicy: SecurityAccountPolicy.actionCreators,
    claimOptions: ClaimOptions.actionCreators,
    claimEventNotification: ClaimEventNotification.actionCreators,

    batchClaimsPrintingOptions: BatchClaimsPrintingOptions.actionCreators,
    userPasswordHints: UserPasswordHints.actionCreators,
    preBillFilter: PreBillFilter.actionCreators,
    holdCodeProcessing: HoldCodeProcessing.actionCreators,
    restrictAccess: RestrictAccess.actionCreators,
    spinoff: SpinOff.actionCreators,
    bulkimportexport: BulkImportExport.actionCreators,
    manualEligibilityCheck: ManualEligibilityCheck.actionCreators,
    viewManualEligibility: ViewManualEligibility.actionCreators,
    removeRemits: RemoveRemits.actionCreators,
    deleteRemitFilter: DeleteRemitFilter.actionCreators,
    submitClaimUpdateFile: SubmitClaimUpdateFile.actionCreators,
    submitReasonCodes: SubmitReasonCodes.actionCreators,
    submitParallonFacilities: SubmitParallonFacilities.actionCreators,
    manageCrossovers: ManageCrossovers.actionCreators,
    fileUpload: FileUpload.actionCreators,
    submitDESFiles: SubmitDESFiles.actionCreators,
    siteTemplateTransfer: SiteTemplateTransfer.actionCreators,
    denialManagement: DenialManagement.actionCreators,
  manageClaimAssignmentsSimpleErrorRules:
    ManageClaimAssignmentsSimpleErrorRules.actionCreators,
    claimTotals: ClaimTotals.actionCreators,
    rsrtFilter: RSRTFilter.actionCreators,
    submitRemits: SubmitRemits.actionCreators,
    selectClient: SelectClient.actionCreators,
    claimInquiryForm: ClaimInquiryForm.actionCreators,
    internalUser: InternalUser.actionCreators,
    reapplySiteTemplate: ReapplySiteTemplate.actionCreators,
    FilterableListDemo: FilterableListDemo.actionCreators,
    siteFile: SiteFile.actionCreators,
    runBridgeRoutines: RunBridgeRoutines.actionCreators,
    paperCommands: PaperCommands.actionCreators,
    userEmailConfirm: UserEmailConfirm.actionCreators
};

export type IActionCreators = typeof actionCreators;
export type IActionCreatorKeys = keyof IActionCreators;
export type IActionCreatorTypes = IActionCreators[IActionCreatorKeys];

export interface CommonState {
    //modal: Modal.ModalState;
}

export interface ComponentState {
    dashboardDefault: DashboardDefault.IDashboardDefaultState;
    drillDownView: DrillDownView.IDrillDownViewDefaultState;
    dashboardClaim: DashboardClaim.IDashboardClaimState;
    dashboardEligibility: DashboardEligibility.IDashboardEligibilityState;
    dashboardAssignedClaims: DashboardAssignedClaims.IDashboardAssignedClaimsState;
}

export interface SampleState {
    counter: Counter.CounterState;
    weatherForecasts: WeatherForecasts.WeatherForecastsState;
    authentication: Authentication.AuthenticationState;
  filterableListDemo: FilterableListDemo.FilterableListDemoState;
}

export interface CrudState {
    autoList: AutoList.IAutoListState;
    adrCoverLetter: ADRCoverLetter.IADRCoverLetterUIState;
    bridgeMaintenance: BridgeMaintenance.IBridgeMaintenanceState;
    claimNoteType: ClaimNoteType.IClaimNoteTypeState;
    claimTrackingType: ClaimTrackingType.IClaimTrackTypeState;
    dashboardContacts: DashboardContacts.IDashboardContactsState;
    dashboardMaintenance: DashboardMaintenance.IDashboardMaintenanceState;
    userMaintenance: UserMaintenance.IUserMaintenanceState;
    payerAddress: PayerAddressType.IPayerAddressState;
    fieldLevelSecurity: FieldLevelSecurity.IFieldLevelSecurityState;
    fileType: FileType.IFileTypeState;
    holdCode: HoldCode.IHoldCodeState;
    formsUsed: FormsUsed.IFormsUsedState;
    groupMaster: GroupMaster.IGroupMasterState;
    eligibilityProfile: EligibilityProfile.IEligibilityProfileState;
    facilityMaster: FacilityMaster.IFacilityMasterState;
    searchClaim: SearchClaim.ISearchClaimState;
    directEntryAccount: DirectEntryAccount.IDirectEntryAccountState;
    favoriteFilter: FavoriteFilter.IFavoriteFilterState;
    userPreferences: UserPreferences.IUserPreferencesState;
    allowedIpMaintenance: AllowedIpMaintenance.IAllowedIpMaintenanceState;
    changePassword: ChangePassword.IChangePasswordState;
    viewResponses: ViewResponses.IViewResponsesState;
    deleteFilter: DeleteFilter.IDeleteFilterState;
    acceptRemits: AcceptRemits.IAcceptRemitsState;
    manualRun: ManualRun.IManualRunState;
    autoModule: AutoModule.IAutoModuleState;
    PaperClaimsPrintingMaintenance: PaperClaimsPrintingMaintenance.IPaperClaimsPrintingMaintenanceState;
    manageDepartment: ManageDeparment.IErrorRuleDepartmentState;
    manageClaimAssignmentsPayerStatusRules: ManageClaimAssignmentsPayerStatusRules.IPayerStatusRuleState;
    secondaryBilling: SecondaryBilling.ISecondaryBillingState;
    reportList: ReportList.IReportListState;
    reportFilter: ReportFilter.IReportFilterUIState;
    ViewProductivity: ViewProductivity.IViewProductivityState;
    jobQueue: JobQueue.IJobQueueState;
    aePhysicianMaintenance: AEPhysicianMaintenance.IAEPhysicianMaintenanceState;
    autoSubmitMaintenance: AutoSubmitMaintenance.IAutoSubmitMaintenanceState;
    remitImportOptions: RemitImportOptions.IRemitImportOptionsState;
    physicianMaintenance: PhysicianMaintenance.IPhysicianMaintenanceState;
    paperClaimList: PaperClaimList.IPaperClaimListState; //not really a CRUD page
    payerAlias: PayerAlias.IPayerAliasState;
    sweepTime: SweepTime.ISweepTimeState;    
    reportOptions: ReportOptions.IReportOptionsUIState;
    physicianMnLetter: PhysicianMnLetter.IPhysicianMnLetterUIState;
    supplementalEditMaintenance: SupplementalEditsMaintenance.ISupplementalEditMaintenanceState;
    batchClaimsPrintingOptions: BatchClaimsPrintingOptions.IBatchClaimsPrintingOptionsUIState;
    securityAccountPolicy: SecurityAccountPolicy.ISecurityAccountPolicyUIState;
    claimOptions: ClaimOptions.IClaimOptionsUIState;
    claimEventNotification: ClaimEventNotification.IClaimEventNotificationState;
    userPasswordHints: UserPasswordHints.UserPasswordHintsState;
    manageHelpNotesForEdits: ManageHelpNotesForEdits.IAEIState;
    preBillFilter: PreBillFilter.IPreBillFilterState;
    restrictAccess: RestrictAccess.IRestrictAccessState;
    holdCodeProcessing:HoldCodeProcessing.IHoldCodeProcessingState;
    removeRemits: RemoveRemits.IRemoveRemitsState;
    deleteRemitFilter: DeleteRemitFilter.IDeleteFilterState;
    submitClaimUpdateFile: SubmitClaimUpdateFile.ISubmitClaimUpdateFileState;
    fileUpload: FileUpload.IFileUploadState;
  submitDESFiles: SubmitDESFiles.ISubmitDESFilesUploadState;
    //denialManagement: DenialManagement.IDenialManagementState;
    manageCrossovers: ManageCrossovers.IManageCrossoversState;
    manageClaimAssignmentsSimpleErrorRules: ManageClaimAssignmentsSimpleErrorRules.IMCASimpleErrorRuleState;
    manageClaimAssignmentsErrorRules: ManageClaimAssignmentsErrorRules.IManageClaimAssignmentsErrorRulesUIState;
    claimTotals: ClaimTotals.IClaimTotalsState;
    submitRemits: SubmitRemits.SubmitRemitsUiState;
    viewbridgelog: ViewBridgeLog.IReportListState;
    backfeedConfig: backfeedConfig.IBackfeedConfigUIState;
    claimInquiryForm: ClaimInquiryForm.IClaimInquiryFormState;
    internalUser: InternalUser.IInternalUserUIState;
    runBridgeRoutines: RunBridgeRoutines.IRunBridgeRoutinesState;
    paperCommands: PaperCommands.IPaperCommandsState;
    pcgPayerMaintenance: UserMaintenance.IUserMaintenanceState;
}

export type CrudStateKeys = keyof CrudState;
export type CrudStates = CrudState[CrudStateKeys];

export interface ApiGridState {}
export type ApiGridStateKeys = keyof ApiGridState;

export interface ApiDataState {
    sampleApiDataGrid: SampleApiDataGrid.ISampleApiDataGridState;
    claimFilter: ClaimFilter.IClaimFilterState;
    workingGroupDisplay: WorkingGroupDisplay.IWorkingGroupDisplayState;
    remitWorkingGroupDisplay: RemitWorkingGroupDisplay.IRemitWorkingGroupDisplayState;
    addNote: AddNote.IAddNoteState;
    submitClaims: SubmitClaims.ISubmitClaimsState;
    reportCreate: ReportCreate.IReportCreateState;
    useFile: UseFile.IUseFileState;
    selectReasonCode: SelectReasonCode.ISelectReasonCodeState;
    remitFilter: RemitFilter.IRemitFilterState;
    spinoff: SpinOff.ISpinOffState;
    bulkimportexport: BulkImportExport.IBulkImportExportState;
    manualEligibilityCheck: ManualEligibilityCheck.IManualEligibilityCheckState;
    viewManualEligibility: ViewManualEligibility.IViewManualEligibilityState;
    submitReasonCodes: SubmitReasonCodes.ISubmitReasonCodesState;
    //bridgeLog: BridgeLog.IBridgeLogState;
    rsrtFilter: RSRTFilter.IRSRTFilterState;

  siteTemplateTransfer: SiteTemplateTransfer.ISiteTemplateTransferState;
    submitParallonFacilities: SubmitParallonFacilities.ISubmitParallonFacilitiesState;
    selectClient: SelectClient.ISelectClientState;
    reapplySiteTemplate: ReapplySiteTemplate.IReapplySiteTemplateState;
    siteFile: SiteFile.ISiteFileState;
    userEmailConfirm: UserEmailConfirm.IUserEmailConfirmState;
}
export type ApiDataStateKeys = keyof ApiDataState;
export type ApiDataStates = ApiDataState[ApiDataStateKeys];

// The top-level state object
export type ApplicationState = CommonState &
  ComponentState &
  SampleState &
  CrudState &
  ApiDataState &
  ApiGridState;
export type ApplicationStateKeys = keyof ApplicationState;
export type KnownStates = ApplicationState[ApplicationStateKeys];

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    authentication: Authentication.reducer,
    counter: Counter.reducer,
    weatherForecasts: WeatherForecasts.reducer,
    //modal: Modal.reducer,
    autoList: AutoList.reducer,
    adrCoverLetter: ADRCoverLetter.reducer,
    bridgeMaintenance: BridgeMaintenance.reducer,
    manualRun: ManualRun.reducer,
    claimNoteType: ClaimNoteType.reducer,
    claimTrackingType:ClaimTrackingType.reducer,
    dashboardContacts: DashboardContacts.reducer,
    dashboardMaintenance: DashboardMaintenance.reducer,
    directEntryAccount: DirectEntryAccount.reducer,
    holdCode: HoldCode.reducer,
    formsUsed: FormsUsed.reducer,
    groupMaster: GroupMaster.reducer,
    eligibilityProfile: EligibilityProfile.reducer,
    facilityMaster: FacilityMaster.reducer,
    userMaintenance: UserMaintenance.reducer,
    payerAddress: PayerAddressType.reducer,
    fileType: FileType.reducer,
    fieldLevelSecurity: FieldLevelSecurity.reducer,
    sampleApiDataGrid: SampleApiDataGrid.reducer,
    searchClaim: SearchClaim.reducer,
    favoriteFilter: FavoriteFilter.reducer,
    userPreferences: UserPreferences.reducer,
    allowedIpMaintenance: AllowedIpMaintenance.reducer,
    changePassword: ChangePassword.reducer,
    viewResponses: ViewResponses.reducer,
    PaperClaimsPrintingMaintenance: PaperClaimsPrintingMaintenance.reducer,
    ViewProductivity: ViewProductivity.reducer, 

    claimFilter: ClaimFilter.reducer,
    workingGroupDisplay: WorkingGroupDisplay.reducer,
    addNote: AddNote.reducer,
    submitClaims: SubmitClaims.reducer,
    selectReasonCode: SelectReasonCode.reducer,

    useFile: UseFile.reducer,
    dashboardDefault: DashboardDefault.reducer,
    drillDownView: DrillDownView.reducer,
    dashboardAssignedClaims: DashboardAssignedClaims.reducer,
    dashboardEligibility: DashboardEligibility.reducer,
    dashboardClaim: DashboardClaim.reducer,

    remitFilter: RemitFilter.reducer,
    remitWorkingGroupDisplay: RemitWorkingGroupDisplay.reducer,
    reportFilter: ReportFilter.reducer,
    acceptRemits: AcceptRemits.reducer,
    deleteFilter: DeleteFilter.reducer,
    secondaryBilling: SecondaryBilling.reducer,
    reportList: ReportList.reducer,
    aePhysicianMaintenance: AEPhysicianMaintenance.reducer,
    autoSubmitMaintenance: AutoSubmitMaintenance.reducer,
    remitImportOptions: RemitImportOptions.reducer,
    physicianMaintenance: PhysicianMaintenance.reducer,
    autoModule: AutoModule.reducer,
    paperClaimList: PaperClaimList.reducer,
    jobQueue: JobQueue.reducer,
    reportCreate: ReportCreate.reducer,
    payerAlias: PayerAlias.reducer,
    manageHelpNotesForEdits: ManageHelpNotesForEdits.reducer,
    sweepTime: SweepTime.reducer,
    reportOptions: ReportOptions.reducer,
    physicianMnLetter: PhysicianMnLetter.reducer,
    supplementalEditMaintenance: SupplementalEditsMaintenance.reducer,
    manageDepartment: ManageDeparment.reducer,
  manageClaimAssignmentsPayerStatusRules:
    ManageClaimAssignmentsPayerStatusRules.reducer,
    securityAccountPolicy: SecurityAccountPolicy.reducer,
    claimOptions: ClaimOptions.reducer,
    claimEventNotification: ClaimEventNotification.reducer,

    batchClaimsPrintingOptions: BatchClaimsPrintingOptions.reducer,
    userPasswordHints: UserPasswordHints.reducer,
    preBillFilter: PreBillFilter.reducer,
    holdCodeProcessing:HoldCodeProcessing.reducer,
    restrictAccess: RestrictAccess.reducer,
    spinoff: SpinOff.reducer,
    bulkimportexport: BulkImportExport.reducer,
    manualEligibilityCheck: ManualEligibilityCheck.reducer,
    viewManualEligibility: ViewManualEligibility.reducer,
    removeRemits: RemoveRemits.reducer,
    deleteRemitFilter: DeleteRemitFilter.reducer,
    submitClaimUpdateFile: SubmitClaimUpdateFile.reducer,
    submitReasonCodes: SubmitReasonCodes.reducer,
    submitParallonFacilities: SubmitParallonFacilities.reducer,
    manageCrossovers: ManageCrossovers.reducer,
    fileUpload: FileUpload.reducer,
    submitDESFiles: SubmitDESFiles.reducer,
    siteTemplateTransfer: SiteTemplateTransfer.reducer,
    denialManagement: DenialManagement.reducer,
  manageClaimAssignmentsSimpleErrorRules:
    ManageClaimAssignmentsSimpleErrorRules.reducer,
    manageClaimAssignmentsErrorRules: ManageClaimAssignmentsErrorRules.reducer,
	claimTotals: ClaimTotals.reducer,
    submitRemits: SubmitRemits.reducer,
    rsrtFilter: RSRTFilter.reducer,
    viewbridgelog: ViewBridgeLog.reducer,
    backfeedConfig: BackfeedConfig.reducer,
    selectClient: SelectClient.reducer,
    claimInquiryForm: ClaimInquiryForm.reducer,
    internalUser: InternalUser.reducer,
    reapplySiteTemplate: ReapplySiteTemplate.reducer,
  filterableListDemo: FilterableListDemo.reducer,
    siteFile: SiteFile.reducer,
    runBridgeRoutines: RunBridgeRoutines.reducer,
    paperCommands: PaperCommands.reducer,
    userEmailConfirm: UserEmailConfirm.reducer,
};

export type ReducerTypes = typeof reducers;

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
