export interface IHeaderProps {
    columns: {
    }
}

export interface IRowProps {
    record: {
        cpId: number;
        payerName: string;
    }
}

interface IUiclColumn {
    dataName: string,
    text: string,
    sortable?: boolean,
    cellType?: string,
    isSorted?: number,
}

export class GridConfig {
    public static getColumnsConfig(): Set<IUiclColumn> {
        const columns: IUiclColumn[] = [
            {
                text: `CPID's`,
                dataName: 'cpId',
                sortable: true,
                cellType: 'text',
                // isSorted: 0,
            },
            {
                text: 'payerName',
                dataName: 'Payer Name',
                sortable: true,
                cellType: 'text',
                // isSorted: 0,
            },

        ];
        return new Set(columns);
    }
}
