import * as React from "react";
import { Switch, Route, Redirect, RouteProps } from "react-router-dom";
import { PrivateRoute } from "@components/common/PrivateRoute";
import { LegacyRoute } from "@components/Legacy/LegacyRoute";
import { CfiRoute } from "@components/Claims/CFIRoute";
import { Layout } from "./components/common/Layout";
import UserMaintenance from "./components/Administration/UserMaintenance/UserMaintenance";
import PayerAddress from "./components/Administration/AEPayerAddress/PayerAddress";
import ARMLogin from "./components/Transactions/Logon/ARMLogin";
import ARMAuthentication from "./components/Transactions/Authentication/ARMAuthentication";
import PageNotFound from "./components/common/PageNotFound";
import LandingPage from "./components/LandingPage/LandingPage";
import TestModeLandingPage from "./components/TestMode/TestModeLandingPage";
import ChangePassword from "./components/Home/ChangePassword";
import { Messages } from "./components/Home/Messages";
import AutoList from "./components/Administration/AutoList/AutoList";
import BridgeMaintenance from "./components/Administration/BridgeMaintenance/BridgeMaintenance";
import ClaimNoteType from "./components/Administration/ClaimNoteType/ClaimNoteType";
import ClaimNoteTypeSample from "./components/samples/ClaimNoteType/ClaimNoteTypeSample";
import ClaimOptions from "./components/Administration/ClaimOptions/ClaimOptions";
import ManualRun from "./components/Administration/ManualRun/ManualRun";
import FieldLevelSecurity from "./components/Administration/FieldLevelSecurity/FieldLevelSecurity";
import HoldCode from "./components/Administration/HoldCode/HoldCode";
import DashboardMaintenance from "./components/Administration/DashboardMaintenance/DashboardMaintenance";
import DashboardContacts from "./components/Administration/DashboardMaintenance/DashboardContacts";
import DirectEntryAccount from "./components/Administration/DirectEntryAccount/DirectEntryAccount";
import FormsUsed from "./components/Administration/FormsUsed/FormsUsed";
import SiteTemplateTransfer from "./components/SupportTools/SiteTemplateTransfer/SiteTemplateTransfer";
import FavoriteFilter from "./components/Administration/FavoriteFilter/FavoriteFilter";
import { SecurityBits } from "@commonResources/SecurityFunctionList";
import AllowedIpMaintenance from "./components/Administration/AllowedIpMaintenance/AllowedIpMaintenance";
import UserPreferences from "./components/Administration/UserPreferences/UserPreferences";
import AcceptRemits from "./components/Remits/AcceptRemits/AcceptRemits";
import RemoveRemits from "./components/Remits/ManageRemits/Remove";
import DeleteFilter from "./components/Administration/DeleteFilter/DeleteFilter";
import ViewEditFilter from "./components/Claims/Filter/ViewEdit";
import AddNoteFilter from "./components/Claims/Filter/AddNoteFilter";
import CopyClaimsToTest from "./components/Claims/Filter/CopyClaimsToTest";
import CreateReportFilter from "./components/Claims/Filter/CreateReportFilter";
import ClaimFilter from "./components/Claims/Filter/ClaimFilter";
import WorkingGroupDisplay from "./components/Claims/WorkingGroupDisplay/WorkingGroupDisplay";
import CheckEligibility from "./components/Claims/Filter/CheckEligibility";
import CancelEligibility from "./components/Claims/Filter/CancelEligibility";
import UndeleteClaims from "./components/Claims/Filter/UndeleteClaims";
import ReprocessMCARules from "./components/Claims/Filter/ReprocessMCARules";
import ReprocessPayerStatusRules from "./components/Claims/Filter/ReprocessPayerStatusRules";
import ReprocessUserProfiles from "./components/Claims/Filter/ReprocessUserProfiles";
import Secondary from "./components/Claims/Filter/Secondary";
import PaperClaimsFilter from "./components/Claims/Filter/PaperClaimsFilter";
import ReleaseClaims from "./components/Claims/Filter/ReleaseClaims";
import ClaimFilterFactory from "./components/TestMode/ClaimFilterFactory";
import ReportListTest from "./components/TestMode/ReportList";

import DirectSubmit from "./components/Claims/Filter/DirectSubmit";
import DirectValidate from "./components/Claims/Filter/DirectValidate";
import DirectEligibility from "./components/Claims/Filter/DirectEligibility";
import DirectStatus from "./components/Claims/Filter/DirectStatus";
import DirectStatusAndServiceLines from "./components/Claims/Filter/DirectStatusAndServiceLines";
import RemitWorkingGroupDisplay from "./components/Remits/WorkingGroupDisplay/RemitWorkingGroupDisplay";
import WorkingGroupDisplayByPayment from "./components/Remits/WorkingGroupDisplayByPayment/WorkingGroupDisplayByPayment";
import AddNote from "./components/Claims/AddNote/AddNote";
import ClaimTracking from "@components/Administration/ClaimTracking/ClaimTracking";
import SelectReasonCode from "./components/Claims/ReasonCode/SelectReasonCode";
import SupplementalEditsMaintenance from "./components/Administration/SupplementalEditsMaintenance/SupplementalEditsMaintenance";
import UserPasswordHints from "./components/Transactions/UserPasswordHints/UserPasswordHints";
import { SSOLogoff } from "./components/Transactions/SSOLogoff/SSOLogoff";
import ViewRemitFilter from "./components/Remits/Filter/ViewRemitFilter";
import RemitPageFactory from "./components/Remits/Filter/RemitPageFactory";
import PaperClaimsPrintingMaintenance from "./components/Administration/PaperClaimsPrintingMaintenance/PaperClaimsPrintingMaintenance";
import SweepTime from "./components/Administration/SweepTime/SweepTime";

import FileType from "./components/Administration/FileType/FileType";

import RestrictAccess from "./components/Administration/RestrictAccess/RestrictAccess";

import { ReportList } from "@components/Reports/ReportList/ReportList";
import SpecificReportFilter from "@components/Reports/Filter/SpecificReportFilter";
import ReeditFilter from "./components/Claims/Filter/ReeditFilter";
import Export28Filter from "./components/Claims/Filter/Export28Filter";
import Export7MFilter from "./components/Claims/Filter/Export7MFilter";
import ExportMS4Filter from "./components/Claims/Filter/ExportMS4Filter";
import GroupMaster from "./components/Administration/GroupMaster/GroupMaster";
import EligibilityProfile from "./components/Administration/EligibilityProfile/EligibilityProfile";
import FacilityMaster from "./components/Administration/FacilityMaster/FacilityMaster";

import AEPhysicianMaintenance from "./components/Administration/AEPhysicianMaintenance/AEPhysicianMaintenance";
import AutoSubmitMaintenance from "./components/Administration/AutoSubmitMaintenance/AutoSubmitMaintenance";
import ViewResponses from "./components/Reports/ViewResponses/ViewResponses";
import PhysicianMaintenance from "./components/Administration/PhysicianMaintenance/PhysicianMaintenance";
import SubmitClaims from "./components/Claims/SubmitClaims/SubmitClaims";
import AutoModule from "./components/Administration/AutoModule/AutoModule";
import { JobQueue } from "@components/Administration/JobQueue/JobQueue";
import PaperClaimList from "@components/Claims/PaperClaims/PaperClaimList";
import RebillFilter from "./components/Claims/Filter/RebillFilter";
import ChangeTransmitDFilter from "./components/Claims/Filter/ChangeTransmitDFilter";
import ChangeTransmitSFilter from "./components/Claims/Filter/ChangeTransmitSFilter";
import DeleteClaimFilter from "./components/Claims/Filter/DeleteClaimFilter";
import ClaimEventNotification from "@components/Reports/ReportSetup/ClaimEventNotification";
import ReportCreate from "@components/Reports/ReportCreate/ReportCreate";
import FilterRegenerate from "@components/Reports/FilterRegenerate/FilterRegenerate";
import PayerAlias from "@components/Maintenance/MasterSetup/PayerAlias";
import ManageHelpNotesForEdits from "@components/Administration/ManageHelpNotesForEdits/ManageHelpNotesForEdits";
import ManageClaimAssignmentDepartments from "./components/Administration/ManageClaimAssignmentDepartments/ManageClaimAssignmentDepartments";
import CreateCIF from "./components/Claims/CreateCIF/CreateCIF";
import UseFile from "./components/Administration/UseFile/UseFile";
import SiteFile from "./components/SupportTools/SiteFile/SiteFile";
import ManageClaimAssignmentsPayerStatusRules from "./components/Administration/ManageClaimAssignmentsPayerStatusRules/ManageClaimAssignmentsPayerStatusRules";
import ManageClaimAssignmentsSimpleErrorRules from "./components/Administration/ManageClaimAssignmentsSimpleErrorRules/ManageClaimAssignmentsSimpleErrorRules";
import ManageClaimAssignmentsErrorRules from "@components/Administration/ManageClaimAssignmentsErrorRules/ManageClaimAssignmentsErrorRules";
import SecodaryBilling from "./components/Administration/SecondaryBilling/SecondaryBilling";
import ReportOptions from "./components/Reports/ReportOptions/ReportOptions";
import DenialManagement from "./components/Reports/DenialManagement/DenialManagement";
import RemitImportOptions from "./components/Administration/RemitImportOptions/RemitImportOptions";
import PhysicianMnLetter from "@components/Reports/PhysicianMnLetter/PhysicianMnLetter";
import ADRCoverLetter from "@components/Reports/ADRCoverLetter/ADRCoverLetter";
import SecurityAccountPolicy from "@components/Administration/SecuritySetup/SecurityAccountPolicy";
import BatchClaimsPrintingOptions from "./components/Administration/BatchClaimsPrintingOptions/BatchClaimsPrintingOptions";
import PreBillFilter from "./components/Pages/PrebillFilter/PreBillFilter";
import ClaimStatusRequest from "./components/Claims/Filter/ClaimStatusRequest";
import HoldCodeProcessing from "./components/Claims/HoldCodeProcessing/HoldCodeProcessing";
import HoldCodeProcessingFilter from "./components/Claims/Filter/HoldCodeProcessingFilter";
import SpinOff from "./components/Claims/SpinOff/SpinOff";
import SpinOffFilter from "./components/Claims/Filter/SpinOffFilter";
import ScheduledEvents from "./components/Reports/ScheduledEvents/ScheduledEvents";
import ManualEligibilityCheck from "./components/Claims/ManualEligibility/ManualEligibilityCheck";
import ViewManualEligibility from "./components/Claims/ManualEligibility/ViewManualEligibility";
import RRTFilterFactory from "./components/Claims/RegulatoryReporting/RRTFilterFactory";

import { Demo } from "./components/Enablers/RemoveRemitsResultsGrid/Demo";
import GraphingDemo from "./components/Enablers/GraphingDemo";
import MeterComponentDemo from "./components/Enablers/MeterComponentDemo";
import ReadOnlyListDemo from "./components/Enablers/ReadOnlyListDemo";
import FilterableListDemo from "./components/Enablers/FilterableListDemo";
import FilterableTableDemo from "./components/Enablers/FilterableTableDemo";
import BulkImportExport from "@components/Administration/BulkImportExport/BulkImportExport";
import DeleteRemitFilter from "./components/Administration/DeleteFilter/DeleteRemitFilter";
import SubmitClaimUpdateFile from "@components/Administration/SubmitClaimUpdateFile/SubmitClaimUpdateFile";
import SubmitReasonCodes from "@components/Administration/SubmitReasonCodes/SubmitReasonCodes";
import SubmitParallonFacilities from "@components/Administration/SubmitParallonFacilities/SubmitParallonFacilities";
import ManageCrossovers from "@components/Administration/ManageCrossovers/ManageCrossovers";
import SetNPIStatus from "@components/Administration/SetNPIStatus/SetNPIStatus";
import FileUpload837 from "@components/Reports/FileUpload/837/FileUpload837";
import FileUpload835 from "@components/Reports/FileUpload/835/FileUpload835";
import ClaimTotals from "./components/Claims/ClaimTotals/ClaimTotals";
import SubmitRemits from "@components/Remits/SubmitRemits/SubmitRemits";
import SubmitDESFiles from "@components/Administration/SubmitDESFiles/SubmitDESFiles";
import BackfeedConfig from "./components/Reports/BackfeedConfig/BackfeedConfig";
import InternalUser from "./components/SupportTools/InternalUser/InternalUser";
import RunBridgeRoutines from "./components/SupportTools/RunBridgeRoutines/RunBridgeRoutines";
import RunBridgeRoutinesFilter from "./components/Claims/Filter/RunBridgeRoutinesFilter";
import ClaimSiloInfo from "./components/Administration/AdminPortal/ClaimSiloInfo";
import LookUpDcn from "./components/Administration/LookUpDcn/LookUpDcn";
import LaunchDigitalBillerScheduler from "./components/Administration/LaunchDigitalBillerScheduler/LaunchDigitalBillerScheduler";
import PaperCommand from "./components/Administration/PaperCommand/PaperCommands";
import UserEmailConfirm from "./components/Transactions/Authentication/UserEmailConfirm";

{
    /* start -- imports not yet ready for QA!!! -- 
  import SecodaryBilling from './components/Administration/SecondaryBilling/SecondaryBilling'
  import RemitImportOptions from './components/Administration/RemitImportOptions/RemitImportOptions';
   */
}
import OperationalDashboard from "@components/Dashboard/Default/Default";
import DrillDownView from "@components/Dashboard/Default/DrillDownView";
import ViewProductivity from "@components/Dashboard/ViewProductivity/ViewProductivity";
//route for barchart for viewproductivity
import ProductivityBar from "@components/Dashboard/ViewProductivity/ProductivityBar/ProductivityBar";
import DateRangeFilterConfig from "@components/Reports/Filter/DateRangeFilterConfig";
import ViewX12ClaimFromAssurance from "@components/Claims/Filter/ViewX12ClaimFromAssurance";
import ViewBridgeLog from "./components/Reports/ViewBridgeLog/ViewBridgeLog";
import DashboardClaimDetails from "@components/Dashboard/Default/DashboardClaimDetails";
import SelectClient from "@components/Transactions/SelectClient/SelectClient";
import ClaimInquiryForm from "./components/Administration/ClaimInquiryForm/ClaimInquiryForm";
import ReapplySiteTemplate from "./components/SupportTools/ReapplySiteTemplate/ReapplySiteTemplate";
import LaunchSendReceiveMail from "@components/Claims/LaunchSendReceiveMail/LaunchSendReceiveMail";
import LaunchPerformanceScorecard from "@components/Claims/LaunchPerformanceScorecard/LaunchPerformanceScorecard";
import AgentClientInstall from "@components/AgentClient/AgentClientInstall";
import PcgPayerMaintenance from "./components/SupportTools/PCGPayerMaintenance/PcgPayerMaintenance";
import PcgPayerMaintenanceAdd from "./components/SupportTools/PCGPayerMaintenance/PcgPayerMaintenanceAdd";
{
    /* end -- imports not yet ready for QA!!! -- */
}

// ------------------------------------------------------------------------------
// NOTE:  none of the import lines above can be in {} if they are wrapped with the hoc stuff, this causes it to use without adding the hoc wrapper
// ------------------------------------------------------------------------------

const allowChrome = true;
export const routes = (
    <Route
        render={(props) => (
            <Layout {...props}>
                <Switch>
                    <Redirect exact from="/" to="/Transactions/Logon" />
                    {/*<Route name="Logon" path='/Transactions/Logon' component={ARMLogin} /> */}
                    <Route
                        name="Logon"
                        path="/Transactions/Logon"
                        component={ARMAuthentication}
                    />
                    <Route
                        name="Logon"
                        path="/Transactions/GetPasswordResetQuestion.*"
                        component={ARMAuthentication}
                    />
                    <Route
                        name="Logoff"
                        path="/Transactions/SSOLogoff"
                        component={SSOLogoff}
                    />
                    {/* CFI Routes */}
                    <PrivateRoute
                        key="/CFI/Default"
                        path="/CFI/Default"
                        component={CfiRoute}
                    />
                    <PrivateRoute
                        path="/LandingPage"
                        component={LandingPage}
                        key="/LandingPage"
                    />
                    <PrivateRoute
                        path="/ui/LandingPage"
                        component={LandingPage}
                        key="/ui/LandingPage"
                    />
                    <PrivateRoute
                        path="/Administration/EligibilityPayerProfileMaintenance"
                        component={EligibilityProfile}
                        key="/Administration/EligibilityProfile"
                        bitCreate={SecurityBits.FN_EDIT_PAYERELIGIBILITY}
                        bitView={SecurityBits.FN_VIEW_PAYERELIGIBILITY}
                        bitEdit={SecurityBits.FN_EDIT_PAYERELIGIBILITY}
                        bitDelete={SecurityBits.FN_EDIT_PAYERELIGIBILITY}
                    />
                    {/* Removed because IEMode cannot have these unremediated routes. 
           * The spaces int between the P and r are so that the ValidateRouteControl app won't see them 
           * (it does not understand comments and will think they are real and will fail)
           * With IEMode in the picture, we can just "hide" unremediated pages from production w/ routeControl.json.
          <P rivateRoute
            path="/TestMode/UserHome"
            component={TestModeLandingPage}
            key="/TestMode/UserHome"
            bitView={SecurityBits.FN_TESTMODE}
            displayTestMode={true}
          />
          <P rivateRoute
            path="/TestMode/Messages"
            component={Messages}
            key="/TestMode/Messages"
            bitView={SecurityBits.FN_TESTMODE}
            displayTestMode={true}
          />
          <P rivateRoute
            path="/TestMode/WorkingGroupDisplay"
            component={WorkingGroupDisplay}
            key="/TestMode/WorkingGroupDisplay"
            bitView={SecurityBits.FN_TESTMODE}
            bitEdit={SecurityBits.FN_VALIDATE_CLAIMS}
            bitCreate={SecurityBits.FN_CREATE_FILTERS}
            displayTestMode={true}
          />
          <P rivateRoute
            component={ClaimFilterFactory}
            path="/TestMode/Filter/Manual"
            key="/TestMode/Filter/Manual"
            displayTestMode={true}
            bitView={SecurityBits.FN_TESTMODE}
            bitEdit={SecurityBits.FN_TESTMODE}
            bitCreate={SecurityBits.FN_TESTMODE}
          />
          <P rivateRoute
            component={ClaimFilterFactory}
            path="/TestMode/Filter/View/Edit"
            key="/TestMode/Filter/View/Edit"
            displayTestMode={true}
            bitView={SecurityBits.FN_TESTMODE}
            bitEdit={SecurityBits.FN_TESTMODE}
            bitCreate={SecurityBits.FN_TESTMODE}
          />
          <P rivateRoute
            component={ReportListTest}
            path="/TestMode/ReportList"
            key="/TestMode/ReportList"
            displayTestMode={true}
            bitView={SecurityBits.FN_TESTMODE}
            bitEdit={SecurityBits.FN_TESTMODE}
            bitCreate={SecurityBits.FN_TESTMODE}
            bitViewClaimReports={SecurityBits.FN_TESTMODE}
            bitViewCustomReports={SecurityBits.FN_TESTMODE}
            bitViewManagementReports={SecurityBits.FN_TESTMODE}
            bitViewMiscellaneousReports={SecurityBits.FN_TESTMODE}
            bitViewOutsourceReports={SecurityBits.FN_TESTMODE}
            bitViewRemitReports={SecurityBits.FN_TESTMODE}
            bitViewSubmissionReports={SecurityBits.FN_TESTMODE}
            bitViewSystemReports={SecurityBits.FN_TESTMODE}
          />
          <P rivateRoute
            path="/Claims/Filter/CopyClaimsToTest"
            component={CopyClaimsToTest}
            key="/Claims/Filter/CopyClaimsToTest"
            bitView={SecurityBits.FN_TESTMODE}
            bitEdit={SecurityBits.FN_TESTMODE}
            bitCreate={SecurityBits.FN_TESTMODE}
          />
          <P rivateRoute
            path="/Administration/RestrictAccess"
            component={RestrictAccess}
            key="/Administration/RestrictAccess"
            bitView={SecurityBits.FN_VIEW_RESTRICT_USER_ACCESS}
            bitEdit={SecurityBits.FN_EDIT_RESTRICT_USER_ACCESS}
          />
          <P rivateRoute
            component={InternalUser}
            path="/SupportTools/UserMaintenance"
            key="/SupportTools/UserMaintenance"
            bitView={SecurityBits.NDC_EDIT_USERS}
          />         
          <P rivateRoute 
            path='/SupportTools/OCILog' 
            component={ViewBridgeLog} 
            key='/SupportTools/OCILog' 
            bitView={SecurityBits.NDC_VIEW_BRIDGE_LOGS} 
          />           
          */}

                    <PrivateRoute
                        component={InternalUser}
                        path="/SupportTools/UserMaintenance"
                        key="/SupportTools/UserMaintenance"
                        bitCreate={SecurityBits.NDC_CREATE_INTERNALUSER}
                        bitView={SecurityBits.NDC_EDIT_USERS}
                        bitEdit={SecurityBits.NDC_EDIT_USERS}
                        bitDelete={SecurityBits.NDC_DELETE_USERS}
                    />
                    <PrivateRoute
                        component={PcgPayerMaintenance}
                        path="/SupportTools/PCGPayerMaintenance"
                        key="/SupportTools/PCGPayerMaintenance"
                        bitView={SecurityBits.NDC_VIEW_PCG_PAYER_MAINTENANCE}
                        bitEdit={SecurityBits.NDC_EDIT_PCG_PAYER_MAINTENANCE}
                    />
                    <PrivateRoute
                        component={PcgPayerMaintenanceAdd}
                        path="/SupportTools/PCGPayerMaintenanceAdd"
                        key="/SupportTools/PCGPayerMaintenanceAdd"
                        bitEdit={SecurityBits.NDC_EDIT_PCG_PAYER_MAINTENANCE}

                    />
                    <PrivateRoute
                        path="/Administration/AutoList"
                        component={AutoList}
                        key="/Administration/AutoList"
                        bitView={SecurityBits.FN_VIEW_AUTOLIST}
                        bitEdit={SecurityBits.FN_EDIT_AUTOLIST}
                    />
                    <PrivateRoute
                        path="/Administration/BridgeMaintenance"
                        component={BridgeMaintenance}
                        key="/Administration/BridgeMaintenance"
                        bitView={SecurityBits.FN_VIEW_BRIDGEMASTER}
                        bitEdit={SecurityBits.FN_EDIT_BRIDGEMASTER}
                        bitCheck1={SecurityBits.FN_DELETE_BRIDGEMASTER}
                    />
                    <PrivateRoute
                        path="/Administration/ClaimNoteType"
                        component={ClaimNoteType}
                        key="/Administration/ClaimNoteType"
                        bitView={SecurityBits.FN_VIEW_CLAIMNOTE_MAINTENANCE}
                        bitEdit={SecurityBits.FN_EDIT_CLAIMNOTE_MAINTENANCE}
                    />
                    <PrivateRoute
                        path="/samples/ClaimNoteTypeSample"
                        component={ClaimNoteTypeSample}
                        key="/samples/ClaimNoteTypeSample"
                    />
                    <PrivateRoute
                        path="/Administration/ClaimOptionsMaintenance"
                        component={ClaimOptions}
                        key="/Administration/ClaimOptionsMaintenance"
                        bitView={SecurityBits.FN_VIEW_CLAIMCOMPLIANCESETTINGS}
                        bitEdit={SecurityBits.FN_EDIT_CLAIMCOMPLIANCESETTINGS}
                        bitDelete={SecurityBits.FN_DELETE_CLAIMCOMPLIANCESETTINGS}
                        bitCheck1={SecurityBits.FN_VIEW_CUSTOMEDITSCONFIGURATION}
                        bitCheck2={SecurityBits.FN_EDIT_CUSTOMEDITSCONFIGURATION}
                    />
                    <PrivateRoute
                        path="/Administration/ManualRun"
                        component={ManualRun}
                        key="/Administration/ManualRun"
                        bitView={SecurityBits.FN_VIEW_AUTOLIST}
                    />
                    <PrivateRoute
                        path="/Administration/HoldCode"
                        component={HoldCode}
                        key="/Administration/HoldCode"
                        bitView={SecurityBits.FN_VIEW_HOLDCODE_MAINTENANCE}
                        bitEdit={SecurityBits.FN_EDIT_HOLDCODE_MAINTENANCE}
                    />
                    <PrivateRoute
                        path="/Administration/SetNPIStatus"
                        component={SetNPIStatus}
                        key="/Administration/SetNPIStatus"
                        bitView={SecurityBits.FN_VIEW_FACILITY}
                        bitEdit={SecurityBits.FN_EDIT_FACILITY}
                    />
                    <PrivateRoute
                        path="/Administration/DashboardMaintenance"
                        component={DashboardMaintenance}
                        key="/Administration/DashboardMaintenance"
                        bitView={SecurityBits.FN_VIEW_DASHBOARD_MAINTENANCE}
                        bitEdit={SecurityBits.FN_EDIT_DASHBOARD_MAINTENANCE}
                    />
                    <PrivateRoute
                        path="/Administration/DashboardContacts"
                        component={DashboardContacts}
                        key="/Administration/DashboardContacts"
                        bitView={SecurityBits.FN_VIEW_DASHBOARD_MAINTENANCE}
                        bitEdit={SecurityBits.FN_EDIT_DASHBOARD_MAINTENANCE}
                    />
                    <PrivateRoute
                        path="/Administration/FormsUsed"
                        component={FormsUsed}
                        key="/Administration/FormsUsed"
                        bitView={SecurityBits.FN_VIEW_FORMTYPESUSED}
                        bitEdit={SecurityBits.FN_EDIT_FORMTYPESUSED}
                    />
                    <PrivateRoute
                        path="/Administration/UserMaintenance"
                        component={UserMaintenance}
                        key="/Administration/UserMaintenance"
                        bitCreate={SecurityBits.FN_CREATE_USERS}
                        bitView={SecurityBits.FN_VIEW_USERS}
                        bitEdit={SecurityBits.FN_EDIT_USERS}
                        bitDelete={SecurityBits.FN_DELETE_USERS}
                        bitRestrictView={SecurityBits.FN_VIEW_RESTRICT_USER_ACCESS}
                        bitViewClientSet={SecurityBits.NDC_VIEW_CLIENTSET}
                        bitEditClientSet={SecurityBits.NDC_EDIT_CLIENTSET}
                        bitBetaPreview={SecurityBits.NDC_USER_BETA_PREVIEW}
                    />
                    <PrivateRoute
                        path="/Administration/GroupMaintenance"
                        component={GroupMaster}
                        key="/Administration/GroupMaintenance"
                        bitCreate={SecurityBits.FN_CREATE_GROUPS}
                        bitView={SecurityBits.FN_VIEW_GROUPS}
                        bitEdit={SecurityBits.FN_EDIT_GROUPS}
                        bitDelete={SecurityBits.FN_DELETE_GROUPS}
                    />
                    <PrivateRoute
                        path="/Administration/FacilityMaintenance"
                        component={FacilityMaster}
                        key="/Administration/FacilityMaintenance"
                        bitCreate={SecurityBits.FN_ADD_FACILITY}
                        bitView={SecurityBits.FN_VIEW_FACILITY}
                        bitEdit={SecurityBits.FN_EDIT_FACILITY}
                        bitDelete={SecurityBits.FN_DELETE_FACILITY}
                        bitCheck1={SecurityBits.FN_ADD_PAYERS}
                        bitCheck2={SecurityBits.FN_EDIT_PAYERS}
                        bitCheck3={SecurityBits.FN_DELETE_PAYERS}
                    />
                    <PrivateRoute
                        path="/Administration/FileType"
                        component={FileType}
                        bitView={SecurityBits.FN_VIEW_FILEDOWNLOAD}
                        bitEdit={SecurityBits.FN_EDIT_FILEDOWNLOAD}
                        bitCheck1={SecurityBits.FN_VIEW_FILEDOWNLOAD}
                    />
                    <PrivateRoute
                        path="/Administration/SweepTime"
                        component={SweepTime}
                        key="/Administration/SweepTime"
                        bitView={SecurityBits.FN_VIEW_AUTOPRINT}
                        bitEdit={SecurityBits.FN_EDIT_AUTOPRINT}
                    />
                    <PrivateRoute
                        path="/Administration/AEPayerAddress"
                        component={PayerAddress}
                        key="/Administration/AEPayerAddress"
                        bitView={SecurityBits.FN_VIEW_AUTOENTRIES}
                        bitEdit={SecurityBits.FN_EDIT_AUTOENTRIES}
                        bitDelete={SecurityBits.FN_DELETE_AUTOENTRIES}
                    />
                    <PrivateRoute
                        path="/Administration/AssignFavoriteFilter"
                        component={FavoriteFilter}
                        key="/Administration/AssignFavoriteFilter"
                    />
                    <PrivateRoute
                        path="/Administration/AllowedIpMaintenance"
                        component={AllowedIpMaintenance}
                        key="/Administration/AllowedIpMaintenance"
                        bitView={SecurityBits.FN_VIEW_ALLOWEDADDRESSES}
                        bitEdit={SecurityBits.FN_EDIT_ALLOWEDADDRESSES}
                    />
                    <PrivateRoute
                        path="/Administration/DeleteFilter/Claim"
                        component={DeleteFilter}
                        key="/Administration/DeleteFilter/Claim"
                        bitDelete={SecurityBits.FN_DELETE_FILTERS}
                    />
                    <PrivateRoute
                        path="/Administration/DeleteFilter/Remit"
                        component={DeleteRemitFilter}
                        key="/Administration/DeleteFilter/Remit"
                        bitDelete={SecurityBits.FN_DELETE_FILTERS}
                    />
                    <PrivateRoute
                        path="/Administration/SubmitClaimUpdateFile"
                        component={SubmitClaimUpdateFile}
                        key="/Administration/SubmitClaimUpdateFile"
                        bitView={SecurityBits.FN_RUN_CLAIMCHANGE_FILE}
                    />
                    <PrivateRoute
                        path="/Administration/SubmitReasonCodes"
                        component={SubmitReasonCodes}
                        key="/Administration/SubmitReasonCodes"
                        bitView={SecurityBits.FN_SUBMIT_REASONCODES}
                    />
                    <PrivateRoute
                        path="/Administration/SubmitParallonFacilities"
                        component={SubmitParallonFacilities}
                        key="/Administration/SubmitParallonFacilities"
                        bitView={SecurityBits.FN_SUBMIT_PARALLON_FACILITIES}
                    />
                    <PrivateRoute
                        path="/Administration/ManageCrossovers"
                        component={ManageCrossovers}
                        key="/Administration/ManageCrossovers"
                        bitView={SecurityBits.FN_PARALLON_MANAGE_CROSSOVERS}
                    />
                    <PrivateRoute
                        path="/Administration/UserPreferences"
                        component={UserPreferences}
                        key="/Administration/UserPreferences"
                        bitEdit={SecurityBits.FN_EDIT_FAVORITE_FILTERS}
                    />
                    <PrivateRoute
                        path="/Administration/RemitImportOptions"
                        component={RemitImportOptions}
                        bitView={SecurityBits.FN_VIEW_REMITOPTIONS_MAINTENANCE}
                        bitEdit={SecurityBits.FN_EDIT_REMITOPTIONS_MAINTENANCE}
                        bitDelete={SecurityBits.FN_DELETE_REMITOPTIONS_MAINTENANCE}
                    />
                    <PrivateRoute
                        path="/SupportTools/InstallAgentClient"
                        component={AgentClientInstall}
                        key="/SupportTools/InstallAgentClient"
                        bitView={SecurityBits.NDC_AGENT_INSTALL}
                    />
                    <PrivateRoute
                        path="/Transactions/UserPasswordHints"
                        component={UserPasswordHints}
                        key="/Transactions/UserPasswordHints"
                    />
                    <PrivateRoute
                        path="/Transactions/SwitchClient"
                        component={SelectClient}
                        key="/Transactions/SwitchClient"
                        menuName="noMenu"
                    />
                    <PrivateRoute
                        path="/Administration/UseFile"
                        component={UseFile}
                        key="/Administration/UseFile"
                    />
                    <PrivateRoute
                        path="/SupportTools/UseFile"
                        component={SiteFile}
                        key="/SupportTools/UseFile"
                        bitView={SecurityBits.NDC_BRIDGE_KEY_FILES}
                    />
                    <PrivateRoute
                        path="/Administration/AEPhysicianMaintenance"
                        component={AEPhysicianMaintenance}
                        key="/Administration/AEPhysicianMaintenance"
                        bitView={SecurityBits.FN_VIEW_PHYSICIANS}
                        bitEdit={SecurityBits.FN_EDIT_PHYSICIANS}
                        bitCheck1={SecurityBits.FN_DELETE_PHYSICIANS}
                    />
                    <PrivateRoute
                        path="/Administration/AutoSubmitMaintenance"
                        component={AutoSubmitMaintenance}
                        key="/Administration/AutoSubmitMaintenance"
                        bitView={SecurityBits.FN_VIEW_AUTOSUBMIT}
                        bitEdit={SecurityBits.FN_EDIT_AUTOSUBMIT}
                        bitCheck1={SecurityBits.FN_DELETE_AUTOSUBMIT}
                    />
                    <PrivateRoute
                        path="/Administration/PhysicianMaintenance"
                        component={PhysicianMaintenance}
                        key="/Administration/PhysicianMaintenance"
                        bitView={SecurityBits.FN_VIEW_PHYSICIANS}
                        bitEdit={SecurityBits.FN_EDIT_PHYSICIANS}
                        bitDelete={SecurityBits.FN_DELETE_PHYSICIANS}
                        bitCreate={SecurityBits.FN_CREATE_PHYSICIANS}
                        bitList={[
                            SecurityBits.FN_ADD_PAYERS,
                            SecurityBits.FN_EDIT_PAYERS,
                            SecurityBits.FN_DELETE_PAYERS,
                            SecurityBits.FN_EDIT_FACILITY,
                        ]}
                    />
                    <PrivateRoute
                        path="/Administration/BatchClaimsPrintingOptions/Print"
                        component={BatchClaimsPrintingOptions}
                        key="/Administration/BatchClaimsPrintingOptions/Print"
                        bitView={SecurityBits.FN_PRINT_CLAIMS}
                        bitEdit={SecurityBits.FN_PRINT_CLAIMS}
                    />
                    <PrivateRoute
                        path="/Administration/ManageClaimAssignmentDepartments"
                        component={ManageClaimAssignmentDepartments}
                        key="/Administration/ManageClaimAssignmentDepartments"
                    />
                    <PrivateRoute
                        path="/Administration/ManageClaimAssignmentsPayerStatusRules"
                        component={ManageClaimAssignmentsPayerStatusRules}
                        key="/Administration/ManageClaimAssignmentsPayerStatusRules"
                    />
                    <PrivateRoute
                        path="/Administration/ManageClaimAssignmentsSimpleErrorRules"
                        component={ManageClaimAssignmentsSimpleErrorRules}
                        key="/Administration/ManageClaimAssignmentsSimpleErrorRules"
                        bitEdit={SecurityBits.FN_ASSIGN_ERROR}
                        bitView={SecurityBits.FN_ASSIGN_ERROR}
                    />
                    <PrivateRoute
                        path="/Administration/ManageClaimAssignmentsErrorRules"
                        component={ManageClaimAssignmentsErrorRules}
                        key="/Administration/ManageClaimAssignmentsErrorRules"
                        bitEdit={SecurityBits.FN_ASSIGN_ERROR}
                        bitView={SecurityBits.FN_ASSIGN_ERROR}
                    />
                    <PrivateRoute
                        path="/Administration/EligibilityPayerProfileMaintenance"
                        component={EligibilityProfile}
                        key="/Administration/EligibilityProfile"
                        bitView={SecurityBits.FN_VIEW_PAYERELIGIBILITY}
                        bitEdit={SecurityBits.FN_EDIT_PAYERELIGIBILITY}
                    />
                    {/* Filter based routes - bitView is your SecurityClearance - bitEdit is your Permission */}
                    <PrivateRoute
                        path="/Claims/Filter/View/Edit"
                        component={ViewEditFilter}
                        key="/Claims/Filter/View/Edit"
                        bitView={SecurityBits.FN_VIEW_CLAIMS}
                        bitEdit={SecurityBits.FN_VIEW_CLAIMS}
                        bitCreate={SecurityBits.FN_CREATE_FILTERS}
                    />
                    <PrivateRoute
                        path="/Claims/Filter/Reedit"
                        component={ReeditFilter}
                        key="/Claims/Filter/Reedit"
                        bitView={SecurityBits.FN_VALIDATE_CLAIMS}
                        bitEdit={SecurityBits.FN_VALIDATE_CLAIMS}
                        bitCreate={SecurityBits.FN_CREATE_FILTERS}
                    />
                    <PrivateRoute
                        path="/Claims/Filter/AddNote"
                        component={AddNoteFilter}
                        key="/Claims/Filter/AddNote"
                        bitView={SecurityBits.FN_BATCH_CLAIMNOTE}
                        bitEdit={SecurityBits.FN_BATCH_CLAIMNOTE}
                        bitCreate={SecurityBits.FN_BATCH_CLAIMNOTE}
                    />
                    <PrivateRoute
                        path="/Claims/Filter/SpinOff"
                        component={SpinOffFilter}
                        key="/Claims/Filter/SpinOff"
                        bitView={SecurityBits.FN_SPINOFF_CLAIM}
                        bitEdit={SecurityBits.FN_SPINOFF_CLAIM}
                        bitCreate={SecurityBits.FN_CREATE_FILTERS}
                    />
                    <PrivateRoute
                        path="/Claims/Filter/Rebill"
                        component={RebillFilter}
                        key="/Claims/Filter/Rebill"
                        bitView={SecurityBits.FN_REBILL_CLAIMS}
                        bitEdit={SecurityBits.FN_REBILL_CLAIMS}
                        bitCreate={SecurityBits.FN_CREATE_FILTERS}
                    />
                    <PrivateRoute
                        path="/Claims/Filter/Delete"
                        component={DeleteClaimFilter}
                        key="/Claims/Filter/Delete"
                        bitViewList={[
                            SecurityBits.FN_DELETE_CLAIMS_UNRELEASED,
                            SecurityBits.FN_DELETE_CLAIMS_RELEASED,
                        ]}
                        bitCreate={SecurityBits.FN_CREATE_FILTERS}
                    />
                    <PrivateRoute
                        path="/Claims/Filter/CreateReport"
                        component={CreateReportFilter}
                        key="/Claims/Filter/CreateReport"
                        bitView={SecurityBits.FN_CREATE_REPORTS}
                        bitEdit={SecurityBits.FN_CREATE_REPORTS}
                        bitCreate={SecurityBits.FN_CREATE_REPORTS}
                    />
                    <PrivateRoute
                        path="/Claims/Filter/PrintClaims"
                        component={PaperClaimsFilter}
                        key="/Claims/Filter/PrintClaims"
                        bitView={SecurityBits.FN_PRINT_CLAIMS}
                        bitEdit={SecurityBits.FN_PRINT_CLAIMS}
                        bitCreate={SecurityBits.FN_PRINT_CLAIMS}
                    />
                    <PrivateRoute
                        path="/Claims/Filter/ChangeTransmitD"
                        component={ChangeTransmitDFilter}
                        key="/Claims/Filter/ChangeTransmitD"
                        bitView={SecurityBits.FN_CHANGE_DIRECT_SUBMIT_STATE}
                        bitEdit={SecurityBits.FN_CHANGE_DIRECT_SUBMIT_STATE}
                        bitCreate={SecurityBits.FN_CHANGE_DIRECT_SUBMIT_STATE}
                    />
                    <PrivateRoute
                        path="/Claims/Filter/ChangeTransmitS"
                        component={ChangeTransmitSFilter}
                        key="/Claims/Filter/ChangeTransmitS"
                        bitView={SecurityBits.FN_CHANGE_DIRECT_SUBMIT_STATE}
                        bitEdit={SecurityBits.FN_CHANGE_DIRECT_SUBMIT_STATE}
                        bitCreate={SecurityBits.FN_CHANGE_DIRECT_SUBMIT_STATE}
                    />
                    <PrivateRoute
                        path="/Claims/Filter/ReprocessMCARules"
                        component={ReprocessMCARules}
                        key="/Claims/Filter/ReprocessMCARules"
                        bitView={SecurityBits.FN_VIEW_CLAIMS}
                        bitEdit={SecurityBits.FN_VIEW_CLAIMS}
                        bitCreate={SecurityBits.FN_VIEW_CLAIMS}
                    />
                    <PrivateRoute
                        path="/Claims/Filter/ReprocessPayerStatusRules"
                        component={ReprocessPayerStatusRules}
                        key="/Claims/Filter/ReprocessPayerStatusRules"
                        bitView={SecurityBits.FN_ASSIGN_ERROR}
                        bitEdit={SecurityBits.FN_ASSIGN_ERROR}
                        bitCreate={SecurityBits.FN_ASSIGN_ERROR}
                    />
                    <PrivateRoute
                        path="/Claims/Filter/ReprocessUserProfiles"
                        component={ReprocessUserProfiles}
                        key="/Claims/Filter/ReprocessUserProfiles"
                        bitView={SecurityBits.FN_EDIT_USER_PROFILE}
                        bitEdit={SecurityBits.FN_EDIT_USER_PROFILE}
                        bitCreate={SecurityBits.FN_EDIT_USER_PROFILE}
                    />
                    <PrivateRoute
                        path="/Claims/Filter/Secondary"
                        component={Secondary}
                        key="/Claims/Filter/Secondary"
                        bitView={SecurityBits.FN_BILL_SECONDARY}
                        bitEdit={SecurityBits.FN_BILL_SECONDARY}
                    />
                    <PrivateRoute
                        path="/Claims/Filter/ClaimStatusRequest"
                        component={ClaimStatusRequest}
                        key="/Claims/Filter/ClaimStatusRequest"
                        bitView={SecurityBits.FN_REQUEST_BATCH_CLAIM_STATUS}
                        bitEdit={SecurityBits.FN_REQUEST_BATCH_CLAIM_STATUS}
                    />
                    <PrivateRoute
                        path="/Claims/Filter/Export28"
                        component={Export28Filter}
                        key="/Claims/Filter/Export28"
                        bitView={SecurityBits.FN_VIEW_CLAIM_CHANGES}
                        bitEdit={SecurityBits.FN_VIEW_CLAIM_CHANGES}
                    />
                    <PrivateRoute
                        path="/Claims/Filter/Export7M"
                        component={Export7MFilter}
                        key="/Claims/Filter/Export7M"
                        bitView={SecurityBits.FN_VIEW_CLAIM_CHANGES}
                        bitEdit={SecurityBits.FN_VIEW_CLAIM_CHANGES}
                    />
                    <PrivateRoute
                        path="/Claims/Filter/ExportMS4"
                        component={ExportMS4Filter}
                        key="/Claims/Filter/ExportMS4"
                        bitView={SecurityBits.FN_VIEW_CLAIM_CHANGES}
                        bitEdit={SecurityBits.FN_VIEW_CLAIM_CHANGES}
                    />
                    <PrivateRoute
                        path="/Administration/HoldCodeProcessing/HoldCodeProcessing"
                        component={HoldCodeProcessing}
                        key="/Administration/HoldCodeProcessing/HoldCodeProcessing"
                        bitView={SecurityBits.FN_HOLD_CODE_PROCESSING}
                    />
                    <PrivateRoute
                        path="/Claims/Filter/HoldCodeProcessing"
                        component={HoldCodeProcessingFilter}
                        key="/Claims/Filter/HoldCodeProcessing"
                        bitView={SecurityBits.FN_HOLD_CODE_PROCESSING}
                        bitEdit={SecurityBits.FN_HOLD_CODE_PROCESSING}
                        bitCreate={SecurityBits.FN_HOLD_CODE_PROCESSING}
                    />
                    {/* Remit Filter based routes - bitView is your SecurityClearance - bitEdit is your Permission */}
                    {/*<PrivateRoute path='/Remits/Filter/View' component={ViewRemitFilter} key='/Remits/Filter/View' bitView={SecurityBits.FN_VIEW_REMITS} bitEdit={SecurityBits.FN_VIEW_REMITS} bitCreate={SecurityBits.FN_CREATE_FILTERS} />*/}
                    <PrivateRoute
                        path="/Claims/CreateCIF"
                        component={CreateCIF}
                        key="/Claims/CreateCIF"
                        bitView={SecurityBits.FN_CREATE_CIF}
                        bitEdit={SecurityBits.FN_CREATE_CIF}
                        bitCreate={SecurityBits.FN_CREATE_CIF}
                    />
                    <PrivateRoute
                        path="/Remits/WorkingGroupDisplay"
                        component={RemitWorkingGroupDisplay}
                        key="/Remits/WorkingGroupDisplay"
                        bitView={SecurityBits.FN_VIEW_REMITS}
                        bitCreate={SecurityBits.FN_CREATE_FILTERS}
                    />
                    <PrivateRoute
                        path="/Remits/WorkingGroupDisplayByPayment"
                        component={WorkingGroupDisplayByPayment}
                        key="/Remits/WorkingGroupDisplayByPayment"
                        bitView={SecurityBits.FN_REMIT_CREATE_POSTING_FILE}
                        bitCreate={SecurityBits.FN_CREATE_FILTERS}
                    />
                    <PrivateRoute
                        path="/Administration/ChangeTracking/:Process(1001)"
                        component={ClaimTracking}
                        key="/Administration/ChangeTracking/1001"
                        bitView={SecurityBits.FN_VIEW_CLAIM_CHANGES}
                    />
                    <PrivateRoute
                        path="/Administration/ChangeTracking/:Process(1007)"
                        component={ClaimTracking}
                        key="/Administration/ChangeTracking/1007"
                        bitView={SecurityBits.FN_VIEW_CLAIM_CHANGES}
                    />
                    <PrivateRoute
                        path="/Administration/ChangeTracking/:Process(1008)"
                        component={ClaimTracking}
                        key="/Administration/ChangeTracking/1008"
                        bitView={SecurityBits.FN_VIEW_CLAIM_CHANGES}
                    />
                    <PrivateRoute
                        path="/Administration/ChangeTracking/:Process(1011)"
                        component={ClaimTracking}
                        key="/Administration/ChangeTracking/1011"
                        bitView={SecurityBits.FN_VIEW_CLAIM_CHANGES}
                    />
                    <PrivateRoute
                        path="/Administration/ChangeTracking/:Process(1020)"
                        component={ClaimTracking}
                        key="/Administration/ChangeTracking/1020"
                        bitView={SecurityBits.FN_VIEW_CLAIM_CHANGES}
                    />
                    <PrivateRoute
                        path="/Claims/SubmitClaims"
                        component={SubmitClaims}
                        key="/Claims/SubmitClaims"
                        bitView={SecurityBits.FN_SUBMIT_CLAIMS}
                    />
                    <PrivateRoute
                        path="/Claims/AddNote"
                        component={AddNote}
                        key="/Claims/AddNote"
                        bitView={SecurityBits.FN_BATCH_CLAIMNOTE}
                        bitEdit={SecurityBits.FN_BATCH_CLAIMNOTE}
                    />
                    <PrivateRoute
                        path="/Claims/SpinOff"
                        component={SpinOff}
                        key="/Claims/SpinOff"
                        bitView={SecurityBits.FN_SPINOFF_CLAIM}
                        bitEdit={SecurityBits.FN_SPINOFF_CLAIM}
                    />
                    <PrivateRoute
                        path="/Claims/LaunchClaimsProcess/:Process(Rebill)"
                        component={SelectReasonCode}
                        key="/Claims/LaunchClaimsProcess/Rebill"
                        bitView={SecurityBits.FN_REBILL_CLAIMS}
                        bitEdit={SecurityBits.FN_REBILL_CLAIMS}
                    />
                    <PrivateRoute
                        path="/Claims/LaunchClaimsProcess/:Process(Delete)"
                        component={SelectReasonCode}
                        key="/Claims/LaunchClaimsProcess/Delete"
                        bitViewList={[
                            SecurityBits.FN_DELETE_CLAIMS_UNRELEASED,
                            SecurityBits.FN_DELETE_CLAIMS_RELEASED,
                        ]}
                    />
                    <PrivateRoute
                        path="/Transactions/PasDataLoader/AutoModule"
                        component={AutoModule}
                        key="/Transactions/PasDataLoader/AutoModule"
                        bitView={SecurityBits.FN_VIEW_AUTOMODULE}
                        bitEdit={SecurityBits.FN_EDIT_AUTOMODULE}
                        bitDelete={SecurityBits.FN_DELETE_AUTOMODULE}
                        bitCheck1={SecurityBits.FN_VIEW_ELEC_ATTACHMENTS}
                    />
                    // TODO verify
                    <PrivateRoute
                        path="/Claims/WorkingGroupDisplay"
                        component={WorkingGroupDisplay}
                        key="/Claims/WorkingGroupDisplay"
                        bitView={SecurityBits.FN_VIEW_CLAIMS}
                        bitEdit={SecurityBits.FN_VALIDATE_CLAIMS}
                        bitCreate={SecurityBits.FN_CREATE_FILTERS}
                    />
                    <PrivateRoute
                        path="/Claims/ClaimTotals"
                        component={ClaimTotals}
                        key="/Claims/ClaimTotals"
                        bitView={SecurityBits.FN_VIEW_CLAIM_METRICS}
                    />
                    <PrivateRoute
                        path="/Home/ChangePassword"
                        component={ChangePassword}
                        key="/Home/ChangePassword"
                    />
                    <PrivateRoute
                        path="/Home/Messages"
                        component={Messages}
                        key="/Home/Messages"
                    />
                    <PrivateRoute
                        path="/Remits/AcceptRemits"
                        component={AcceptRemits}
                        key="/Remits/AcceptRemits"
                    />
                    <PrivateRoute
                        path="/Remits/ManageRemits/Remove"
                        component={RemoveRemits}
                        key="/Remits/ManageRemits/Remove"
                        bitView={SecurityBits.FN_REMOVE_REMIT}
                        bitEdit={SecurityBits.FN_REMOVE_REMIT}
                    />
                    <PrivateRoute
                        path="/Reports/ScheduledEvents"
                        component={ScheduledEvents}
                        key="/Reports/ScheduledEvents"
                        bitView={SecurityBits.FN_VIEW_SCHEDULED_EVENTS}
                    />
                    <PrivateRoute
                        path="/Administration/OCILog"
                        component={ViewBridgeLog}
                        key="/Administration/OCILog"
                        bitView={SecurityBits.FN_BRIDGE_ROUTINES}
                    />
                    <PrivateRoute
                        path="/Reports/ReportList"
                        component={ReportList}
                        key="/Reports/ReportList"
                        bitView={SecurityBits.FN_VIEW_REPORTS}
                        bitDelete={SecurityBits.FN_DELETE_REPORTS}
                        bitViewClaimReports={SecurityBits.RPT_VIEW_CLAIM_REPORTS}
                        bitViewCustomReports={SecurityBits.RPT_VIEW_CUSTOM_REPORTS}
                        bitViewManagementReports={SecurityBits.RPT_VIEW_MANAGEMENT_REPORTS}
                        bitViewMiscellaneousReports={
                            SecurityBits.RPT_VIEW_MISCELLANEOUS_REPORTS
                        }
                        bitViewOutsourceReports={
                            SecurityBits.EBO_RPT_VIEW_OUTSOURCE_REPORTS
                        }
                        bitViewRemitReports={SecurityBits.RPT_VIEW_REMIT_REPORTS}
                        bitViewSubmissionReports={SecurityBits.RPT_VIEW_SUBMISSION_REPORTS}
                        bitViewSystemReports={SecurityBits.RPT_VIEW_SYSTEM_REPORTS}
                    />
                    <PrivateRoute
                        path="/Administration/ClaimEventNotification"
                        key="/Administration/ClaimEventNotification"
                        component={ClaimEventNotification}
                        bitView={SecurityBits.FN_VIEW_CLAIMEVENTNOTIFICATION}
                        bitEdit={SecurityBits.FN_EDIT_CLAIMEVENTNOTIFICATION}
                    />
                    <PrivateRoute
                        path="/Reports/ReportOptions"
                        key="/Reports/ReportOptions"
                        component={ReportOptions}
                    />
                    <PrivateRoute
                        path="/Administration/DenialManagement"
                        key="/Reports/DenialManagement"
                        component={DenialManagement}
                        bitView={SecurityBits.FN_DENIAL_MGT_VIEW}
                        bitEdit={SecurityBits.FN_DENIAL_MGT_SETUP}
                    />
                    <PrivateRoute
                        path="/Reports/Filter"
                        key="/Reports/Filter"
                        component={DateRangeFilterConfig}
                    />
                    <PrivateRoute
                        path="/CreateReports/Filter"
                        key="/Reports/Filter"
                        component={SpecificReportFilter}
                    />
                    <PrivateRoute
                        path="/Administration/HISSetup"
                        key="/Administration/HISSetup"
                        component={BackfeedConfig}
                    />
                    <PrivateRoute
                        path="/Reports/PhysicianMNLetter"
                        key="/Reports/PhysicianMNLetter"
                        component={PhysicianMnLetter}
                        bitView={SecurityBits.FN_VIEW_PHYSMNLETTER_MAINTENANCE}
                    />
                    <PrivateRoute
                        path="/Reports/ADRCoverLetter"
                        key="/Reports/ADRCoverLetter"
                        component={ADRCoverLetter}
                        bitView={SecurityBits.FN_VIEW_ADRCOVERLETTER_MAINTENANCE}
                    />
                    <PrivateRoute
                        path="/Administration/SupplementalEditsMaintenance"
                        component={SupplementalEditsMaintenance}
                        key="/SupplementalEditsMaintenance/SupplementalEditsMaintenance"
                        bitView={SecurityBits.FN_SUPPLEMENTALEDITS_VIEW}
                        bitEdit={SecurityBits.FN_SUPPLEMENTALEDITS_EDIT}
                    />
                    <PrivateRoute
                        path="/Administration/JobQueue"
                        component={JobQueue}
                        key="/JobQueue/JobQueue"
                        bitView={SecurityBits.FN_VIEW_JOBQUEUE}
                        bitEdit={SecurityBits.FN_EDIT_JOBQUEUE}
                    />
                    <PrivateRoute
                        path="/Reports/ViewResponses"
                        component={ViewResponses}
                        key="/Reports/ViewResponses"
                        bitView={SecurityBits.FN_VIEW_REMITS}
                    />
                    <PrivateRoute
                        path="/Claims/PaperClaimList"
                        key="/Claims/PaperClaimList"
                        component={PaperClaimList}
                        bitView={SecurityBits.FN_VIEW_PAPER_CLAIMS}
                        bitViewDownloads={SecurityBits.FN_VIEW_DOWNLOAD_FILES}
                        bitDelete={SecurityBits.FN_DELETE_PAPER_CLAIMS}
                    />
                    <PrivateRoute
                        path="/Administration/SecondaryBilling"
                        component={SecodaryBilling}
                        bitEdit={SecurityBits.FN_SECONDARY_BILLING_SETUP}
                        bitView={SecurityBits.FN_SECONDARY_BILLING_SETUP}
                    />
                    <PrivateRoute
                        path="/Reports/ReportCreate"
                        component={ReportCreate}
                        key="/Reports/ReportCreate"
                        bitView={SecurityBits.FN_CREATE_REPORTS}
                        bitList={[
                            SecurityBits.RPT_CREATE_CLAIM_REPORTS,
                            SecurityBits.RPT_CREATE_CUSTOM_REPORTS,
                            SecurityBits.RPT_CREATE_MANAGEMENT_REPORTS,
                            SecurityBits.RPT_CREATE_MISCELLANEOUS_REPORTS,
                            SecurityBits.EBO_RPT_CREATE_OUTSOURCE_REPORTS,
                            SecurityBits.RPT_CREATE_REMIT_REPORTS,
                            SecurityBits.RPT_CREATE_SUBMISSION_REPORTS,
                            SecurityBits.RPT_CREATE_SYSTEM_REPORTS,
                            SecurityBits.FN_VIEW_PREBILL,
                        ]}
                    />
                    <PrivateRoute
                        path="/Reports/FilterRegenerate"
                        component={FilterRegenerate}
                        key="/Reports/FilterRegenerate"
                        bitView={SecurityBits.FN_CREATE_REPORTS}
                        bitList={[
                            SecurityBits.RPT_CREATE_CLAIM_REPORTS,
                            SecurityBits.RPT_CREATE_CUSTOM_REPORTS,
                            SecurityBits.RPT_CREATE_MANAGEMENT_REPORTS,
                            SecurityBits.RPT_CREATE_MISCELLANEOUS_REPORTS,
                            SecurityBits.EBO_RPT_CREATE_OUTSOURCE_REPORTS,
                            SecurityBits.RPT_CREATE_REMIT_REPORTS,
                            SecurityBits.RPT_CREATE_SUBMISSION_REPORTS,
                            SecurityBits.RPT_CREATE_SYSTEM_REPORTS,
                            SecurityBits.FN_VIEW_PREBILL,
                        ]}
                    />
                    <PrivateRoute
                        path="/Administration/PayerAliasMaintenance"
                        key="/Administration/PayerAliasMaintenance"
                        component={PayerAlias}
                        bitView={SecurityBits.FN_VIEW_SPELLINGS}
                        bitCreate={SecurityBits.FN_CREATE_SPELLINGS}
                        bitEdit={SecurityBits.FN_EDIT_SPELLINGS}
                        bitDelete={SecurityBits.FN_DELETE_SPELLINGS}
                    />
                    <PrivateRoute
                        path="/Claims/Filter/CheckEligibility"
                        component={CheckEligibility}
                        key="/Claims/Filter/CheckEligibility"
                        bitView={SecurityBits.FN_SUBMIT_PAYERELIGIBILITY}
                        bitEdit={SecurityBits.FN_SUBMIT_PAYERELIGIBILITY}
                        bitCreate={SecurityBits.FN_CREATE_REPORTS}
                    />
                    <PrivateRoute
                        path="/Claims/Filter/CancelEligibility"
                        component={CancelEligibility}
                        key="/Claims/Filter/CancelEligibility"
                        bitView={SecurityBits.FN_CANCEL_ELIGIBILITY_REQUEST}
                        bitEdit={SecurityBits.FN_CANCEL_ELIGIBILITY_REQUEST}
                        bitCreate={SecurityBits.FN_CREATE_REPORTS}
                    />
                    <PrivateRoute
                        path="/Administration/ManageHelpNotesForEdits"
                        component={ManageHelpNotesForEdits}
                        key="Administration/ManageHelpNotesForEdits"
                        bitView={SecurityBits.FN_VIEW_ERROR_ADDITIONAL}
                        bitEdit={SecurityBits.FN_EDIT_ERROR_ADDITIONAL}
                    />
                    <PrivateRoute
                        component={UndeleteClaims}
                        path="/Claims/Filter/Undelete"
                        key="/Claims/Filter/Undelete"
                        bitView={SecurityBits.FN_UNDELETE_CLAIMS}
                        bitEdit={SecurityBits.FN_UNDELETE_CLAIMS}
                        bitCreate={SecurityBits.FN_CREATE_REPORTS}
                    />
                    <PrivateRoute
                        component={ReleaseClaims}
                        path="/Claims/Filter/Manual"
                        key="/Claims/Filter/Manual"
                        bitView={SecurityBits.FN_RELEASE_CLAIMS}
                        bitEdit={SecurityBits.FN_RELEASE_CLAIMS}
                        bitCreate={SecurityBits.FN_CREATE_REPORTS}
                    />
                    <PrivateRoute
                        component={DirectSubmit}
                        path="/Claims/Filter/Submit"
                        key="/Claims/Filter/Submit"
                        bitView={SecurityBits.FN_REQUEST_DDE_RELEASE}
                        bitEdit={SecurityBits.FN_REQUEST_DDE_RELEASE}
                        bitCreate={SecurityBits.FN_CREATE_REPORTS}
                    />
                    <PrivateRoute
                        component={DirectValidate}
                        path="/Claims/Filter/Validate"
                        key="/Claims/Filter/Validate"
                        bitView={SecurityBits.FN_REQUEST_DDE_VALIDATION}
                        bitEdit={SecurityBits.FN_REQUEST_DDE_VALIDATION}
                        bitCreate={SecurityBits.FN_CREATE_REPORTS}
                    />
                    <PrivateRoute
                        component={DirectEligibility}
                        path="/Claims/Filter/Eligibility"
                        key="/Claims/Filter/Eligibility"
                        bitView={SecurityBits.FN_REQUEST_DDE_ELIGIBILITY}
                        bitEdit={SecurityBits.FN_REQUEST_DDE_ELIGIBILITY}
                        bitCreate={SecurityBits.FN_CREATE_REPORTS}
                    />
                    <PrivateRoute
                        path="/Administration/PaperClaimsPrintingMaintenance"
                        component={PaperClaimsPrintingMaintenance}
                        key="/Administration/PaperClaimsPrintingMaintenance"
                        bitView={SecurityBits.FN_VIEW_PAPERCLAIMSSETTINGS}
                        bitEdit={SecurityBits.FN_EDIT_PAPERCLAIMSSETTINGS}
                    />
                    <PrivateRoute
                        component={DirectStatus}
                        path="/Claims/Filter/Status"
                        key="/Claims/Filter/Status"
                        bitView={SecurityBits.FN_REQUEST_DDE_INQUIRY}
                        bitEdit={SecurityBits.FN_REQUEST_DDE_INQUIRY}
                        bitCreate={SecurityBits.FN_CREATE_REPORTS}
                    />
                    <PrivateRoute
                        component={DirectStatusAndServiceLines}
                        path="/Claims/Filter/DESLStatus"
                        key="/Claims/Filter/DESLStatus"
                        bitView={SecurityBits.FN_REQUEST_DDE_INQUIRY}
                        bitEdit={SecurityBits.FN_REQUEST_DDE_INQUIRY}
                        bitCreate={SecurityBits.FN_CREATE_REPORTS}
                    />
                    <PrivateRoute
                        path="/Pages/PreBilleditFilter"
                        component={PreBillFilter}
                        key="/Pages/PreBilleditFilter"
                        bitEdit={SecurityBits.FN_EDIT_PREBILL}
                        bitView={SecurityBits.FN_VIEW_PREBILL}
                    />
                    <PrivateRoute
                        path="/Administration/SecurityPolicy"
                        component={SecurityAccountPolicy}
                        key="/Administration/SecurityPolicy"
                        bitView={SecurityBits.FN_VIEW_ACCOUNT_POLICY}
                        bitEdit={SecurityBits.FN_EDIT_ACCOUNT_POLICY}
                    />
                    {/* REMIT PAGES*/}
                    <PrivateRoute
                        component={RemitPageFactory}
                        path="/Remits/Filter/retrieve835"
                        key="/Remits/Filter/retrieve835"
                        bitView={SecurityBits.FN_RETRIEVE835}
                        bitEdit={SecurityBits.FN_RETRIEVE835}
                        bitCreate={SecurityBits.FN_RETRIEVE835}
                    />
                    <PrivateRoute
                        component={RemitPageFactory}
                        path="/Remits/Filter/View"
                        key="/Remits/Filter/View"
                        bitView={SecurityBits.FN_VIEW_REMITS}
                        bitEdit={SecurityBits.FN_VIEW_REMITS}
                        bitCreate={SecurityBits.FN_CREATE_FILTERS}
                    />
                    <PrivateRoute
                        component={RemitPageFactory}
                        path="/Remits/Filter/Match"
                        key="/Remits/Filter/Match"
                        bitView={SecurityBits.FN_MANUALLY_MATCH_REMIT}
                        bitEdit={SecurityBits.FN_MANUALLY_MATCH_REMIT}
                        bitCreate={SecurityBits.FN_CREATE_FILTERS}
                    />
                    <PrivateRoute
                        component={RemitPageFactory}
                        path="/Remits/Filter/postingfile"
                        key="/Remits/Filter/postingfile"
                        bitView={SecurityBits.FN_REMIT_CREATE_POSTING_FILE}
                        bitEdit={SecurityBits.FN_REMIT_CREATE_POSTING_FILE}
                        bitCreate={SecurityBits.FN_CREATE_FILTERS}
                    />
                    <PrivateRoute
                        component={RemitPageFactory}
                        path="/Remits/Filter/postingfilebypayer"
                        key="/Remits/Filter/postingfilebypayer"
                        bitView={SecurityBits.FN_REMIT_CREATE_POSTING_FILE}
                        bitEdit={SecurityBits.FN_REMIT_CREATE_POSTING_FILE}
                        bitCreate={SecurityBits.FN_CREATE_FILTERS}
                    />
                    <PrivateRoute
                        component={RemitPageFactory}
                        path="/Remits/Filter/postingfilebypayment"
                        key="/Remits/Filter/postingfilebypayment"
                        bitView={SecurityBits.FN_REMIT_CREATE_POSTING_FILE}
                        bitEdit={SecurityBits.FN_REMIT_CREATE_POSTING_FILE}
                        bitCreate={SecurityBits.FN_CREATE_FILTERS}
                    />
                    <PrivateRoute
                        component={RemitPageFactory}
                        path="/Remits/Filter/viewx12remit"
                        key="/Remits/Filter/viewx12remit"
                        bitView={SecurityBits.FN_VIEW_X12_835}
                        bitEdit={SecurityBits.FN_VIEW_X12_835}
                        bitCreate={SecurityBits.FN_CREATE_FILTERS}
                    />
                    <PrivateRoute
                        component={RemitPageFactory}
                        path="/Remits/Filter/CreateReport"
                        key="/Remits/Filter/CreateReport"
                        bitView={SecurityBits.FN_CREATE_REPORTS}
                        bitEdit={SecurityBits.FN_CREATE_REPORTS}
                        bitCreate={SecurityBits.FN_CREATE_FILTERS}
                    />
                    <PrivateRoute
                        component={SubmitRemits}
                        path="/Remits/SubmitRemits/Submit"
                        key="/Remits/SubmitRemits/Submit"
                        bitView={SecurityBits.FN_REMIT_SUBMIT}
                        bitEdit={SecurityBits.FN_REMIT_SUBMIT}
                    />
                    <PrivateRoute
                        path="/Administration/FieldLevelSecurity"
                        component={FieldLevelSecurity}
                        bitView={SecurityBits.FN_VIEW_FIELDLEVELSECURITY}
                        bitEdit={SecurityBits.FN_EDIT_FIELDLEVELSECURITY}
                        bitCheck1={SecurityBits.FN_EDIT_GROUPS}
                    />
                    <PrivateRoute
                        component={ManualEligibilityCheck}
                        path="/Claims/EligibilityRequestForm"
                        key="/Claims/EligibilityRequestForm"
                        bitEdit={SecurityBits.FN_MANUAL_ELIGIBILITY_REQUEST}
                        bitPrint={SecurityBits.FN_PRINT_ELIGIBILITY}
                    />
                    <PrivateRoute
                        component={RRTFilterFactory}
                        path="/Claims/RSRTFilter/ViewEdit/RRT"
                        key="/Claims/RSRTFilter/ViewEdit/RRT"
                        bitEdit={SecurityBits.FN_VIEW_CLAIMS}
                        bitCreate={SecurityBits.FN_VIEW_CLAIMS}
                    />
                    <PrivateRoute
                        component={RRTFilterFactory}
                        path="/Claims/RSRTFilter/Custom/ViewEdit/RRT"
                        key="/Claims/RSRTFilter/Custom/ViewEdit/RRT"
                        bitEdit={SecurityBits.FN_CREATE_REPORTS}
                        bitCreate={SecurityBits.FN_CREATE_REPORTS}
                    />
                    <PrivateRoute
                        component={RRTFilterFactory}
                        path="/Claims/RSRTFilter/Validate/RRT"
                        key="/Claims/RSRTFilter/Validate/RRT"
                        bitEdit={SecurityBits.FN_RRT_VALIDATE_CLAIMS}
                        bitCreate={SecurityBits.FN_RRT_VALIDATE_CLAIMS}
                    />
                    <PrivateRoute
                        component={RRTFilterFactory}
                        path="/Claims/RSRTFilter/Delete/RRT"
                        key="/Claims/RSRTFilter/Delete/RRT"
                        bitEdit={SecurityBits.FN_DELETE_CLAIMS_UNRELEASED}
                        bitCreate={SecurityBits.FN_DELETE_CLAIMS_UNRELEASED}
                    />
                    <PrivateRoute
                        component={ViewManualEligibility}
                        path="/Claims/ViewManualEligibility"
                        key="/Claims/ViewManualEligibility"
                        bitView={SecurityBits.FN_MANUAL_ELIGIBILITY_REQUEST}
                        bitPrint={SecurityBits.FN_PRINT_ELIGIBILITY}
                    />
                    <PrivateRoute
                        component={BulkImportExport}
                        path="/Administration/BulkImportExport"
                        key="/Administration/BulkImportExport"
                        bitEdit={SecurityBits.FN_IMPORT_PMFM}
                        bitCreate={SecurityBits.FN_EXPORT_PMFM}
                    />
                    <PrivateRoute
                        component={FileUpload837}
                        path="/Reports/FileUpload/837"
                        key="/Reports/FileUpload/837"
                        bitView={SecurityBits.FN_VIEW_X12_UPLOAD_837}
                    />
                    <PrivateRoute
                        component={FileUpload835}
                        path="/Reports/FileUpload/835"
                        key="/Reports/FileUpload/835"
                        bitView={SecurityBits.FN_VIEW_X12_UPLOAD_835}
                    />
                    <PrivateRoute
                        component={SubmitDESFiles}
                        path="/Administration/SubmitFiles"
                        key="/Administration/SubmitFiles"
                        bitView={SecurityBits.FN_SUBMIT_ATTACHMENTS}
                    />
                    <PrivateRoute
                        component={SiteTemplateTransfer}
                        path="/SupportTools/SiteTemplate"
                        key="/SupportTools/SiteTemplate"
                        bitView={SecurityBits.NDC_IMPORT_SYSTEM_DATA}
                    />
                    <PrivateRoute
                        component={ReapplySiteTemplate}
                        path="/SupportTools/ReapplySiteTemplate"
                        key="/SupportTools/ReapplySiteTemplate"
                        bitView={SecurityBits.NDC_VIEW_APPLY_SITETEMPLATES}
                    />
                    <PrivateRoute
                        path="/Dashboard/default"
                        key="/Dashboard/default"
                        component={OperationalDashboard}
                        bitView={SecurityBits.FN_VIEW_DASHBOARD}
                        bitEdit={SecurityBits.FN_VIEW_DASHBOARD}
                        bitCheck1={SecurityBits.FN_VIEW_WORK_GROUP_DASHBOARD}
                    />
                    <PrivateRoute
                        path="/Dashboard/DrillDownView"
                        key="/Dashboard/DrillDownView"
                        component={DrillDownView}
                        menuName="Dashboard"
                    />
                    <PrivateRoute
                        path="/Dashboard/dashboard_details"
                        key="/Dashboard/dashboard_details"
                        component={DashboardClaimDetails}
                    />
                    {/*  //Route for the productivity bar*/}
                    <PrivateRoute
                        path="/Dashboard/productivityBar"
                        key="/Dashboard/productivityBar"
                        component={ProductivityBar}
                        menuName="Dashboard"
                    />
                    <PrivateRoute
                        path="/Dashboard/productivity_selection"
                        key="/Dashboard/productivity_selection"
                        component={ViewProductivity}
                    />
                    <PrivateRoute
                        path="/Administration/DirectEntryAccount"
                        component={DirectEntryAccount}
                        bitView={SecurityBits.FN_VIEW_DDE_ACCOUNTS}
                        bitEdit={SecurityBits.FN_EDIT_DDE_ACCOUNTS}
                        bitCheck1={SecurityBits.FN_DELETE_DDE_ACCOUNTS}
                        bitCheck2={SecurityBits.FN_CREATE_DDE_ACCOUNTS}
                    />
                    <PrivateRoute
                        path="/Claims/Filter/viewx12claim"
                        component={ViewX12ClaimFromAssurance}
                        key="/Claims/Filter/viewx12claim"
                        bitView={SecurityBits.FN_VIEW_X12_837}
                        bitEdit={SecurityBits.FN_VIEW_X12_837}
                    />
                    <PrivateRoute
                        path="/Administration/ClaimInquiryForm"
                        component={ClaimInquiryForm}
                        bitEdit={SecurityBits.FN_CIF_SETUP}
                        bitView={SecurityBits.FN_CIF_SETUP}
                    />
                    <PrivateRoute
                        path="/SupportTools/RunBridgeRoutines"
                        component={RunBridgeRoutines}
                        key="/SupportTools/RunBridgeRoutines"
                        bitView={SecurityBits.NDC_RUN_BRIDGE_ROUTINES}
                        bitEdit={SecurityBits.NDC_RUN_BRIDGE_ROUTINES}
                    />
                    <PrivateRoute
                        path="/Claims/Filter/RunBridgeRoutines"
                        component={RunBridgeRoutinesFilter}
                        key="/Claims/Filter/RunBridgeRoutines"
                        bitView={SecurityBits.NDC_RUN_BRIDGE_ROUTINES}
                        bitEdit={SecurityBits.NDC_RUN_BRIDGE_ROUTINES}
                        bitCreate={SecurityBits.FN_CREATE_FILTERS}
                    />
                    <PrivateRoute
                        path="/Administration/PaperCommand"
                        component={PaperCommand}
                        key="/Administration/PaperCommand"
                        bitView={SecurityBits.FN_EDIT_PAPER_COMMAND}
                        bitEdit={SecurityBits.FN_EDIT_PAPER_COMMAND}
                    />
                   
                    {/* legacy, hosted solution */}
                    <PrivateRoute
                        path="/Administration/StartDigitalBiller"
                        component={LookUpDcn}
                        key="/Admninistration/StartDigitalBiller"
                        bitView={SecurityBits.FN_SUPER_ASSIGNER}
                        bitEdit={SecurityBits.FN_SUPER_ASSIGNER}
                        bitCreate={SecurityBits.FN_SUPER_ASSIGNER}
                    />
                    {/* cloud initiative */}
                    <PrivateRoute
                        path="/Administration/InitiateDigitalBiller"
                        component={LookUpDcn}
                        key="/Admninistration/InitiateDigitalBiller"
                        bitView={SecurityBits.FN_AWS_DIGITAL_BILLER}
                        bitEdit={SecurityBits.FN_AWS_DIGITAL_BILLER}
                        bitCreate={SecurityBits.FN_AWS_DIGITAL_BILLER}
                    />
                    <PrivateRoute
                        path="/Administration/AdjRollupInitiateDigitalBiller"
                        component={LookUpDcn}
                        key="/Admninistration/AdjRollupInitiateDigitalBiller"
                        bitView={SecurityBits.FN_AWS_DIGITAL_BILLER}
                        bitEdit={SecurityBits.FN_AWS_DIGITAL_BILLER}
                        bitCreate={SecurityBits.FN_AWS_DIGITAL_BILLER}
                    />
                    <PrivateRoute
                        path="/Administration/AdjDeleteInitiateDigitalBiller"
                        component={LookUpDcn}
                        key="/Admninistration/AdjDeleteInitiateDigitalBiller"
                        bitView={SecurityBits.FN_AWS_DIGITAL_BILLER}
                        bitEdit={SecurityBits.FN_AWS_DIGITAL_BILLER}
                        bitCreate={SecurityBits.FN_AWS_DIGITAL_BILLER}
                    />
                    <PrivateRoute
                        path="/Administration/DigitalBillerScheduler"
                        component={LaunchDigitalBillerScheduler}
                        key="/Admninistration/DigitalBillerScheduler"
                        bitView={SecurityBits.FN_AWS_DIGITAL_BILLER}
                        bitEdit={SecurityBits.FN_AWS_DIGITAL_BILLER}
                        bitCreate={SecurityBits.FN_AWS_DIGITAL_BILLER}
                    />
                    {/* end of cloud initiative */}

                    {/*
           <P rivateRoute
            path="/Administration/AdminPortal/CiamSiloInfo"
            component={ClaimSiloInfo} 
            />
        */}
                    {/* START ENABLER DEMO ROUTES */}
                    <PrivateRoute
                        path="/Enablers/RemoveRemitsResultsGrid/Demo"
                        component={Demo}
                        key="/Enablers/RemoveRemitsResultsGrid/Demo"
                    />
                    <PrivateRoute
                        path="/Enablers/ReadOnlyListDemo"
                        component={ReadOnlyListDemo}
                        key="/Enablers/ReadOnlyListDemo"
                    />
                    <PrivateRoute
                        path="/Enablers/FilterableListDemo"
                        component={FilterableListDemo}
                        key="/Enablers/FilterableListDemo"
                    />
                    <PrivateRoute
                        path="/Enablers/FilterableTableDemo"
                        component={FilterableTableDemo}
                        key="/Enablers/FilterableTableDemo"
                    />
                    <PrivateRoute
                        path="/Enablers/GraphingDemo"
                        component={GraphingDemo}
                        key="/Enablers/GraphingDemo"
                    />
                    <PrivateRoute
                        path="/Claims/LaunchSendReceiveMail"
                        component={LaunchSendReceiveMail}
                        key="/Claims/LaunchSendReceiveMail"
                        bitView={SecurityBits.FN_RELEASE_CLAIMS}
                    />
                    <PrivateRoute
                        path="/Claims/LaunchPerformanceScorecard"
                        component={LaunchPerformanceScorecard}
                        key="/Claims/LaunchPerformanceScorecard"
                        bitView={SecurityBits.FN_PERFORMANCE_SCORECARD}
                    />
                    <PrivateRoute
                        path="/Enablers/MeterComponentDemo"
                        component={MeterComponentDemo}
                        key="/Enablers/MeterComponentDemo"
                    />
                    <PrivateRoute
                        path="/UserEmailConfirm"
                        component={UserEmailConfirm}
                        key="/UserEmailConfirm"
                    />
                    {/* END ENABLER DEMO ROUTES */}
                    {/* PLEASE ONLY ADD LEGACY ROUTES FROM HERE DOWN */}
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(Shared)/:ChildFolder(Pages)/:File(ViewFile)/:Process*"
                        path="/:ParentFolder(Shared)/:ChildFolder(Pages)/:File(ViewFile)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(AgentClient)/:File(AgentClient.application)"

                        path="/:ParentFolder(AgentClient)/:File(AgentClient.application)"
                    />

                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(Administration)/:File(RestrictAccess)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(Administration)/:File(RestrictAccess)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(SupportTools)/:File(OCILog)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(SupportTools)/:File(OCILog)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(SupportTools)/:File(UserMaintenance)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(SupportTools)/:File(UserMaintenance)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:File(SelectSiloCID)"
                        allowChrome={allowChrome}
                        path="/:File(SelectSiloCID)"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(Administration)/:File(FTPConfig)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(Administration)/:File(FTPConfig)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(Administration)/:File(HISWorkflow)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(Administration)/:File(HISWorkflow)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(Administration)/:File(AdminPortal)/:Process(adjclaim)"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(Administration)/:File(AdminPortal)/:Process(adjclaim)"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(Administration)/:File(AdminPortal)/:Process(audit72)"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(Administration)/:File(AdminPortal)/:Process(audit72)"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(Administration)/:File(AdminPortal)/:Process(ciamsiloinfo)"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(Administration)/:File(AdminPortal)/:Process(ciamsiloinfo)"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(Administration)/:File(AdminPortal)/:Process(dlfiles)"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(Administration)/:File(AdminPortal)/:Process(dlfiles)"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(Administration)/:File(AdminPortal)/:Process(editseveritymaintenance)"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(Administration)/:File(AdminPortal)/:Process(editseveritymaintenance)"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(Administration)/:File(AdminPortal)/:Process(viewx12remit)"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(Administration)/:File(AdminPortal)/:Process(viewx12remit)"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(Administration)/:File(AdminPortal)/:Process(viewx12claimfromfile)"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(Administration)/:File(AdminPortal)/:Process(viewx12claimfromfile)"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(Administration)/:File(AdminPortal)/:Process(viewx12remitfromfile)"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(Administration)/:File(AdminPortal)/:Process(viewx12remitfromfile)"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(Administration)/:File(AdminPortal)/:Process(viewx12claim)"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(Administration)/:File(AdminPortal)/:Process(viewx12claim)"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(Administration)/:File(AdminPortal)/:Process(payerstatusrules)"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(Administration)/:File(AdminPortal)/:Process(payerstatusrules)"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(Administration)/:File(AdminPortal)/:Process(prebill)"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(Administration)/:File(AdminPortal)/:Process(prebill)"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(Administration)/:File(AdminPortal)/:Process(QueueView)"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(Administration)/:File(AdminPortal)/:Process(QueueView)"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(Administration)/:File(AdminPortal)/:Process(RRTClientProfile)"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(Administration)/:File(AdminPortal)/:Process(RRTClientProfile)"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(Administration)/:File(AdminPortal)/:Process(userprofile)"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(Administration)/:File(AdminPortal)/:Process(userprofile)"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(Administration)/:File(AdminPortal)/:Process(WorkGroup)"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(Administration)/:File(AdminPortal)/:Process(WorkGroup)"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(Administration)/:File(AdminPortal)/:Process(RRTAdHoc)"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(Administration)/:File(AdminPortal)/:Process(RRTAdHoc)"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(Administration)/:File(AdminPortal)/:Process(reconciliationprofile)"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(Administration)/:File(AdminPortal)/:Process(reconciliationprofile)"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(Administration)/:File(AdminPortal)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(Administration)/:File(AdminPortal)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(Administration)/:File(ProviderKnowledgePacks)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(Administration)/:File(ProviderKnowledgePacks)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(Dashboard)/:File(CTDashboard)"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(Dashboard)/:File(CTDashboard)"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(Dashboard)/:File(CTDashboardEnterprise)"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(Dashboard)/:File(CTDashboardEnterprise)"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(Remits)/:File(MatchRemits)/:Process(Refine)/:Param1*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(Remits)/:File(MatchRemits)/:Process(Refine)/:Param1*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(Remits)/:File(Filter)/:Process(FeedAntPay)"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(Remits)/:File(Filter)/:Process(FeedAntPay)"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(SupportTools)/:File(ChangeClientDisplayName)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(SupportTools)/:File(ChangeClientDisplayName)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(Administration)/:File(SecondaryBilling)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(Administration)/:File(SecondaryBilling)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(Claims)/:ChildFolder(CFR)/:Process(NewClaim)/:Param1*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(Claims)/:ChildFolder(CFR)/:Process(NewClaim)/:Param1*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(Administration)/:File(FileTypeMaintenance)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(Administration)/:File(FileTypeMaintenance)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(Administration)/:File(PaperCommand)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(Administration)/:File(PaperCommand)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(Administration)/:File(SpinOffSelection)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(Administration)/:File(SpinOffSelection)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(Administration)/:File(SplitMaintenance)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(Administration)/:File(SplitMaintenance)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(Claims)/:File(Filter)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(Claims)/:File(Filter)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(Claims)/:File(MassAppealResume)/:Process(ResumeAppeals)"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(Claims)/:File(MassAppealResume)/:Process(ResumeAppeals)"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(Claims)/:File(RSRTFilter)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(Claims)/:File(RSRTFilter)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(Dashboard)/:File(BIDashboard)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(Dashboard)/:File(BIDashboard)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(Dashboard)/:File(CTDashboard)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(Dashboard)/:File(CTDashboard)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(Dashboard)/:File(CTDashboardEnterprise)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(Dashboard)/:File(CTDashboardEnterprise)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(Home)/:File(Files)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(Home)/:File(Files)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(SupportTools)/:File(BannedAddressesMaintenance)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(SupportTools)/:File(BannedAddressesMaintenance)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(SupportTools)/:File(BridgeArchive)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(SupportTools)/:File(BridgeArchive)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(SupportTools)/:File(CCILookup)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(SupportTools)/:File(CCILookup)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(SupportTools)/:ChildFolder(Claims)/:File(ImportClaims)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(SupportTools)/:ChildFolder(Claims)/:File(ImportClaims)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(SupportTools)/:File(ClientSetMaintenance)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(SupportTools)/:File(ClientSetMaintenance)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(SupportTools)/:File(ConfigureSubmitter)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(SupportTools)/:File(ConfigureSubmitter)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(SupportTools)/:File(CreateSubmitter)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(SupportTools)/:File(CreateSubmitter)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(SupportTools)/:File(DESFileTypes)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(SupportTools)/:File(DESFileTypes)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(SupportTools)/:File(DESMaintenance)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(SupportTools)/:File(DESMaintenance)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(SupportTools)/:File(DirectEntry)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(SupportTools)/:File(DirectEntry)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(SupportTools)/:File(DownloadFiles)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(SupportTools)/:File(DownloadFiles)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(SupportTools)/:File(EventLog)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(SupportTools)/:File(EventLog)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(SupportTools)/:File(FeaturePreviewMaintenance)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(SupportTools)/:File(FeaturePreviewMaintenance)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(SupportTools)/:File(FieldMapping)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(SupportTools)/:File(FieldMapping)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(SupportTools)/:File(GroupMaintenance)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(SupportTools)/:File(GroupMaintenance)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(SupportTools)/:File(JobTypeMaintenance)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(SupportTools)/:File(JobTypeMaintenance)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(SupportTools)/:File(LinkClients)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(SupportTools)/:File(LinkClients)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(SupportTools)/:File(Logging)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(SupportTools)/:File(Logging)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(SupportTools)/:File(ManageRemits)/:Process(Recalculate)"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(SupportTools)/:File(ManageRemits)/:Process(Recalculate)"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(SupportTools)/:File(NotesFileMapping)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(SupportTools)/:File(NotesFileMapping)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(SupportTools)/:File(OutsourceMaintenance)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(SupportTools)/:File(OutsourceMaintenance)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(SupportTools)/:File(PhoneHome)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(SupportTools)/:File(PhoneHome)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(SupportTools)/:File(ProductProfileMaintenance)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(SupportTools)/:File(ProductProfileMaintenance)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(SupportTools)/:File(ProductUpdate)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(SupportTools)/:File(ProductUpdate)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(SupportTools)/:File(ReApplySiteTemplate)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(SupportTools)/:File(ReApplySiteTemplate)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(SupportTools)/:File(Registration)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(SupportTools)/:File(Registration)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(SupportTools)/:ChildFolder(Reports)/:File(ViewResponses)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(SupportTools)/:ChildFolder(Reports)/:File(ViewResponses)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(SupportTools)/:ChildFolder(Reports)/:File(ReportList)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(SupportTools)/:ChildFolder(Reports)/:File(ReportList)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(SupportTools)/:ChildFolder(Reports)/:File(ReportCreate)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(SupportTools)/:ChildFolder(Reports)/:File(ReportCreate)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(SupportTools)/:File(RunBridgeRoutines)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(SupportTools)/:File(RunBridgeRoutines)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(SupportTools)/:File(ServerMaintenance)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(SupportTools)/:File(ServerMaintenance)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(SupportTools)/:File(SetPayerStatus)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(SupportTools)/:File(SetPayerStatus)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(SupportTools)/:File(SetPhysicianStatus)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(SupportTools)/:File(SetPhysicianStatus)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(SupportTools)/:File(SiloInformation)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(SupportTools)/:File(SiloInformation)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(SupportTools)/:File(StuckClaims)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(SupportTools)/:File(StuckClaims)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(SupportTools)/:File(SystemExport)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(SupportTools)/:File(SystemExport)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(SupportTools)/:File(SystemImport)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(SupportTools)/:File(SystemImport)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(SupportTools)/:File(TicketLookUp)/:Process(EventIDLookup)"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(SupportTools)/:File(TicketLookUp)/:Process(EventIDLookup)"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(SupportTools)/:File(UploadFile)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(SupportTools)/:File(UploadFile)/:Process*"
                    />
                    {/* TestMode Routes */}
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(TestMode)/:File(CFILoad)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(TestMode)/:File(CFILoad)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(TestMode)/:File(ClaimFilter)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(TestMode)/:File(ClaimFilter)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(TestMode)/:File(Messages)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(TestMode)/:File(Messages)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(TestMode)/:File(ReportCreate)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(TestMode)/:File(ReportCreate)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(TestMode)/:File(ReportList)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(TestMode)/:File(ReportList)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(TestMode)/:File(SubmitTestClaims)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(TestMode)/:File(SubmitTestClaims)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(TestMode)/:File(UserHome)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(TestMode)/:File(UserHome)/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(TestMode)/:File(WorkingGroupDisplay)/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(TestMode)/:File(WorkingGroupDisplay)/:Process*"
                    />
                    {/* temporary routes... */}
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(Administration|Transactions|Home|Claims|Pages|Remits|Reports|Dashboard)/:File/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(Administration|Transactions|Home|Claims|Pages|Remits|Reports|Dashboard)/:File*/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(Legacy)/:ChildFolder(Administration|Transactions|Home|Claims|Dashboard|SupportTools)/:File/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(Legacy)/:ChildFolder(Administration|Transactions|Home|Claims|Dashboard|SupportTools)/:File*/:Process*"
                    />
                    <PrivateRoute
                        component={LegacyRoute}
                        key="/:ParentFolder(SupportTools)/:ChildFolder(Claims|Help|Reports)?/:File/:Process*"
                        allowChrome={allowChrome}
                        path="/:ParentFolder(SupportTools)/:ChildFolder(Claims|Help|Reports)?/:File/:Process*"
                    />
                    <Route component={PageNotFound} />
                </Switch>
            </Layout>
        )}
    />
);

// THE FOLLOWING WERE ALL ROUTES IN DEVELOPMENT, HERE FOR RESEARCH PURPOSES ONLY
/*
 *
        THESE WERE STARTED BUT REMOVED... ADD BACK WHEN READY
		    <PrivateRoute path='/Administration/DeleteFilter/Remit' component={DeleteRemitFilter} key='/Administration/DeleteFilter/Remit' bitDelete={SecurityBits.FN_DELETE_FILTERS} />

		            <PrivateRoute component={LegacyRoute} key='/:ParentFolder(Administration)/:File(AdminPortal)/:Process(viewx12remit)' allowChrome={allowChrome} path="/:ParentFolder(Administration)/:File(AdminPortal)/:Process(viewx12remit)" />
             <PrivateRoute component={RemitPageFactory}
                path='/Remits/Filter/viewx12remit'
                key='/Remits/Filter/viewx12remit'
                bitView={SecurityBits.FN_VIEW_X12_835}
                bitEdit={SecurityBits.FN_VIEW_X12_835}
                bitCreate={SecurityBits.FN_CREATE_FILTERS}
            />

            <PrivateRoute path='/Administration/FieldLevelSecurity' component={FieldLevelSecurity} bitView={SecurityBits.FN_VIEW_FIELDLEVELSECURITY} bitEdit={SecurityBits.FN_EDIT_FIELDLEVELSECURITY} bitCheck1={SecurityBits.FN_EDIT_GROUPS} />
            <PrivateRoute component={LegacyRoute} key='/:ParentFolder(Administration)/:File(AdminPortal)/:Process(PayerStatusRules)' allowChrome={allowChrome} path="/:ParentFolder(Administration)/:File(AdminPortal)/:Process(PayerStatusRules)" />
            <PrivateRoute path='/Reports/ViewResponses' component={ViewResponses} />
            <PrivateRoute path='/Administration/GroupMaintenance' component={GroupMaster} />
            <PrivateRoute path='/Administration/FileType' component={FileType} bitView={SecurityBits.FN_VIEW_FILEDOWNLOAD} bitEdit={SecurityBits.FN_EDIT_FILEDOWNLOAD} bitCheck1={SecurityBits.FN_VIEW_FILEDOWNLOAD} />

            <PrivateRoute path='/Administration/SecondaryBilling' component={SecodaryBilling} bitEdit={SecurityBits.FN_SECONDARY_BILLING_SETUP} bitView={SecurityBits.FN_SECONDARY_BILLING_SETUP} />
            <PrivateRoute path='/Administration/RemitImportOptions' component={RemitImportOptions} bitView={SecurityBits.FN_VIEW_REMITOPTIONS_MAINTENANCE} bitEdit={SecurityBits.FN_EDIT_REMITOPTIONS_MAINTENANCE} bitDelete={SecurityBits.FN_DELETE_REMITOPTIONS_MAINTENANCE} />




        THESE ARE UNNEEDED
            <Route path='/SampleApiGrid' component={SampleApiGrid} />
            <Route path='/SampleApiDataGrid' component={SampleApiDataGrid} />
            <Route path='/TestApiGridExample' render={(props) => <ApiGrid title='Test Api Grid' {...props} url='/api/SampleData/TestGrid' columns={GRID_COLUMNS} stateField='sampleApiGrid' />} />
            <Route path='/PASLogin' render={(props) => <PASLogin {...props} />} />
            <Route path='/test' component={AssurancePlaceholder} />
            <Route path='/TestLegacyQuickSearch' render={(props) => <ARMQuickSearch {...props} renderPanel={true} />} />
            <PrivateRoute path='/X12Viewer' component={X12Test} />
            <Route path='/RemitValidation' render={(props) => <RemitValidation {...props} Name="RemitValidation" />} />
            <Route path='/testMessages' component={ARMMessageComponent} />
            <Route exact path='/Home' component={Home} />
            <Route path='/Work' component={MyWorkProductivity} />
            <Route path='/Unreleased' component={UnReleasedClaimAge} />
            <Route path='/ReportTool' component={ReportTool} />
            <Route path='/counter' component={Counter} />
            <Route path='/fetchdata/:startDateIndex?' component={FetchData}/>

            <PrivateRoute path='/Administration/SearchClaim' component={SearchClaim} />

 *
 */
